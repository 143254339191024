.webix_view {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #475466;
  letter-spacing: 0.2px;
  -webkit-font-smoothing: antialiased;
  cursor: default;
  overflow: hidden;
  border: 0px solid #DADEE0;
  background-color: #FFFFFF;
  white-space: normal;
  -webkit-appearance: none;
}
.webix_view.webix_form {
  background-color: #FFFFFF;
}
body {
  -webkit-tap-highlight-color: transparent !important;
}
/*
	webix_layout_{type}
	known types are: line, clean, wide, space;
*/
.webix_abslayout {
  position: relative;
}
.webix_abslayout > .webix_view {
  position: absolute !important;
  z-index: 0;
}
.webix_layout_space {
  background-color: #EBEDF0;
}
.webix_layout_wide {
  background-color: #EBEDF0;
}
.webix_layout_line,
.webix_multiview,
.webix_layout_clean,
.webix_layout_accordion {
  background: transparent;
}
.webix_overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 90;
  text-align: center;
  padding-top: 10px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_view > .webix_disabled {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ddd;
  opacity: 0.4;
  z-index: 92;
  cursor: not-allowed;
}
.webix_disabled_view {
  overflow: hidden !important;
  position: relative;
}
body.webix_full_screen {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
body.webix_noselect {
  overscroll-behavior: none;
}
.webix_noselect,
.webix_noselect div {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.webix_selectable {
  -moz-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
.webix_template {
  height: 100%;
  padding: 4px 12px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_clean {
  background: transparent;
}
.webix_clean .webix_template {
  padding: 0px;
}
.webix_section {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: transparent;
  position: relative;
  color: #444;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 44px;
}
.webix_section:before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #DADEE0;
  position: absolute;
  top: 50%;
}
.webix_section > .webix_template {
  padding: 0px 8px;
  margin-left: 30px;
  background-color: #FFFFFF;
  display: inline;
  position: relative;
}
.webix_layout_space > .webix_section > .webix_template {
  padding: 0px 8px;
  background-color: #EBEDF0;
}
.webix_layout_wide > .webix_section > .webix_template {
  padding: 0px 8px;
  background-color: #EBEDF0;
}
.webix_layout_line > .webix_section > .webix_template,
.webix_layout_clean > .webix_section > .webix_template {
  padding: 0px 8px;
  background-color: #FFFFFF;
}
.webix_header {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #475466;
  letter-spacing: 0px;
  line-height: 42px;
}
.webix_header .webix_el_label .webix_el_box {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #475466;
  letter-spacing: 0px;
}
.webix_header.webix_dark {
  background: #657584;
  border-color: #657584;
}
.webix_header.webix_dark .webix_el_button,
.webix_header.webix_dark .webix_el_label .webix_el_box,
.webix_header.webix_dark .webix_inp_label,
.webix_header.webix_dark .webix_inp_top_label,
.webix_header.webix_dark .webix_slider_title {
  color: #FFFFFF;
}
.webix_header.webix_dark .webix_secondary .webix_icon,
.webix_header.webix_dark .webix_transparent .webix_icon,
.webix_header.webix_dark .webix_secondary .webix_img_btn_text,
.webix_header.webix_dark .webix_transparent .webix_img_btn_text,
.webix_header.webix_dark .webix_secondary .webix_icon_btn,
.webix_header.webix_dark .webix_transparent .webix_icon_btn {
  color: #FFFFFF;
}
.webix_header.webix_dark .webix_transparent button {
  color: #FFFFFF;
}
.webix_header.webix_dark .webix_transparent button:hover,
.webix_header.webix_dark .webix_transparent button:focus {
  background-color: rgba(0, 0, 0, 0.2);
}
.webix_header.webix_dark .webix_transparent button:active {
  background-color: rgba(0, 0, 0, 0.4);
}
.webix_header.webix_dark .webix_secondary button,
.webix_header.webix_dark .webix_inp_counter_next,
.webix_header.webix_dark .webix_inp_counter_prev,
.webix_header.webix_dark .webix_segment_0,
.webix_header.webix_dark .webix_segment_1,
.webix_header.webix_dark .webix_segment_N,
.webix_header.webix_dark .webix_menu-x .webix_list_item {
  background-color: rgba(0, 0, 0, 0.2);
  color: #FFFFFF;
  border-color: transparent;
}
.webix_header.webix_dark .webix_view > .webix_template {
  color: #FFFFFF;
  background-color: #657584;
}
.webix_header.webix_dark .webix_secondary button:hover,
.webix_header.webix_dark .webix_menu-x .webix_list_item:hover,
.webix_header.webix_dark .webix_inp_counter_next:hover,
.webix_header.webix_dark .webix_inp_counter_prev:hover,
.webix_header.webix_dark .webix_segment_0:hover,
.webix_header.webix_dark .webix_segment_1:hover,
.webix_header.webix_dark .webix_segment_N:hover {
  color: #FFFFFF;
  background-color: rgba(0, 0, 0, 0.4);
}
.webix_header.webix_dark .webix_secondary button:focus,
.webix_header.webix_dark .webix_menu-x .webix_list_item:focus,
.webix_header.webix_dark .webix_inp_counter_next:focus,
.webix_header.webix_dark .webix_inp_counter_prev:focus,
.webix_header.webix_dark .webix_segment_0:focus,
.webix_header.webix_dark .webix_segment_1:focus,
.webix_header.webix_dark .webix_segment_N:focus {
  background-color: rgba(0, 0, 0, 0.4);
  color: #FFFFFF;
}
.webix_header.webix_dark .webix_secondary button:active,
.webix_header.webix_dark .webix_menu-x .webix_list_item:active,
.webix_header.webix_dark .webix_inp_counter_next:active,
.webix_header.webix_dark .webix_inp_counter_prev:active,
.webix_header.webix_dark .webix_segment_0:active,
.webix_header.webix_dark .webix_segment_1:active,
.webix_header.webix_dark .webix_segment_N:active {
  background-color: rgba(0, 0, 0, 0.5);
  color: #FFFFFF;
}
.webix_header.webix_dark .webix_inp_static,
.webix_header.webix_dark input[type=text],
.webix_header.webix_dark input[type=combo],
.webix_header.webix_dark input[type=datepicker],
.webix_header.webix_dark textarea,
.webix_header.webix_dark select,
.webix_header.webix_dark .webix_text_highlight {
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.7);
}
.webix_header.webix_dark .webix_el_texthighlight input,
.webix_header.webix_dark .webix_el_texthighlight textarea {
  caret-color: rgba(255, 255, 255, 0.7);
}
.webix_header.webix_dark .webix_inp_static input[type=text] {
  background-color: transparent;
}
.webix_header.webix_dark select option {
  background-color: #657584;
  color: #FFFFFF;
}
.webix_header.webix_dark .webix_multicombo_value,
.webix_header.webix_dark .webix_multicombo_tag {
  color: #657584;
}
.webix_header.webix_dark .webix_inp_static:focus,
.webix_header.webix_dark input[type=text]:focus,
.webix_header.webix_dark input[type=combo]:focus,
.webix_header.webix_dark input[type=datepicker]:focus,
.webix_header.webix_dark textarea:focus,
.webix_header.webix_dark select:focus {
  border-color: #FFFFFF;
  box-shadow: none;
}
.webix_header.webix_dark .webix_el_counter button {
  background-color: rgba(0, 0, 0, 0.3);
}
.webix_header.webix_dark .webix_el_counter input[type=text]:focus {
  border-color: transparent;
}
.webix_header.webix_dark .webix_placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.webix_header.webix_dark ::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.webix_header.webix_dark ::-moz-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.webix_header.webix_dark :-moz-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.webix_header.webix_dark :-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.webix_header.webix_dark .webix_input_icon {
  color: rgba(255, 255, 255, 0.7);
}
.webix_header.webix_dark .webix_selected.webix_segment_0,
.webix_header.webix_dark .webix_selected.webix_segment_1,
.webix_header.webix_dark .webix_selected.webix_segment_N {
  background: #1CA1C1;
  color: #FFFFFF;
}
.webix_header.webix_dark .webix_selected.webix_segment_0:hover,
.webix_header.webix_dark .webix_selected.webix_segment_1:hover,
.webix_header.webix_dark .webix_selected.webix_segment_N:hover {
  background-color: #1992af;
}
.webix_header.webix_dark .webix_selected.webix_segment_0:focus,
.webix_header.webix_dark .webix_selected.webix_segment_1:focus,
.webix_header.webix_dark .webix_selected.webix_segment_N:focus {
  background-color: #1992af;
}
.webix_header.webix_dark .webix_selected.webix_segment_0:active,
.webix_header.webix_dark .webix_selected.webix_segment_1:active,
.webix_header.webix_dark .webix_selected.webix_segment_N:active {
  background-color: #17839d;
}
.webix_header.webix_dark .webix_el_icon,
.webix_header.webix_dark .webix_icon_button {
  color: #FFFFFF;
}
.webix_header.webix_dark .webix_control {
  border-color: #1CA1C1;
}
.webix_header.webix_dark .webix_icon_button:hover:before {
  background-color: rgba(0, 0, 0, 0.2);
}
.webix_header.webix_dark .webix_icon_button:hover .webix_icon,
.webix_header.webix_dark .webix_icon_button:focus .webix_icon {
  opacity: 1;
}
.webix_header.webix_dark .webix_icon_button .webix_icon {
  color: #FFFFFF;
  opacity: 0.7;
}
.webix_header.webix_dark .webix_custom_checkbox,
.webix_header.webix_dark .webix_custom_radio {
  color: #FFFFFF;
}
.webix_header.webix_dark .webix_custom_checkbox:focus,
.webix_header.webix_dark .webix_custom_radio:focus {
  color: #f0f0f0;
}
.webix_header.webix_dark .webix_label_right {
  color: #FFFFFF;
}
.webix_header.webix_dark .webix_view.webix_control .webix_disabled_box .webix_button {
  color: rgba(255, 255, 255, 0.4);
  background-color: rgba(0, 0, 0, 0.1);
  text-shadow: none;
}
.webix_header.webix_dark .webix_view.webix_control .webix_disabled_box .webix_img_btn_text {
  color: rgba(255, 255, 255, 0.4);
}
.webix_header.webix_dark .webix_disabled_view.webix_control .webix_icon_btn,
.webix_header.webix_dark .webix_disabled_view.webix_control .webix_icon {
  color: rgba(255, 255, 255, 0.4);
}
.webix_header.webix_dark .webix_disabled_view.webix_menu-x .webix_list_item.menu {
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(255, 255, 255, 0.4);
}
.webix_header.webix_dark .webix_toolbar .webix_disabled_view.webix_control .webix_icon,
.webix_header.webix_dark .webix_toolbar .webix_disabled_view.webix_control .webix_disabled_box .webix_img_btn_text,
.webix_header.webix_dark .webix_toolbar .webix_disabled_view.webix_control .webix_icon_btn {
  color: rgba(255, 255, 255, 0.4);
}
.webix_header.webix_dark .webix_view.webix_control .webix_disabled_box label,
.webix_header.webix_dark .webix_view.webix_control .webix_disabled_box .webix_input_icon,
.webix_header.webix_dark .webix_view.webix_control .webix_disabled_box button {
  color: rgba(255, 255, 255, 0.4);
}
.webix_header.webix_dark .webix_view.webix_control .webix_disabled_box input,
.webix_header.webix_dark .webix_view.webix_control .webix_disabled_box select,
.webix_header.webix_dark .webix_view.webix_control .webix_disabled_box textarea,
.webix_header.webix_dark .webix_view.webix_control .webix_disabled_box .webix_inp_static,
.webix_header.webix_dark .webix_view.webix_control .webix_disabled_box .webix_inp_counter_prev,
.webix_header.webix_dark .webix_view.webix_control .webix_disabled_box .webix_inp_counter_next {
  color: rgba(255, 255, 255, 0.4);
  background-color: rgba(0, 0, 0, 0.1);
  border-color: transparent;
}
.webix_header.webix_dark .webix_view.webix_control .webix_disabled_box .webix_inp_static input {
  background-color: transparent;
}
.webix_header.webix_dark .webix_view.webix_control .webix_disabled_box .webix_segment_0,
.webix_header.webix_dark .webix_view.webix_control .webix_disabled_box .webix_segment_1,
.webix_header.webix_dark .webix_view.webix_control .webix_disabled_box .webix_segment_N,
.webix_header.webix_dark .webix_view.webix_control .webix_disabled_box .webix_item_tab {
  color: rgba(255, 255, 255, 0.4);
  background-color: rgba(0, 0, 0, 0.1);
  text-shadow: none;
}
.webix_header.webix_dark .webix_view.webix_control .webix_disabled_box .webix_segment_0.webix_selected,
.webix_header.webix_dark .webix_view.webix_control .webix_disabled_box .webix_segment_1.webix_selected,
.webix_header.webix_dark .webix_view.webix_control .webix_disabled_box .webix_segment_N.webix_selected {
  background-color: rgba(0, 0, 0, 0.15);
}
.webix_header.webix_dark .webix_disabled_top_label {
  color: rgba(255, 255, 255, 0.4);
}
.webix_header.webix_dark .webix_invalid .webix_el_box input,
.webix_header.webix_dark .webix_invalid .webix_el_box .webix_inp_static,
.webix_header.webix_dark .webix_invalid .webix_el_box select,
.webix_header.webix_dark .webix_invalid .webix_el_box textarea,
.webix_header.webix_dark input.webix_invalid,
.webix_header.webix_dark select.webix_invalid,
.webix_header.webix_dark textarea.webix_invalid {
  color: #ffdedb;
  background-color: rgba(255, 92, 76, 0.3);
  border-color: #ff8d82;
}
.webix_header.webix_dark .webix_invalid .webix_multicombo_value,
.webix_header.webix_dark .webix_invalid .webix_multicombo_tag {
  color: #FF5C4C;
}
.webix_header.webix_dark .webix_invalid .webix_el_box .webix_inp_static input {
  background-color: transparent;
}
.webix_header.webix_dark {
  color: #FFFFFF;
}
.webix_header > div {
  line-height: 44px;
  padding-left: 10px;
  padding-top: 0;
  padding-bottom: 0;
}
.webix_spacer {
  background: transparent;
}
.webix_view_animate {
  position: relative;
  width: 100%;
  height: 100%;
}
x\:ui {
  display: none;
}
.webix_view.webix_flexlayout {
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: row;
  overflow: hidden;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_flexlayout > div {
  display: block !important;
}
.webix_view_align {
  background: transparent;
}
.webix_view div,
.webix_view span,
.webix_view a.webix_list_item,
.webix_view.webix_window {
  outline: none;
}
textarea,
input,
select,
button,
.webix_view div.webix_inp_static:focus {
  outline: none;
}
.webix_resize_area {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
.webix_resize_handle_x .webix_handle_content,
.webix_resize_handle_y .webix_handle_content {
  width: 100%;
  height: 100%;
  background: rgba(100, 100, 100, 0.1);
  border: 1px dotted #ebebeb;
}
.webix_resize_handle_x {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: ew-resize;
  width: 1px;
  height: 100%;
  position: absolute;
  z-index: 2;
  top: 0px;
}
.webix_resize_handle_x .webix_handle_content {
  border-width: 0px 1px;
}
.webix_resize_origin_x {
  cursor: ew-resize;
  width: 3px;
  height: 100%;
  position: absolute;
  z-index: 2;
  top: 0px;
}
.webix_resize_handle_y {
  cursor: ns-resize;
  height: 1px;
  width: 100%;
  position: absolute;
  z-index: 2;
  left: 0px;
}
.webix_resize_handle_y .webix_handle_content {
  border-width: 1px 0px;
}
.webix_resize_origin_y {
  cursor: ns-resize;
  height: 3px;
  width: 100%;
  position: absolute;
  z-index: 2;
  left: 0px;
}
.webix_resizer {
  position: relative;
}
.webix_resizer.webix_disabled_view {
  cursor: default;
}
.webix_resizer_vy {
  cursor: ns-resize;
  margin-top: 0px !important;
  border: 0px !important;
  padding: 0px !important;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAADAQMAAABCowZuAAAABlBMVEUAAACkvtSA7tmIAAAAAXRSTlMAQObYZgAAAA9JREFUCB1jXMUABoxAGgAJaAFXPIkJqAAAAABJRU5ErkJggg==") no-repeat center center;
}
.webix_resizer_vx {
  cursor: ew-resize;
  margin-left: 0px !important;
  border: 0px !important;
  padding: 0px !important;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAAXAQMAAAD0oWdxAAAABlBMVEUAAACkvtSA7tmIAAAAAXRSTlMAQObYZgAAAA5JREFUeF5jWMDAQDwGAKyuB4FpGQdzAAAAAElFTkSuQmCC") no-repeat center center;
}
.webix_resizer_y {
  cursor: ns-resize;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAADAQMAAABCowZuAAAABlBMVEUAAACkvtSA7tmIAAAAAXRSTlMAQObYZgAAAA9JREFUCB1jXMUABoxAGgAJaAFXPIkJqAAAAABJRU5ErkJggg==") no-repeat center center;
  background-color: #ffffff;
}
.webix_resizer_x {
  cursor: ew-resize;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAAXAQMAAAD0oWdxAAAABlBMVEUAAACkvtSA7tmIAAAAAXRSTlMAQObYZgAAAA5JREFUeF5jWMDAQDwGAKyuB4FpGQdzAAAAAElFTkSuQmCC") no-repeat center center;
  background-color: #ffffff;
}
.webix_accordionitem_body {
  position: relative;
}
.webix_accordionitem_body > div {
  border-width: 0px!important;
}
.webix_accordionitem_header {
  border-bottom: 1px solid #DADEE0;
  position: relative;
  z-index: 2;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #475466;
  letter-spacing: 0px;
  line-height: 42px;
  cursor: pointer;
  overflow: hidden;
}
.webix_accordionitem_header.webix_dark {
  color: #FFFFFF;
}
.webix_accordionitem_header .webix_el_label .webix_el_box {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #475466;
  letter-spacing: 0px;
}
.webix_accordionitem_header.webix_dark {
  background: #657584;
  border-color: #657584;
}
.webix_accordionitem_header.webix_dark .webix_el_button,
.webix_accordionitem_header.webix_dark .webix_el_label .webix_el_box,
.webix_accordionitem_header.webix_dark .webix_inp_label,
.webix_accordionitem_header.webix_dark .webix_inp_top_label,
.webix_accordionitem_header.webix_dark .webix_slider_title {
  color: #FFFFFF;
}
.webix_accordionitem_header.webix_dark .webix_secondary .webix_icon,
.webix_accordionitem_header.webix_dark .webix_transparent .webix_icon,
.webix_accordionitem_header.webix_dark .webix_secondary .webix_img_btn_text,
.webix_accordionitem_header.webix_dark .webix_transparent .webix_img_btn_text,
.webix_accordionitem_header.webix_dark .webix_secondary .webix_icon_btn,
.webix_accordionitem_header.webix_dark .webix_transparent .webix_icon_btn {
  color: #FFFFFF;
}
.webix_accordionitem_header.webix_dark .webix_transparent button {
  color: #FFFFFF;
}
.webix_accordionitem_header.webix_dark .webix_transparent button:hover,
.webix_accordionitem_header.webix_dark .webix_transparent button:focus {
  background-color: rgba(0, 0, 0, 0.2);
}
.webix_accordionitem_header.webix_dark .webix_transparent button:active {
  background-color: rgba(0, 0, 0, 0.4);
}
.webix_accordionitem_header.webix_dark .webix_secondary button,
.webix_accordionitem_header.webix_dark .webix_inp_counter_next,
.webix_accordionitem_header.webix_dark .webix_inp_counter_prev,
.webix_accordionitem_header.webix_dark .webix_segment_0,
.webix_accordionitem_header.webix_dark .webix_segment_1,
.webix_accordionitem_header.webix_dark .webix_segment_N,
.webix_accordionitem_header.webix_dark .webix_menu-x .webix_list_item {
  background-color: rgba(0, 0, 0, 0.2);
  color: #FFFFFF;
  border-color: transparent;
}
.webix_accordionitem_header.webix_dark .webix_view > .webix_template {
  color: #FFFFFF;
  background-color: #657584;
}
.webix_accordionitem_header.webix_dark .webix_secondary button:hover,
.webix_accordionitem_header.webix_dark .webix_menu-x .webix_list_item:hover,
.webix_accordionitem_header.webix_dark .webix_inp_counter_next:hover,
.webix_accordionitem_header.webix_dark .webix_inp_counter_prev:hover,
.webix_accordionitem_header.webix_dark .webix_segment_0:hover,
.webix_accordionitem_header.webix_dark .webix_segment_1:hover,
.webix_accordionitem_header.webix_dark .webix_segment_N:hover {
  color: #FFFFFF;
  background-color: rgba(0, 0, 0, 0.4);
}
.webix_accordionitem_header.webix_dark .webix_secondary button:focus,
.webix_accordionitem_header.webix_dark .webix_menu-x .webix_list_item:focus,
.webix_accordionitem_header.webix_dark .webix_inp_counter_next:focus,
.webix_accordionitem_header.webix_dark .webix_inp_counter_prev:focus,
.webix_accordionitem_header.webix_dark .webix_segment_0:focus,
.webix_accordionitem_header.webix_dark .webix_segment_1:focus,
.webix_accordionitem_header.webix_dark .webix_segment_N:focus {
  background-color: rgba(0, 0, 0, 0.4);
  color: #FFFFFF;
}
.webix_accordionitem_header.webix_dark .webix_secondary button:active,
.webix_accordionitem_header.webix_dark .webix_menu-x .webix_list_item:active,
.webix_accordionitem_header.webix_dark .webix_inp_counter_next:active,
.webix_accordionitem_header.webix_dark .webix_inp_counter_prev:active,
.webix_accordionitem_header.webix_dark .webix_segment_0:active,
.webix_accordionitem_header.webix_dark .webix_segment_1:active,
.webix_accordionitem_header.webix_dark .webix_segment_N:active {
  background-color: rgba(0, 0, 0, 0.5);
  color: #FFFFFF;
}
.webix_accordionitem_header.webix_dark .webix_inp_static,
.webix_accordionitem_header.webix_dark input[type=text],
.webix_accordionitem_header.webix_dark input[type=combo],
.webix_accordionitem_header.webix_dark input[type=datepicker],
.webix_accordionitem_header.webix_dark textarea,
.webix_accordionitem_header.webix_dark select,
.webix_accordionitem_header.webix_dark .webix_text_highlight {
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.7);
}
.webix_accordionitem_header.webix_dark .webix_el_texthighlight input,
.webix_accordionitem_header.webix_dark .webix_el_texthighlight textarea {
  caret-color: rgba(255, 255, 255, 0.7);
}
.webix_accordionitem_header.webix_dark .webix_inp_static input[type=text] {
  background-color: transparent;
}
.webix_accordionitem_header.webix_dark select option {
  background-color: #657584;
  color: #FFFFFF;
}
.webix_accordionitem_header.webix_dark .webix_multicombo_value,
.webix_accordionitem_header.webix_dark .webix_multicombo_tag {
  color: #657584;
}
.webix_accordionitem_header.webix_dark .webix_inp_static:focus,
.webix_accordionitem_header.webix_dark input[type=text]:focus,
.webix_accordionitem_header.webix_dark input[type=combo]:focus,
.webix_accordionitem_header.webix_dark input[type=datepicker]:focus,
.webix_accordionitem_header.webix_dark textarea:focus,
.webix_accordionitem_header.webix_dark select:focus {
  border-color: #FFFFFF;
  box-shadow: none;
}
.webix_accordionitem_header.webix_dark .webix_el_counter button {
  background-color: rgba(0, 0, 0, 0.3);
}
.webix_accordionitem_header.webix_dark .webix_el_counter input[type=text]:focus {
  border-color: transparent;
}
.webix_accordionitem_header.webix_dark .webix_placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.webix_accordionitem_header.webix_dark ::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.webix_accordionitem_header.webix_dark ::-moz-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.webix_accordionitem_header.webix_dark :-moz-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.webix_accordionitem_header.webix_dark :-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.webix_accordionitem_header.webix_dark .webix_input_icon {
  color: rgba(255, 255, 255, 0.7);
}
.webix_accordionitem_header.webix_dark .webix_selected.webix_segment_0,
.webix_accordionitem_header.webix_dark .webix_selected.webix_segment_1,
.webix_accordionitem_header.webix_dark .webix_selected.webix_segment_N {
  background: #1CA1C1;
  color: #FFFFFF;
}
.webix_accordionitem_header.webix_dark .webix_selected.webix_segment_0:hover,
.webix_accordionitem_header.webix_dark .webix_selected.webix_segment_1:hover,
.webix_accordionitem_header.webix_dark .webix_selected.webix_segment_N:hover {
  background-color: #1992af;
}
.webix_accordionitem_header.webix_dark .webix_selected.webix_segment_0:focus,
.webix_accordionitem_header.webix_dark .webix_selected.webix_segment_1:focus,
.webix_accordionitem_header.webix_dark .webix_selected.webix_segment_N:focus {
  background-color: #1992af;
}
.webix_accordionitem_header.webix_dark .webix_selected.webix_segment_0:active,
.webix_accordionitem_header.webix_dark .webix_selected.webix_segment_1:active,
.webix_accordionitem_header.webix_dark .webix_selected.webix_segment_N:active {
  background-color: #17839d;
}
.webix_accordionitem_header.webix_dark .webix_el_icon,
.webix_accordionitem_header.webix_dark .webix_icon_button {
  color: #FFFFFF;
}
.webix_accordionitem_header.webix_dark .webix_control {
  border-color: #1CA1C1;
}
.webix_accordionitem_header.webix_dark .webix_icon_button:hover:before {
  background-color: rgba(0, 0, 0, 0.2);
}
.webix_accordionitem_header.webix_dark .webix_icon_button:hover .webix_icon,
.webix_accordionitem_header.webix_dark .webix_icon_button:focus .webix_icon {
  opacity: 1;
}
.webix_accordionitem_header.webix_dark .webix_icon_button .webix_icon {
  color: #FFFFFF;
  opacity: 0.7;
}
.webix_accordionitem_header.webix_dark .webix_custom_checkbox,
.webix_accordionitem_header.webix_dark .webix_custom_radio {
  color: #FFFFFF;
}
.webix_accordionitem_header.webix_dark .webix_custom_checkbox:focus,
.webix_accordionitem_header.webix_dark .webix_custom_radio:focus {
  color: #f0f0f0;
}
.webix_accordionitem_header.webix_dark .webix_label_right {
  color: #FFFFFF;
}
.webix_accordionitem_header.webix_dark .webix_view.webix_control .webix_disabled_box .webix_button {
  color: rgba(255, 255, 255, 0.4);
  background-color: rgba(0, 0, 0, 0.1);
  text-shadow: none;
}
.webix_accordionitem_header.webix_dark .webix_view.webix_control .webix_disabled_box .webix_img_btn_text {
  color: rgba(255, 255, 255, 0.4);
}
.webix_accordionitem_header.webix_dark .webix_disabled_view.webix_control .webix_icon_btn,
.webix_accordionitem_header.webix_dark .webix_disabled_view.webix_control .webix_icon {
  color: rgba(255, 255, 255, 0.4);
}
.webix_accordionitem_header.webix_dark .webix_disabled_view.webix_menu-x .webix_list_item.menu {
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(255, 255, 255, 0.4);
}
.webix_accordionitem_header.webix_dark .webix_toolbar .webix_disabled_view.webix_control .webix_icon,
.webix_accordionitem_header.webix_dark .webix_toolbar .webix_disabled_view.webix_control .webix_disabled_box .webix_img_btn_text,
.webix_accordionitem_header.webix_dark .webix_toolbar .webix_disabled_view.webix_control .webix_icon_btn {
  color: rgba(255, 255, 255, 0.4);
}
.webix_accordionitem_header.webix_dark .webix_view.webix_control .webix_disabled_box label,
.webix_accordionitem_header.webix_dark .webix_view.webix_control .webix_disabled_box .webix_input_icon,
.webix_accordionitem_header.webix_dark .webix_view.webix_control .webix_disabled_box button {
  color: rgba(255, 255, 255, 0.4);
}
.webix_accordionitem_header.webix_dark .webix_view.webix_control .webix_disabled_box input,
.webix_accordionitem_header.webix_dark .webix_view.webix_control .webix_disabled_box select,
.webix_accordionitem_header.webix_dark .webix_view.webix_control .webix_disabled_box textarea,
.webix_accordionitem_header.webix_dark .webix_view.webix_control .webix_disabled_box .webix_inp_static,
.webix_accordionitem_header.webix_dark .webix_view.webix_control .webix_disabled_box .webix_inp_counter_prev,
.webix_accordionitem_header.webix_dark .webix_view.webix_control .webix_disabled_box .webix_inp_counter_next {
  color: rgba(255, 255, 255, 0.4);
  background-color: rgba(0, 0, 0, 0.1);
  border-color: transparent;
}
.webix_accordionitem_header.webix_dark .webix_view.webix_control .webix_disabled_box .webix_inp_static input {
  background-color: transparent;
}
.webix_accordionitem_header.webix_dark .webix_view.webix_control .webix_disabled_box .webix_segment_0,
.webix_accordionitem_header.webix_dark .webix_view.webix_control .webix_disabled_box .webix_segment_1,
.webix_accordionitem_header.webix_dark .webix_view.webix_control .webix_disabled_box .webix_segment_N,
.webix_accordionitem_header.webix_dark .webix_view.webix_control .webix_disabled_box .webix_item_tab {
  color: rgba(255, 255, 255, 0.4);
  background-color: rgba(0, 0, 0, 0.1);
  text-shadow: none;
}
.webix_accordionitem_header.webix_dark .webix_view.webix_control .webix_disabled_box .webix_segment_0.webix_selected,
.webix_accordionitem_header.webix_dark .webix_view.webix_control .webix_disabled_box .webix_segment_1.webix_selected,
.webix_accordionitem_header.webix_dark .webix_view.webix_control .webix_disabled_box .webix_segment_N.webix_selected {
  background-color: rgba(0, 0, 0, 0.15);
}
.webix_accordionitem_header.webix_dark .webix_disabled_top_label {
  color: rgba(255, 255, 255, 0.4);
}
.webix_accordionitem_header.webix_dark .webix_invalid .webix_el_box input,
.webix_accordionitem_header.webix_dark .webix_invalid .webix_el_box .webix_inp_static,
.webix_accordionitem_header.webix_dark .webix_invalid .webix_el_box select,
.webix_accordionitem_header.webix_dark .webix_invalid .webix_el_box textarea,
.webix_accordionitem_header.webix_dark input.webix_invalid,
.webix_accordionitem_header.webix_dark select.webix_invalid,
.webix_accordionitem_header.webix_dark textarea.webix_invalid {
  color: #ffdedb;
  background-color: rgba(255, 92, 76, 0.3);
  border-color: #ff8d82;
}
.webix_accordionitem_header.webix_dark .webix_invalid .webix_multicombo_value,
.webix_accordionitem_header.webix_dark .webix_invalid .webix_multicombo_tag {
  color: #FF5C4C;
}
.webix_accordionitem_header.webix_dark .webix_invalid .webix_el_box .webix_inp_static input {
  background-color: transparent;
}
.webix_accordionitem_label {
  height: 100%;
  padding-left: 10px;
}
.webix_accordionitem_button {
  float: right;
  position: relative;
  height: 11px;
  width: 11px;
  margin: 14.5px 13px;
}
.webix_carousel {
  position: relative;
}
.webix_nav_panel {
  bottom: 16px;
  height: 14px;
  line-height: 14px;
  overflow: hidden;
  position: absolute;
  text-align: center;
  padding: 2px 0;
}
.webix_nav_item {
  display: inline-block;
  height: 10px;
  margin: 0 2px;
  width: 10px;
  cursor: pointer;
  border-radius: 50%;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
.webix_nav_inactive {
  border: 2px solid rgba(0, 0, 0, 0.15);
  opacity: 0.6;
  background: #fff;
}
.webix_nav_active {
  border: 2px solid rgba(0, 0, 0, 0.1);
  background: #fff;
}
.webix_nav_active:hover,
.webix_nav_active:focus {
  box-shadow: 0 0 2px #fff;
}
.webix_nav_button_prev,
.webix_nav_button_next {
  cursor: pointer;
  display: block;
  height: 32px;
  line-height: 32px;
  position: absolute;
  width: 32px;
}
.webix_nav_button_prev .webix_nav_button_inner,
.webix_nav_button_next .webix_nav_button_inner {
  height: 100%;
  width: 100%;
}
.webix_nav_button_prev .webix_nav_button_inner {
  opacity: 0.8;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDowNDNEOTBEMTYyNzQxMUUzOEU1N0MyQTEyMzIxMDVFQSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDowNDNEOTBEMjYyNzQxMUUzOEU1N0MyQTEyMzIxMDVFQSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjA0M0Q5MENGNjI3NDExRTM4RTU3QzJBMTIzMjEwNUVBIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjA0M0Q5MEQwNjI3NDExRTM4RTU3QzJBMTIzMjEwNUVBIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+yGPRLgAAAPBJREFUeNpi+P//vycQP/tPfQAy05MRxGBgYJBkoA14DrLgPwMNARMDjcGgteAINN5CCaokI3UcB2I+UNQBsTghxaRacAaI+aH62IB4MzUtuAjEwlA9zEC8hhhNxFpwFYhFkQxfTqyriLHgFhBLQtWC8s18UsKUkAV3gVgGyfBZpKYIQhbYQtWAcBg5BRIhC26j+WAOtS0AgRvQ9A6L4IXUtgAELqOlopXUtgAEzgOxIFQPKxCvo7YFIHAKLSdvo7YFIHAUiHmgeiUIKSanNLUC4i1ALAHENoQUD48a7TkNzX8BsiAFxKCB4U+BOBkgwAAsAiGcWMs58QAAAABJRU5ErkJggg==");
}
.webix_nav_button_prev .webix_nav_button_inner:hover,
.webix_nav_button_prev .webix_nav_button_inner:focus {
  opacity: 1;
}
.webix_nav_button_next .webix_nav_button_inner {
  opacity: 0.8;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpEODJBRDc2RTYyNzMxMUUzQTMxN0IxNjAwN0JBOUE4OSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpEODJBRDc2RjYyNzMxMUUzQTMxN0IxNjAwN0JBOUE4OSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkQ4MkFENzZDNjI3MzExRTNBMzE3QjE2MDA3QkE5QTg5IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkQ4MkFENzZENjI3MzExRTNBMzE3QjE2MDA3QkE5QTg5Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+crpRPgAAAOhJREFUeNpi+P//vycQP/tPfQAy05MRxGBgYJBkoA14DrLgPwMNARMDjcGgsCAMGkdHyLKBiNQgDoomIOYH4uOkJiViLNgMxGxIlpyhtgUgsAaImaHqhYH4IrUtAIHlSJaIAvFValsAAvOBmBGqTxKIb1HbAhCYhWSJDBDfobYFIBAO1QvCNtS2YA6SD6SB+DY1LViIFNFiQHydmnGwEi0VXaZmKloHxKxQ9YJAfJ6a+WAbWk4+Re2cLAFVxwPER0lNEcSUpjZALAHEW4DYitTCdHjUaM9paP4LkAUpIAYNDH8KxMkAAQYAlwMhmq8XxigAAAAASUVORK5CYII=");
}
.webix_nav_button_next .webix_nav_button_inner:hover,
.webix_nav_button_next .webix_nav_button_inner:focus {
  opacity: 1;
}
.webix_nav_panel_corner {
  right: 80px;
}
.webix_nav_button_corner.webix_nav_button_prev {
  bottom: 9px;
  right: 44px;
}
.webix_nav_button_corner.webix_nav_button_prev .webix_nav_button_inner:hover,
.webix_nav_button_corner.webix_nav_button_prev .webix_nav_button_inner:focus {
  opacity: 1;
}
.webix_nav_button_corner.webix_nav_button_next {
  bottom: 9px;
  right: 8px;
}
.webix_nav_button_corner.webix_nav_button_next .webix_nav_button_inner:hover,
.webix_nav_button_corner.webix_nav_button_next .webix_nav_button_inner:focus {
  opacity: 1;
}
.webix_nav_panel_side {
  margin: 0 auto;
  left: 0;
  width: 100%;
}
.webix_nav_button_side {
  height: 86px;
  line-height: 86px;
  width: 56px;
  top: 50%;
  margin-top: -43px;
}
.webix_nav_button_side.webix_nav_button_next {
  left: auto;
  right: 16px;
}
.webix_nav_button_side.webix_nav_button_next .webix_nav_button_inner {
  opacity: 0.8;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAiCAYAAACwaJKDAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTc4MTkxODY2MzBGMTFFMzk1MUQ4RTNCRjNCMUVFRUMiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTc4MTkxODU2MzBGMTFFMzk1MUQ4RTNCRjNCMUVFRUMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjA4NjlGM0NFNjE4OTExRTNBRjMwOTNFODNBRTJCRENBIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjA4NjlGM0NGNjE4OTExRTNBRjMwOTNFODNBRTJCRENBIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+D/KEcQAAAOhJREFUeNqs1jsOwjAMBuCKhR2uxsLGxg1a+hD0wVGYGJC4DgssnAFVQjLukKWk/WM7lv4OqfQ1UdLWCRGtOTdOz3lwtpzEkuFyp/9KreiX/JVa0DdNV6ZF9zRfmQYdcgbwQYNGhccDXQzYN4jgXIOa4bkntgAuNKgaDtlNMRx69hoAlxpUBEvfawRXGnRIjeBFIq8e3F9KZ5mDWXbS5QeBEjQYDEULALbSwy8GEYrARvpBUYNTaGkBfagZHKMIrKU/vioW6NANAE+aZuIVE3RoHxN06NUDHq2t5Ipz4Xw4T87O2vT+BBgAeQQKvezNqP0AAAAASUVORK5CYII=");
}
.webix_nav_button_side.webix_nav_button_next .webix_nav_button_inner:hover,
.webix_nav_button_side.webix_nav_button_next .webix_nav_button_inner:focus {
  opacity: 1;
}
.webix_nav_button_side.webix_nav_button_prev {
  left: 16px;
  right: auto;
}
.webix_nav_button_side.webix_nav_button_prev .webix_nav_button_inner {
  opacity: 0.8;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAiCAYAAACwaJKDAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RjIzRTVCQkY2MzBGMTFFM0FDNTBDNzdDRkQ0NTI0NzciIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RjIzRTVCQkU2MzBGMTFFM0FDNTBDNzdDRkQ0NTI0NzciIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjA4NjlGM0NFNjE4OTExRTNBRjMwOTNFODNBRTJCRENBIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjA4NjlGM0NGNjE4OTExRTNBRjMwOTNFODNBRTJCRENBIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+wRT1eAAAAM9JREFUeNqs1jsKwlAQBdDBxlL3ZmVlmRXYaaVbCYg7kti4BQu1e85AhBDfZz73wm1ewsmfDKWUKNgNd+C+uVfuOgoe0n8uaFDyQoOSOxqU7NDgUfaDgxZUDWpRE6hBzWALdYE11A2W0BCYQ8PgHIWAUxQG/lAoKF0Q0ZLq+ZA1o35qnO3eevlweL4AgXOL5yhc2hCCa0d0w63744I1T9MMa989E2z5/NSw9Retgj3DRAveeseeGnyLDGgl+BkdJXNwH0WlHfcxDr09d/UVYADVjwptZ8mPvQAAAABJRU5ErkJggg==");
}
.webix_nav_button_side.webix_nav_button_prev .webix_nav_button_inner:hover,
.webix_nav_button_side.webix_nav_button_prev .webix_nav_button_inner:focus {
  opacity: 1;
}
.webix_carousel > .webix_scroll_x,
.webix_carousel > .webix_scroll_y {
  background-color: rgba(0, 0, 0, 0);
}
.webix_dark .webix_nav_inactive {
  background-color: #95a5a6;
}
.webix_dark .webix_nav_active {
  background-color: #7f8c8d;
}
.webix_dark .webix_nav_button_prev .webix_nav_button_inner {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH3gcdCSIURgotfwAAASxJREFUSMfNlbErhlEUh5/vJZOSQSijshgsFiklhdj0lcHw5SP7uX+A8we4d1c+g2RgsIjIxmCxGCzKRO9bMjCSWE5WpXPrO9MZbs9T5577u7XNrTgPtIBBfKsEmkUmOMZsFZngv5KCzNW2gmsbbT2H4AZY0CAVcOUtuAXmNMi7xtQF7HgK7oBZDfKmMXUAB8Cil+AemNEgrwbfB5a8LvnB4C8aU83Gsuy1RY/AtAYpDb4NNDzXtKFBnqyvA+ve72BXYxqy/shyy1UwDFxqTP0a5BvYAPa8X/KISfo0yBewChx6R8UocKEx9ZpkBTj2zqIx4Fxj6tEgn7aqZ95RMQ6cakzdGuTDxuUedhPAicY0AEz+dbjzn3E9ZX9ue3w4ZUZ+VQBrQJUB/gw0fwC/UlVpnH2f9AAAAABJRU5ErkJggg==");
}
.webix_dark .webix_nav_button_next .webix_nav_button_inner {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH3gcdCSENCUzWfAAAAS1JREFUSMfN1TFLXEEUhuFnr8FKCBaGiK2FRQobG7GSgBG2E8IWFmE3wX7uD3B+QOb2QpaAiMWmsAkJBtMEizQ2FmmCXcIuhBRpDZI0g7XCXNhTD+8L53znTGf/ddrGEIvK1hiDqiW4zBxWLcFvJZWWayoEz3Mbz9sSfIl1mKCLr20I3sTUzMY6/MEzXJQWdHEcUzOTJVu4LD3kHRxlyW88xbfSKerldnViHX5lyffSMX2BgywZYxNXpffgVY6uWIcfWVpUMMQIYmqW8Lak4BB7sQ7/Ymoe4QzLpQQj9GMdbmJqFvAZK6WGfILdDJ/HJzwpFdOP6MU6/I2peYhTrJZctH6sw3VMzRw+YK30qdiIqXmM91i/b+Qe3OHNu6n/cMYt8icVXmLSAvwnBv8B+EFU7qlZ1xIAAAAASUVORK5CYII=");
}
.webix_dark .webix_nav_button_side.webix_nav_button_prev .webix_nav_button_inner {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAiCAYAAACwaJKDAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH3gcdCSI77dsQJgAAARxJREFUSMet1jFqAkEUxvH/rkIqLVN5ES2CjScQG6tUESx3SWOMLKgggt7BC6SxsnIr9w4ewAsEEis3RTBE3N3ZmfemHB4/HsPMm89L0xTJilbrLrAAGsAWeKkKwTEw/bfVAy6ea6cZ4HV9+8ogwMm6UwMI8Owrg5MoDDa+MjgF8LXBUqgtaERdwELUFcxFJWAmKgXvUA3wBtUC/1BNEKBCra4KXjt9MNScbadYJd7t9nGSVIGnnJpOnCRf7VbzYNMpURi8A7OCumW0Wr9aodrw7T39hedS+P5FhcFYCme/fSGcP6UEcPE8dYTNk98BLvdHWcLlf1ML2CVMzIC3gpK+U5YywEdJQMuDP50CmuGMt55C6B0AI+AR+ACGPw26r6m12WOpAAAAAElFTkSuQmCC");
}
.webix_dark .webix_nav_button_side.webix_nav_button_prev .webix_nav_button_inner:hover,
.webix_dark .webix_nav_button_side.webix_nav_button_prev .webix_nav_button_inner:focus {
  opacity: 1;
}
.webix_dark .webix_nav_button_side.webix_nav_button_next .webix_nav_button_inner {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAiCAYAAACwaJKDAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH3gcdCSE1IU5u4gAAAW1JREFUSMet1rtOwzAUgOG/5jbBwsQD8BAwIAa2TggegjmRgELbuFeo1GRlQEJMMDN264CUiYdgg0dolzIkFUEk9nHSbLGtT76cc+xaMA53gUegDnwCgfa9Vyp8CngCToFNYB940WF0WRWt57SPqsAK+C7oG+kwuiqLdg3992Xgtelk8jGN4w3gqGDMyTSOZ8eHB+8uM0X7XhMYGMbd6TC6dkIzcH8VsMr+aN9rCeCGEyqEhzZY5TWmcM8C3zihKdy2wIMiWJmWURZWtk1P4a4FvnVCUziwwP0srKQBLYSbALXFYuGU1zqMNBAYhrRUiSI0s/RvKcdZNoChcQt8Tz5TKSg/fQdQFqdJcJvAXha0Z1QCDixgW577drCbBxZXKRkYyOtpkm6lwf+VPwH7VcC/d5Qd7EjA39s0KQQ2UIvvfbZ3zoEHU2Rp3+u4vlDGqwSX6N4qwSX6ltMelAUB1oELYA6cAV/poTxXeZ/+AOFqn1e/Gh7yAAAAAElFTkSuQmCC");
}
.webix_dark .webix_nav_button_side.webix_nav_button_next .webix_nav_button_inner:hover,
.webix_dark .webix_nav_button_side.webix_nav_button_next .webix_nav_button_inner:focus {
  opacity: 1;
}
.webix_list_item {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 2px 12px;
  line-height: 31px;
  cursor: pointer;
  border-bottom: 1px solid #EDEFF0;
  text-align: left;
  border-color: #EDEFF0;
}
.webix_list_icon {
  vertical-align: middle;
  margin-right: 4px;
}
.webix_list_item.webix_invalid {
  background-color: #ffeeee;
}
.webix_list_item.webix_selected {
  color: #475466;
  background: #F4F5F9;
}
.webix_list_item.webix_selected:focus {
  background: #edeff5;
}
.webix_list .webix_list_item.webix_selected {
  border-bottom: 1px solid #EDEFF0;
  padding: 2px 12px;
}
.webix_group {
  position: relative;
}
.webix_group_back,
.webix_group_back.webix_selected {
  position: relative;
  background: #F4F5F9;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #313131;
  letter-spacing: 0.2px;
}
.webix_arrow_icon {
  position: absolute;
  top: 50%;
  bottom: 50%;
  margin-top: -10px;
  right: 8px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  color: #475466;
  font-size: 20px;
  font-family: "Webix Material Icons";
}
.webix_arrow_icon:before {
  content: '\F003';
}
.webix_group_back {
  padding-left: 33px;
}
.webix_group_back .webix_arrow_icon {
  left: 8px;
}
.webix_group_back .webix_arrow_icon:before {
  content: '\F002';
}
.dhx_pagelist {
  position: relative;
}
.webix_list_count {
  float: right;
  width: 25px;
  font-size: 14px;
  text-align: center;
  margin-right: 45px;
  box-shadow: 0 1px 0 #000000;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.6);
}
.webix_unit_header {
  padding: 0 12px;
  line-height: 35px;
  text-align: left;
  background-color: #F4F5F9;
}
.webix_list-x .webix_list_item {
  display: inline-block;
  vertical-align: top;
  border-right: 1px solid #EDEFF0;
  border-bottom: none;
  border-color: #EDEFF0;
}
.webix_list-x .webix_list_item.webix_selected {
  border-left: 1px solid #EDEFF0;
  border-right: 1px solid #EDEFF0;
  padding-left: 11px;
}
.webix_list-x .webix_scroll_cont {
  display: inline-block;
}
.webix_view.webix_list-x {
  white-space: nowrap;
}
/* Style for disabled items */
.webix_list_item.webix_disabled {
  color: #8b949e;
  background-color: #FFFFFF;
  cursor: not-allowed;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.webix_list_item.webix_disabled:focus {
  background-color: #FFFFFF;
}
.webix_list_item.webix_disabled .webix_badge {
  background-color: #eab993;
}
.webix_list_item.webix_disabled .webix_submenu_icon {
  visibility: hidden;
}
/*button of pager*/
.webix_view.webix_pager {
  padding: 3px 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: transparent;
}
.webix_pager_item,
.webix_pager_item_selected {
  padding: 0 6px;
  text-align: center;
  height: 100%;
  width: auto;
  min-width: 32px;
  margin: 0 2px;
  cursor: pointer;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 2px;
}
.webix_pager_item .webix_icon,
.webix_pager_item_selected .webix_icon {
  line-height: 0;
  font-size: 17px;
}
.webix_pager_item {
  background: #F4F5F9;
  color: #1CA1C1;
}
.webix_pager_item:hover {
  background-color: #e7e9f2;
}
.webix_pager_item:focus {
  background-color: #e7e9f2;
}
.webix_pager_item:active {
  background-color: #daddeb;
}
.webix_pager_item:hover {
  background-color: #e7e9f2;
}
.webix_pager_item:focus {
  background-color: #e7e9f2;
}
.webix_pager_item:active {
  background-color: #daddeb;
}
/*selected  button of pager*/
.webix_pager_item_selected {
  cursor: default;
}
.webix_pager_item_selected:hover {
  background-color: #1992af;
}
.webix_pager_item_selected:focus {
  background-color: #1992af;
}
.webix_pager_item_selected:active {
  background-color: #17839d;
}
.webix_animation .webix_ss_header {
  position: relative;
  z-index: 1;
}
.webix_animation .webix_ss_vscroll_header {
  z-index: 1;
}
.webix_animation .webix_ss_footer,
.webix_animation .webix_vscroll_x {
  position: absolute;
  bottom: 0px;
  z-index: 1;
}
/*helper for allowing selection*/
.webix_allow_selection {
  -moz-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
/*default item in dataview*/
.webix_dataview_item {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 2px 12px;
  line-height: 31px;
  cursor: pointer;
  border-bottom: 1px solid #EDEFF0;
  text-align: left;
  border-color: #EDEFF0;
  border-right: 1px solid #EDEFF0;
}
.webix_dataview_item.webix_selected {
  box-shadow: inset 2px 0 #1CA1C1;
}
.webix_dataview_item.webix_disabled {
  color: #94A1B3;
}
.webix_dataview_item.webix_selected.webix_disabled {
  box-shadow: inset 2px 0 #94A1B3;
}
.webix_dataview_item .webix_icon {
  font-size: 20px;
}
/*default item in selected state*/
.webix_dataview_item.webix_selected {
  color: #475466;
  background: #F4F5F9;
  border-bottom: 1px solid #EDEFF0;
  border-right: 1px solid #EDEFF0;
}
.webix_dataview_item.webix_selected:focus {
  background: #edeff5;
}
.webix_dataview_item.webix_invalid {
  background-color: #ffeeee;
}
.webix_dataview_item.tiles {
  border: 1px solid #EDEFF0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_drag_zone > .webix_dataview_item.tiles {
  margin: 0 !important;
}
.webix_dataview .webix_scroll_cont {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
/*chart container*/
.webix_chart {
  position: relative;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  color: #475466;
  overflow: hidden;
  z-index: 1;
}
.webix_chart canvas {
  position: absolute;
}
/*labels*/
.webix_canvas_text {
  position: absolute;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  z-index: 80;
}
.webix_chart .webix_canvas_text {
  border-radius: 4px;
}
.webix_chart .webix_canvas_text.webix_inner_text_dark {
  color: #475466;
  text-shadow: 0 0 4px #ffffff;
  padding: 0 2px;
}
.webix_chart .webix_canvas_text.webix_inner_text_light {
  color: #ffffff;
  text-shadow: 0 0 4px #475466;
  padding: 0 2px;
}
/*map*/
.webix_map_img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  border: 0px;
  opacity: 0;
  -webkit-user-select: none;
}
/*scales*/
.webix_axis_item_y {
  position: absolute;
  height: 18px;
  line-height: 18px;
  margin-top: -4px;
  text-align: right;
}
.webix_axis_item_x {
  padding-top: 2px;
}
.webix_axis_title_x {
  text-align: center;
  font-size: 13px;
}
.webix_axis_title_y {
  text-align: center;
  font-family: Verdana;
  font-size: 13px;
  transform: rotate(-90deg);
  padding-left: 3px;
}
/*legend block*/
.webix_chart_legend {
  position: absolute;
  z-index: 81;
}
.webix_chart_legend_item {
  height: 18px;
  line-height: 18px;
  padding: 2px;
  cursor: pointer;
}
.webix_chart_legend_item.hidden {
  color: #cfd5dd;
}
.webix_axis_item_y.webix_radar {
  color: #555;
  font-size: 11px;
  padding-right: 3px;
  height: 13px;
  line-height: 13px;
  margin-top: 0px;
}
.webix_canvas_text.webix_axis_radar_title {
  margin-top: 0px;
  padding-top: 0px;
  color: #74869f;
  font-size: 12px;
}
.webix_axis_item_y,
.webix_axis_item_x {
  color: #74869f;
}
/*data frame*/
.webix_chart_frame {
  background-color: #a0cfef;
  height: 100%;
  opacity: 0.3;
  position: absolute;
  top: 0;
  z-index: 82;
  border-left: 1px solid #3498db;
  border-right: 1px solid #3498db;
  cursor: w-resize;
}
.webix_chart_resizer {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAICAYAAAAx8TU7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTJCMjdFNENBRTg4MTFFMjk2NjJGMTJENjkyNDA2NTkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTJCMjdFNERBRTg4MTFFMjk2NjJGMTJENjkyNDA2NTkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5MkIyN0U0QUFFODgxMUUyOTY2MkYxMkQ2OTI0MDY1OSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5MkIyN0U0QkFFODgxMUUyOTY2MkYxMkQ2OTI0MDY1OSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Powz/icAAAAaSURBVHjaYmBgYDBjgAA4zcSABQysIECAAQBn+ACx2PqtbAAAAABJRU5ErkJggg==");
  background-position: 3px center;
  background-repeat: no-repeat;
  border-radius: 2px;
  height: 20px;
  bottom: 0;
  width: 11px;
  z-index: 83;
  position: absolute;
  background-color: #fff;
  border: 1px solid #cccccc;
  cursor: w-resize;
}
.webix_chart_resizer:focus {
  border-color: #1CA1C1;
}
.webix_wresize_cursor {
  cursor: w-resize;
}
/* donut center text */
.webix_donut_center_text {
  display: inline-block;
  line-height: normal;
  white-space: normal;
  vertical-align: middle;
}
.webix_dt_editor {
  position: absolute;
  z-index: 2;
  overflow: visible;
  background: #FFFFFF;
}
.webix_dt_editor input,
.webix_dt_editor select {
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 11px;
  margin: 0;
  border: 1px solid #ffd700;
  height: 100%;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #475466;
  letter-spacing: 0.2px;
}
.webix_dt_editor input[type="checkbox"] {
  margin: 11px 13px 0px;
  height: auto;
  width: auto;
}
.webix_dt_editor select {
  padding: 0 7px;
}
.webix_clipbuffer {
  width: 2000px;
  height: 1px;
  padding: 0;
  margin: 0;
  border: 0;
  left: 0px;
  top: 0px;
  white-space: pre-wrap;
  position: fixed;
  opacity: 0;
  pointer-events: none;
  cursor: default;
}
.webix_dt_editor.webix_password_editor {
  overflow: hidden;
}
.webix_password_editor input {
  padding-right: 26px;
}
.webix_password_editor .webix_icon {
  position: absolute;
  right: 4px;
  top: 50%;
  margin-top: -9px;
  font-size: 18px;
  cursor: pointer;
  color: #94A1B3;
}
.webix_password_editor .webix_icon:hover {
  color: #1CA1C1;
}
.webix_column,
.webix_hcolumn {
  position: absolute;
  top: 0px;
}
.webix_cell {
  overflow: hidden;
  line-height: 33px;
  white-space: nowrap;
}
.webix_hcell {
  width: 100%;
  overflow: hidden;
}
.webix_cell,
.webix_hcell {
  background: inherit;
  font: inherit;
  text-align: inherit;
  color: inherit;
}
.webix_ss_body {
  overflow: hidden;
  position: relative;
}
.webix_hs_center,
.webix_hs_right,
.webix_hs_left,
.webix_ss_center,
.webix_ss_right,
.webix_ss_left {
  overflow: hidden;
  float: left;
  position: relative;
}
.webix_ss_center_scroll {
  height: 100%;
  position: relative;
  overflow: hidden;
  min-height: 1px;
}
.webix_ss_hscroll {
  bottom: 0px;
}
.webix_ss_vscroll {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 2;
}
.webix_ss_vscroll_footer {
  position: absolute;
  bottom: 0px;
  right: 0px;
  border-left: 1px solid #EDEFF0;
  border-top: 1px solid #EDEFF0;
  z-index: 1;
}
.webix_ss_vscroll_header {
  position: absolute;
  top: 0px;
  right: 0px;
  border-left: 1px solid #DADEE0;
  border-bottom: 1px solid #DADEE0;
  z-index: 1;
}
.webix_dtable {
  position: relative;
  cursor: default;
  z-index: 0;
}
.webix_ss_footer,
.webix_ss_header {
  clear: both;
  overflow: hidden;
}
.webix_table_cell,
.webix_view.webix_table_cell,
.webix_block_selection,
.webix_hcell,
.webix_cell {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_table_cell {
  font-size: 14px;
}
.webix_block_selection {
  position: absolute;
}
.webix_ss_sort_asc,
.webix_ss_sort_desc {
  position: absolute;
  top: 50%;
  right: 2px;
  font-family: "Webix Material Icons";
  font-size: 20px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  margin-top: -12px;
  float: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.webix_ss_sort_asc.webix_ss_sort_single,
.webix_ss_sort_desc.webix_ss_sort_single {
  width: 20px;
  text-align: center;
}
.webix_ss_sort_asc:before {
  content: '\F004';
}
.webix_ss_sort_desc:before {
  content: '\F001';
}
.webix_ss_sort_num {
  position: absolute;
  top: 0;
  right: 0;
  height: 12px;
  line-height: 12px;
  font-family: Roboto, sans-serif;
  font-size: 10px;
  text-align: right;
  color: #1CA1C1;
}
.webix_ss_sort_single .webix_ss_sort_num {
  display: none;
}
.webix_hcell .webix_excel_filter {
  position: absolute;
  top: 50%;
  right: 2px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  margin-top: -10px;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #94A1B3;
}
.webix_hcell .webix_excel_filter:hover {
  color: #1CA1C1;
}
.webix_excel_filter ~ .webix_ss_sort_asc,
.webix_excel_filter ~ .webix_ss_sort_desc {
  right: 24px;
}
.webix_hcell.webix_ss_excel_filter {
  position: relative;
}
.webix_hcell.webix_ss_filter_active .webix_excel_filter {
  color: #1CA1C1;
}
.webix_hcell.webix_ss_filter_active .webix_excel_filter:hover {
  color: #17839d;
}
.webix_hcell.webix_ss_filter {
  padding: 3px 5px 3px 5px;
}
.webix_hcell .webix_tree_close,
.webix_hcell .webix_tree_open {
  margin-right: 5px;
}
.webix_table_checkbox,
.webix_hcell input[type="checkbox"] {
  margin: 0px 1px 2px;
  vertical-align: middle;
}
.webix_ss_filter select,
.webix_ss_filter input {
  width: 100%;
  font-size: inherit;
  color: #475466 !important;
  height: 38px;
  margin-top: -2px;
  padding: 0 3px;
  vertical-align: top;
  border: 1px solid #DADEE0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
}
.webix_richfilter {
  margin-top: 1px;
  line-height: 32px;
}
.webix_ss_filter select {
  padding: 3px;
}
/*headers and footers*/
.webix_ss_header,
.webix_hcolumn,
.webix_ss_vscroll_header,
.webix_dd_drag_column,
.webix_hs_left,
.webix_hs_center,
.webix_hs_right {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #313131;
  letter-spacing: 0.2px;
  background: #F4F5F9;
}
.webix_ss_footer,
.webix_ss_footer .webix_hcolumn,
.webix_ss_footer .webix_hs_left,
.webix_ss_footer .webix_hs_center,
.webix_ss_footer .webix_hs_right,
.webix_ss_footer div.webix_ss_vscroll_footer {
  background: #fafafa;
}
.webix_hcell.webix_measure_size {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #313131;
  letter-spacing: 0.2px;
}
/*content in header and footer*/
.webix_hcell {
  padding: 0px 12px 0px 12px;
  text-align: left;
}
/*borders in header and footer*/
.webix_ss_header .webix_hcolumn .webix_hcell,
.webix_ss_header .webix_span {
  border-right: 1px solid #DADEE0;
  border-bottom: 1px solid #DADEE0;
}
.webix_ss_footer .webix_hcolumn .webix_hcell,
.webix_ss_footer .webix_span {
  border-right: 1px solid #EDEFF0;
  border-top: 1px solid #EDEFF0;
}
/*remove rightmost borders*/
.webix_hs_right .webix_hcell.webix_last,
.webix_hs_center .webix_hcell.webix_last {
  border-right-width: 0px;
}
/*right inner border*/
.webix_column.webix_last > div {
  border-right-width: 0px;
}
/*borders between split areas*/
.webix_ss_right .webix_column.webix_first > div,
.webix_hs_right .webix_first {
  border-left: 1px solid #DADEE0;
}
.webix_ss_left .webix_column.webix_last > div {
  border-right: 1px solid #DADEE0;
}
.webix_last_topcell.webix_cell {
  border-bottom: 1px solid #DADEE0;
}
/*normal data cell of the table*/
.webix_column > div,
.webix_table_cell {
  border-right: 1px solid #EDEFF0;
  padding: 0 12px;
  border-bottom: 1px solid #EDEFF0;
}
.webix_column,
.webix_hcolumn {
  text-align: left;
}
/*header selection in column mode, column dnd*/
.webix_ss_header .webix_sel_hcell,
.webix_ss_footer .webix_sel_hcell,
.webix_ss_header .webix_dd_over_column {
  background-color: #c9eaf5;
}
.webix_block_selection {
  z-index: 2;
  background: rgba(0, 0, 0, 0.1);
  border: 2px solid #EDEFF0;
}
.webix_dd_drag {
  white-space: nowrap;
  overflow: hidden;
  border-left: 1px solid #EDEFF0;
  height: 36px;
}
.webix_dd_drag > div {
  display: inline-block;
  vertical-align: top;
  background: #FFFFFF;
  border-top: 1px solid #EDEFF0;
  border-right: 1px solid #EDEFF0;
  border-bottom: 1px solid #EDEFF0;
  padding: 5px 12px;
  height: 36px;
  line-height: 28px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_dd_drag_column {
  padding: 5px 10px 5px 10px;
}
.webix_column > .webix_drag_over.webix_cell {
  background-color: #e6e6e6;
  color: #475466;
}
.webix_column > .webix_drag_over.webix_cell.webix_row_select,
.webix_column > .webix_drag_over.webix_cell.webix_cell_select {
  color: #475466;
  background: #F4F5F9;
}
.webix_column > .webix_drag_over.webix_cell.webix_row_select:focus,
.webix_column > .webix_drag_over.webix_cell.webix_cell_select:focus {
  background: #edeff5;
}
.webix_cell.webix_invalid {
  background-color: #ffeeee;
}
.webix_invalid_cell {
  position: relative;
}
.webix_invalid_cell:after {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  width: 0px;
  height: 1px;
  border-top: 8px solid #f44;
  border-left: 8px solid transparent;
}
/*selection*/
.webix_column > div.webix_cell_select,
.webix_column > div.webix_column_select,
.webix_column > div.webix_row_select {
  color: #475466;
  background: #F4F5F9;
}
.webix_column > div.webix_cell_select:focus,
.webix_column > div.webix_column_select:focus,
.webix_column > div.webix_row_select:focus {
  background: #edeff5;
}
/*extra borders, for selection in cell mode*/
.webix_column > div.webix_cell_select {
  border-bottom: 1px solid #EDEFF0;
  border-left: 1px solid #EDEFF0;
  border-right: 1px solid #EDEFF0;
  padding-top: 0px;
  padding-left: 11px;
}
.webix_ss_right .webix_column.webix_first > div.webix_cell_select {
  padding-left: 12px;
}
/*extra borders, for selection in row mode*/
.webix_column > div.webix_row_select {
  border-bottom: 1px solid #EDEFF0;
  padding-top: 0px;
}
/*extra borders for selection in column mode*/
.webix_column > div.webix_column_select {
  border-left: 1px solid #EDEFF0;
  border-right: 1px solid #EDEFF0;
  padding-left: 11px;
}
.webix_cell.webix_dtable_colrow,
.webix_cell.webix_dtable_subrow,
.webix_dtable_subview {
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: #FFFFFF;
  width: 100%;
  padding-right: 30px;
  padding-left: 12px;
  padding-top: 1px;
  border-bottom: 1px solid #EDEFF0;
}
.webix_cell.webix_dtable_colrow.webix_selected {
  color: #475466;
  background: #F4F5F9;
}
.webix_cell.webix_dtable_colrow.webix_selected:focus {
  background: #edeff5;
}
.webix_dtable_subrow {
  border-top: 1px solid #EDEFF0;
  text-align: right;
}
.webix_dtable_subview {
  background: #EDEFF0;
  border-top: 1px solid #EDEFF0;
  padding: 0px 0px 0px 0px;
  padding-left: 10px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.number .webix_cell {
  text-align: right;
}
.webix_rotate {
  transform: rotate(-90deg);
  line-height: normal;
}
.webix_measure_rotate {
  line-height: normal;
  white-space: normal;
  padding: 10px;
}
.webix_span_layer,
.webix_span_layer_top {
  background: transparent;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 0px;
  width: 0px;
  overflow: visible;
}
.webix_span_layer_top {
  z-index: 1;
}
.webix_ss_right .webix_span_layer,
.webix_ss_right .webix_span_layer_top {
  border-left: 1px solid #DADEE0;
}
.webix_dtable_span {
  position: absolute;
  background: #FFFFFF;
  text-align: left;
}
.webix_dtable_span.webix_selected {
  color: #475466;
  background: #F4F5F9;
}
.webix_dtable_span.webix_selected:focus {
  background: #edeff5;
}
.webix_hcell .webix_input_icon {
  background-color: #DADEE0;
}
.webix_area_selection_layer {
  position: absolute;
  z-index: 2;
}
.webix_area_selection {
  position: absolute;
  background: #475466;
}
.webix_dtable_focused .webix_area_selection {
  background-color: #3f4a5a;
}
.webix_area_selection_top,
.webix_area_selection_bottom {
  height: 2px;
}
.webix_area_selection_left,
.webix_area_selection_right {
  width: 2px;
}
.webix_area_selection_handle {
  position: absolute;
  width: 6px;
  height: 6px;
  border: 1px solid #fff;
  background: #475466;
  cursor: crosshair;
  margin: -6px 0 0 -6px;
}
.webix_topcell {
  position: relative;
  background: #FFFFFF;
  z-index: 1;
}
.webix_dtable .webix_resize_handle_x .webix_handle_content,
.webix_dtable .webix_resize_handle_y .webix_handle_content {
  border: none;
  background-color: #1CA1C1;
}
.webix_dtable_focused .webix_column > div.webix_row_select,
.webix_dtable_focused .webix_column > div.webix_column_select {
  background: #edeff5;
}
.webix_window {
  position: absolute;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 7px 0 rgba(0, 0, 0, 0.1);
}
.webix_window:focus {
  box-shadow: 0 1px 14px 0 rgba(0, 0, 0, 0.12), 0 4px 10px 0 rgba(0, 0, 0, 0.1);
}
.webix_win_body {
  overflow: hidden;
}
.webix_window,
.webix_win_content,
.webix_win_body,
.webix_win_body > .webix_view {
  border-radius: 0px;
}
.webix_win_body > .webix_view .webix_cal_month {
  border-radius: 0px;
}
.webix_win_head {
  border-bottom: 1px solid #DADEE0;
}
.webix_win_title {
  text-align: center;
}
/*----------------------------*/
/* DO NOT CHANGE STYLES BELOW */
/*----------------------------*/
.webix_win_content {
  height: auto;
  width: auto;
  overflow: hidden;
}
.webix_modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.2;
}
.webix_modalbox_inside {
  pointer-events: none;
}
.webix_point_top,
.webix_point_right,
.webix_point_bottom,
.webix_point_left {
  position: absolute;
}
.webix_point_top,
.webix_point_bottom {
  width: 13px;
  height: 7px;
}
.webix_point_left,
.webix_point_right {
  width: 7px;
  height: 13px;
}
.webix_point_top,
.webix_point_bottom,
.webix_point_right,
.webix_point_left {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAArCAIAAADHSy4DAAAABnRSTlMAAAAAAABupgeRAAAACXBIWXMAAABPAAAATwFjiv3XAAAAhElEQVR4nMXVQQoAIQhAUe9/FC/pLAYayUm/EeT6YWGmoqpWharKG7n+XK5nt9L/LurMeV27oZHrReN0M6Oupr5S1GU0vhN1J7L27hp1QYXX1WtE5UxnNXI0ep7+pEGRpr95ooWObbXUm1npXWkFaF3vTqj9c+h8pVOb7gK6Yeje4tvwARi4FgcAh2V/AAAAAElFTkSuQmCC");
}
.webix_point_top {
  background-position: 0 -36px;
}
.webix_point_bottom {
  background-position: 0 0;
}
.webix_point_right {
  background-position: 0 -22px;
}
.webix_point_left {
  background-position: 0 -9px;
}
.webix_resize_handle {
  position: absolute;
  z-index: 2;
  width: 15px;
  height: 15px;
  bottom: 0px;
  line-height: 15px;
  right: -1px;
  text-align: center;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAMAAAAMCGV4AAAABlBMVEUAAAAAAAClZ7nPAAAAAXRSTlMAQObYZgAAABZJREFUeAFjIAUwUshlpJDLSIhLGAAACQ4AFk79JaMAAAAASUVORK5CYII=);
  cursor: nw-resize;
}
.webix_resize_frame {
  position: absolute;
  opacity: 0.1;
  background: #1CA1C1;
  display: block;
  border: 1px solid #0f5768;
  box-shadow: 1px 3px 6px #000;
}
.webix_resize_cursor {
  cursor: nw-resize;
}
.webix_shadow_medium,
.webix_shadow_small,
.webix_shadow_big {
  position: relative;
}
.webix_shadow_medium {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 7px 0 rgba(0, 0, 0, 0.1);
}
.webix_shadow_small {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
}
.webix_shadow_big {
  box-shadow: 0 1px 14px 0 rgba(0, 0, 0, 0.12), 0 4px 10px 0 rgba(0, 0, 0, 0.1);
}
.webix_shadow_none {
  box-shadow: none !important;
}
.webix_toolbar.webix_shadow_small,
.webix_toolbar.webix_shadow_medium,
.webix_toolbar.webix_shadow_big {
  z-index: 1;
}
/*basis*/
.webix_tree .webix_scroll_cont > .webix_tree_leaves {
  display: inline-block;
  padding: 8px;
}
.webix_tree_item {
  clear: both;
  height: 24px;
  line-height: 24px;
  white-space: nowrap;
}
.webix_tree_item.webix_selected {
  background: transparent;
}
.webix_tree_item.webix_selected span {
  padding: 4px 10px 4px 4px;
  margin-left: -4px;
  color: #475466;
  background: #F4F5F9;
}
.webix_tree_item.webix_selected span:focus {
  background: #edeff5;
}
.webix_tree_item.webix_invalid {
  background-color: #ffeeee;
}
.webix_tree_checkbox {
  margin: 0px;
  padding: 0px;
  vertical-align: top;
  height: 100%;
  width: 13px;
}
.webix_indeterminate {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAZUlEQVR4nGPw8Mhldw9ObXAPTp1JBG4AqWcAawhNs2QgArgFp1q5haTVgzTNZCABuIekzSJdU3DqTOpoqq+vZ3IPSfsPwyD+INJECNBREzSeGkAxTYwG16B0a3CKICXtgTSA0h4ApZxpTQmN4jEAAAAASUVORK5CYII=");
  background-repeat: no-repeat;
  background-position: left center;
  height: 100%;
  width: 13px;
  margin-top: 0 !important;
  display: inline-block;
}
.webix_measure_size {
  font-family: Roboto, sans-serif;
}
.webix_measure_size input,
.webix_measure_size textarea,
.webix_measure_size select {
  width: 5px;
}
.webix_measure_size div {
  float: none;
}
.webix_measure_size div,
.webix_measure_size a {
  display: inline-block !important;
  position: static !important;
}
.webix_tree_close,
.webix_tree_open,
.webix_tree_none,
.webix_tree_folder,
.webix_tree_file,
.webix_tree_folder_open {
  display: inline-block;
  vertical-align: top;
  width: 20px;
  height: 100%;
  cursor: pointer;
  margin: 0;
  background-repeat: no-repeat;
}
.webix_tree_close,
.webix_tree_open,
.webix_tree_none {
  background-position: center center;
}
.webix_cell .webix_tree_close,
.webix_cell .webix_tree_open,
.webix_cell .webix_tree_none {
  background-color: transparent;
}
.webix_tree_folder,
.webix_tree_file,
.webix_tree_folder_open {
  background-position: 0 center ;
}
.webix_tree_file,
.webix_tree_folder,
.webix_tree_folder_open {
  margin: 0px 2px;
}
.webix_tree_leaves .webix_tree_leaves {
  display: block;
  margin-left: 20px;
}
.webix_line_tree .webix_tree_leaves {
  margin-left: 0px;
}
.webix_tree_file {
  background-image: url("data:image/gif;base64,R0lGODlhEgASAIABAI6Pj////yH5BAEKAAEALAAAAAASABIAAAInjI+JwM26XAtPyTnTfU5veGyXJ2ZhaRpoSq0AicKlLNJftNojyCMFADs=");
}
.webix_tree_folder {
  background-image: url("data:image/gif;base64,R0lGODlhEgASAIABAI6Pj////yH5BAEKAAEALAAAAAASABIAAAIhjI+py90AI3Syvoqj27mfnn2gZYxkYEpiCqypa27yTC8FADs=");
}
.webix_tree_folder_open {
  background-image: url("data:image/gif;base64,R0lGODlhEgASAIABAI6Pj////yH5BAEKAAEALAAAAAASABIAAAIjjI+py90AI3SyvorjkQ7pDAJBSJaTaGpjKq7pxsJmR9c2UwAAOw==");
}
.webix_tree_img {
  background-color: #FFFFFF;
  float: left;
  width: 24px;
  height: 24px;
  background-position: 0 0;
}
.webix_tree_line1 {
  background-image: url("data:image/gif;base64,R0lGODlhGQAcAIABAMHBwf///yH5BAEKAAEALAAAAAAZABwAAAIxjI8Gyc3r4pP0wCovdnpPz4Gh+JFIZ6KkKrKg68GbjNGVTeGZmeiRP+IphBbi0EgrAAA7");
}
.webix_tree_line2 {
  background-image: url("data:image/gif;base64,R0lGODlhGQAcAIABAMHBwf///yH5BAEKAAEALAAAAAAZABwAAAIpjI8Gyc3r4pP0wCovdnpPz4Gh+JFIZ6KkKgLuC7/mTNf2jef6zvc+XgAAOw==");
  background-repeat: no-repeat;
}
.webix_tree_line3 {
  background-image: url("data:image/gif;base64,R0lGODlhGQAcAIABAMHBwf///yH5BAEKAAEALAAAAAAZABwAAAI1jI8Gyc3r4pP0wCovdnpPz4Gh+JFIZ6KkKgLuC78VC9KeveGYPpsJTwFmfCeixahABoQRXQEAOw==");
}
.webix_tree_line4 {
  background-image: url("data:image/gif;base64,R0lGODlhGQAcAIABAMHBwf///yH5BAEKAAEALAAAAAAZABwAAAIjjI+py+0Po5y02ouz3rxDAIZi+HGAiW6nmmqr22avHGMzUwAAOw==");
}
.webix_cal_month {
  height: 24px;
  line-height: 24px;
  text-align: center;
  position: relative;
  font-size: 14px;
  font-weight: bold;
  padding: 0 0 6px;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
.webix_cal_prev_button,
.webix_cal_next_button {
  cursor: pointer;
  position: absolute;
  top: 0;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: 8px 6px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.webix_cal_prev_button {
  left: 0;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAALCAYAAACzkJeoAAAASUlEQVR42p2QMQoAQQgD79EZ0Mqn5xCu2cPdYgUbJ0rMMxXgLeiuKq8gsJAz8weQ0QiwJEfECnr4wdlEnwJ68+yyhReC+c85oRecj0Um+pmo9wAAAABJRU5ErkJggg==");
  background-repeat: no-repeat;
}
.webix_cal_next_button {
  right: 0;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAALCAYAAACzkJeoAAAARElEQVR42qXPsQoAIAhF0T76PdDJT7cUmtQhcmk4N8QVQ9JXN2bmgWOgqp+BiCRG2AYAMoi3/LxYdyagAnhA6G93TrABZaJFJjrFY8IAAAAASUVORK5CYII=");
  background-repeat: no-repeat;
}
.webix_view > .webix_cal_header {
  height: 24px;
}
.webix_view > .webix_cal_header div {
  float: left;
  text-align: center;
  height: 19px;
  font-size: 11px;
  color: #475466;
  border-bottom: #DADEE0 1px solid;
}
.webix_cal_body .webix_cal_row > div {
  float: left;
  text-align: center;
  height: 100%;
}
.webix_cal_body .webix_selected,
.webix_cal_body .webix_cal_select,
.webix_cal_body .webix_cal_select.webix_cal_today {
  color: #475466;
  background: #F4F5F9;
}
.webix_cal_day,
.webix_cal_week_num {
  cursor: pointer;
  font-size: 12px;
  color: #475466;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.webix_cal_week_num {
  color: #a2adbe;
  cursor: default;
  margin-right: 0px;
  border-right: 1px solid #DADEE0;
}
.webix_cal_event {
  font-weight: bold;
  color: #C66200;
}
.webix_cal_outside {
  color: #b1bbc8;
}
.webix_cal_inactive {
  background: #888;
}
.webix_cal_inactive {
  background: #888;
}
.webix_cal_day_disabled,
.webix_cal_day_disabled.webix_cal_event {
  color: #b1bbc8;
  background: #f0f0f0;
}
.webix_cal_day_disabled.webix_cal_day,
.webix_cal_day_disabled.webix_cal_block {
  cursor: not-allowed;
}
.webix_hours .webix_cal_day_disabled,
.webix_minutes .webix_cal_day_disabled,
.webix_hours .webix_cal_day_disabled.webix_cal_event,
.webix_minutes .webix_cal_day_disabled.webix_cal_event {
  background: #FFFFFF;
  font-weight: normal;
  text-decoration: line-through;
}
.webix_cal_month_name {
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.webix_cal_block {
  float: left;
  text-align: center;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.webix_selected {
  background: #F4F5F9;
  color: #475466;
}
.webix_cal_footer {
  text-align: center;
  font-size: 12px;
  color: #444;
  height: 24px;
  padding: 6px 0 0;
  cursor: pointer;
}
.webix_cal_icons {
  float: right;
  height: 20px;
  line-height: 20px;
  padding: 4px 4px 0;
}
.webix_cal_time {
  height: 20px;
  line-height: 20px;
  padding: 4px 4px 0;
}
.webix_cal_time_icons {
  float: left;
}
.webix_cal_icons .webix_cal_icon {
  text-align: center;
  padding: 0 4px;
}
.webix_cal_icon:hover,
.webix_cal_time:hover,
.webix_cal_month_name:hover {
  text-decoration: underline;
  color: #1CA1C1;
}
.webix_cal_icon:focus,
.webix_cal_time:focus,
.webix_cal_month_name:focus {
  color: #1CA1C1;
}
.webix_cal_month_name.webix_readonly {
  cursor: default;
}
.webix_cal_month_name.webix_readonly:hover,
.webix_cal_month_name.webix_readonly:focus {
  text-decoration: none;
  color: inherit;
}
.webix_time_header {
  padding: 0 0 6px;
}
.webix_time_header .webix_cal_hours,
.webix_time_header .webix_cal_minutes {
  display: inline-block;
  font-size: 14px;
  text-align: center;
  height: 24px;
  line-height: 24px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-bottom: 1px solid #DADEE0;
}
.webix_time_header .webix_cal_minutes {
  padding-left: 1px;
  border-left: none;
}
.webix_time_header .webix_cal_hours {
  border-right: none !important;
}
.webix_cal_body .webix_hours {
  float: left;
  border-right: 1px solid #DADEE0;
  padding-right: 1px;
}
.webix_cal_body .webix_minutes {
  float: left;
  padding-left: 1px;
}
.webix_cal_block_empty {
  float: left;
  text-align: center;
}
.webix_time_footer {
  text-align: center;
  font-size: 12px;
  height: 24px;
  padding: 6px 0 0;
  cursor: pointer;
}
.webix_cal_done {
  height: 24px;
  padding: 2px 8px;
  font-size: 12px;
  border-radius: 2px;
  border: 1px solid #DADEE0;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  outline: none;
  background: #F4F5F9;
  color: #1CA1C1;
  float: right;
}
.webix_cal_done:hover {
  background-color: #e7e9f2;
}
.webix_cal_done:focus {
  background-color: #e7e9f2;
}
.webix_cal_done:active {
  background-color: #daddeb;
}
.webix_cal_blurred_hours {
  background-color: #f7f7f7;
}
.webix_property_line {
  width: 100%;
  height: auto;
  line-height: 0;
}
.webix_property_line.webix_focused > div,
.webix_property_line:focus > div {
  background-color: #f7f8fb;
}
.webix_property_label,
.webix_property_value {
  display: inline-block;
  padding-left: 12px;
  border-bottom: 1px solid #EDEFF0;
  overflow: hidden;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_property_label {
  border-right: 1px solid #EDEFF0;
}
.webix_property_label_line {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #475466;
  letter-spacing: 0px;
  line-height: 42px;
  padding-left: 12px;
  border-bottom: 1px solid #EDEFF0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_property_label_line .webix_el_label .webix_el_box {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #475466;
  letter-spacing: 0px;
}
.webix_property_label_line.webix_dark {
  background: #657584;
  border-color: #657584;
}
.webix_property_label_line.webix_dark .webix_el_button,
.webix_property_label_line.webix_dark .webix_el_label .webix_el_box,
.webix_property_label_line.webix_dark .webix_inp_label,
.webix_property_label_line.webix_dark .webix_inp_top_label,
.webix_property_label_line.webix_dark .webix_slider_title {
  color: #FFFFFF;
}
.webix_property_label_line.webix_dark .webix_secondary .webix_icon,
.webix_property_label_line.webix_dark .webix_transparent .webix_icon,
.webix_property_label_line.webix_dark .webix_secondary .webix_img_btn_text,
.webix_property_label_line.webix_dark .webix_transparent .webix_img_btn_text,
.webix_property_label_line.webix_dark .webix_secondary .webix_icon_btn,
.webix_property_label_line.webix_dark .webix_transparent .webix_icon_btn {
  color: #FFFFFF;
}
.webix_property_label_line.webix_dark .webix_transparent button {
  color: #FFFFFF;
}
.webix_property_label_line.webix_dark .webix_transparent button:hover,
.webix_property_label_line.webix_dark .webix_transparent button:focus {
  background-color: rgba(0, 0, 0, 0.2);
}
.webix_property_label_line.webix_dark .webix_transparent button:active {
  background-color: rgba(0, 0, 0, 0.4);
}
.webix_property_label_line.webix_dark .webix_secondary button,
.webix_property_label_line.webix_dark .webix_inp_counter_next,
.webix_property_label_line.webix_dark .webix_inp_counter_prev,
.webix_property_label_line.webix_dark .webix_segment_0,
.webix_property_label_line.webix_dark .webix_segment_1,
.webix_property_label_line.webix_dark .webix_segment_N,
.webix_property_label_line.webix_dark .webix_menu-x .webix_list_item {
  background-color: rgba(0, 0, 0, 0.2);
  color: #FFFFFF;
  border-color: transparent;
}
.webix_property_label_line.webix_dark .webix_view > .webix_template {
  color: #FFFFFF;
  background-color: #657584;
}
.webix_property_label_line.webix_dark .webix_secondary button:hover,
.webix_property_label_line.webix_dark .webix_menu-x .webix_list_item:hover,
.webix_property_label_line.webix_dark .webix_inp_counter_next:hover,
.webix_property_label_line.webix_dark .webix_inp_counter_prev:hover,
.webix_property_label_line.webix_dark .webix_segment_0:hover,
.webix_property_label_line.webix_dark .webix_segment_1:hover,
.webix_property_label_line.webix_dark .webix_segment_N:hover {
  color: #FFFFFF;
  background-color: rgba(0, 0, 0, 0.4);
}
.webix_property_label_line.webix_dark .webix_secondary button:focus,
.webix_property_label_line.webix_dark .webix_menu-x .webix_list_item:focus,
.webix_property_label_line.webix_dark .webix_inp_counter_next:focus,
.webix_property_label_line.webix_dark .webix_inp_counter_prev:focus,
.webix_property_label_line.webix_dark .webix_segment_0:focus,
.webix_property_label_line.webix_dark .webix_segment_1:focus,
.webix_property_label_line.webix_dark .webix_segment_N:focus {
  background-color: rgba(0, 0, 0, 0.4);
  color: #FFFFFF;
}
.webix_property_label_line.webix_dark .webix_secondary button:active,
.webix_property_label_line.webix_dark .webix_menu-x .webix_list_item:active,
.webix_property_label_line.webix_dark .webix_inp_counter_next:active,
.webix_property_label_line.webix_dark .webix_inp_counter_prev:active,
.webix_property_label_line.webix_dark .webix_segment_0:active,
.webix_property_label_line.webix_dark .webix_segment_1:active,
.webix_property_label_line.webix_dark .webix_segment_N:active {
  background-color: rgba(0, 0, 0, 0.5);
  color: #FFFFFF;
}
.webix_property_label_line.webix_dark .webix_inp_static,
.webix_property_label_line.webix_dark input[type=text],
.webix_property_label_line.webix_dark input[type=combo],
.webix_property_label_line.webix_dark input[type=datepicker],
.webix_property_label_line.webix_dark textarea,
.webix_property_label_line.webix_dark select,
.webix_property_label_line.webix_dark .webix_text_highlight {
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.7);
}
.webix_property_label_line.webix_dark .webix_el_texthighlight input,
.webix_property_label_line.webix_dark .webix_el_texthighlight textarea {
  caret-color: rgba(255, 255, 255, 0.7);
}
.webix_property_label_line.webix_dark .webix_inp_static input[type=text] {
  background-color: transparent;
}
.webix_property_label_line.webix_dark select option {
  background-color: #657584;
  color: #FFFFFF;
}
.webix_property_label_line.webix_dark .webix_multicombo_value,
.webix_property_label_line.webix_dark .webix_multicombo_tag {
  color: #657584;
}
.webix_property_label_line.webix_dark .webix_inp_static:focus,
.webix_property_label_line.webix_dark input[type=text]:focus,
.webix_property_label_line.webix_dark input[type=combo]:focus,
.webix_property_label_line.webix_dark input[type=datepicker]:focus,
.webix_property_label_line.webix_dark textarea:focus,
.webix_property_label_line.webix_dark select:focus {
  border-color: #FFFFFF;
  box-shadow: none;
}
.webix_property_label_line.webix_dark .webix_el_counter button {
  background-color: rgba(0, 0, 0, 0.3);
}
.webix_property_label_line.webix_dark .webix_el_counter input[type=text]:focus {
  border-color: transparent;
}
.webix_property_label_line.webix_dark .webix_placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.webix_property_label_line.webix_dark ::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.webix_property_label_line.webix_dark ::-moz-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.webix_property_label_line.webix_dark :-moz-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.webix_property_label_line.webix_dark :-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.webix_property_label_line.webix_dark .webix_input_icon {
  color: rgba(255, 255, 255, 0.7);
}
.webix_property_label_line.webix_dark .webix_selected.webix_segment_0,
.webix_property_label_line.webix_dark .webix_selected.webix_segment_1,
.webix_property_label_line.webix_dark .webix_selected.webix_segment_N {
  background: #1CA1C1;
  color: #FFFFFF;
}
.webix_property_label_line.webix_dark .webix_selected.webix_segment_0:hover,
.webix_property_label_line.webix_dark .webix_selected.webix_segment_1:hover,
.webix_property_label_line.webix_dark .webix_selected.webix_segment_N:hover {
  background-color: #1992af;
}
.webix_property_label_line.webix_dark .webix_selected.webix_segment_0:focus,
.webix_property_label_line.webix_dark .webix_selected.webix_segment_1:focus,
.webix_property_label_line.webix_dark .webix_selected.webix_segment_N:focus {
  background-color: #1992af;
}
.webix_property_label_line.webix_dark .webix_selected.webix_segment_0:active,
.webix_property_label_line.webix_dark .webix_selected.webix_segment_1:active,
.webix_property_label_line.webix_dark .webix_selected.webix_segment_N:active {
  background-color: #17839d;
}
.webix_property_label_line.webix_dark .webix_el_icon,
.webix_property_label_line.webix_dark .webix_icon_button {
  color: #FFFFFF;
}
.webix_property_label_line.webix_dark .webix_control {
  border-color: #1CA1C1;
}
.webix_property_label_line.webix_dark .webix_icon_button:hover:before {
  background-color: rgba(0, 0, 0, 0.2);
}
.webix_property_label_line.webix_dark .webix_icon_button:hover .webix_icon,
.webix_property_label_line.webix_dark .webix_icon_button:focus .webix_icon {
  opacity: 1;
}
.webix_property_label_line.webix_dark .webix_icon_button .webix_icon {
  color: #FFFFFF;
  opacity: 0.7;
}
.webix_property_label_line.webix_dark .webix_custom_checkbox,
.webix_property_label_line.webix_dark .webix_custom_radio {
  color: #FFFFFF;
}
.webix_property_label_line.webix_dark .webix_custom_checkbox:focus,
.webix_property_label_line.webix_dark .webix_custom_radio:focus {
  color: #f0f0f0;
}
.webix_property_label_line.webix_dark .webix_label_right {
  color: #FFFFFF;
}
.webix_property_label_line.webix_dark .webix_view.webix_control .webix_disabled_box .webix_button {
  color: rgba(255, 255, 255, 0.4);
  background-color: rgba(0, 0, 0, 0.1);
  text-shadow: none;
}
.webix_property_label_line.webix_dark .webix_view.webix_control .webix_disabled_box .webix_img_btn_text {
  color: rgba(255, 255, 255, 0.4);
}
.webix_property_label_line.webix_dark .webix_disabled_view.webix_control .webix_icon_btn,
.webix_property_label_line.webix_dark .webix_disabled_view.webix_control .webix_icon {
  color: rgba(255, 255, 255, 0.4);
}
.webix_property_label_line.webix_dark .webix_disabled_view.webix_menu-x .webix_list_item.menu {
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(255, 255, 255, 0.4);
}
.webix_property_label_line.webix_dark .webix_toolbar .webix_disabled_view.webix_control .webix_icon,
.webix_property_label_line.webix_dark .webix_toolbar .webix_disabled_view.webix_control .webix_disabled_box .webix_img_btn_text,
.webix_property_label_line.webix_dark .webix_toolbar .webix_disabled_view.webix_control .webix_icon_btn {
  color: rgba(255, 255, 255, 0.4);
}
.webix_property_label_line.webix_dark .webix_view.webix_control .webix_disabled_box label,
.webix_property_label_line.webix_dark .webix_view.webix_control .webix_disabled_box .webix_input_icon,
.webix_property_label_line.webix_dark .webix_view.webix_control .webix_disabled_box button {
  color: rgba(255, 255, 255, 0.4);
}
.webix_property_label_line.webix_dark .webix_view.webix_control .webix_disabled_box input,
.webix_property_label_line.webix_dark .webix_view.webix_control .webix_disabled_box select,
.webix_property_label_line.webix_dark .webix_view.webix_control .webix_disabled_box textarea,
.webix_property_label_line.webix_dark .webix_view.webix_control .webix_disabled_box .webix_inp_static,
.webix_property_label_line.webix_dark .webix_view.webix_control .webix_disabled_box .webix_inp_counter_prev,
.webix_property_label_line.webix_dark .webix_view.webix_control .webix_disabled_box .webix_inp_counter_next {
  color: rgba(255, 255, 255, 0.4);
  background-color: rgba(0, 0, 0, 0.1);
  border-color: transparent;
}
.webix_property_label_line.webix_dark .webix_view.webix_control .webix_disabled_box .webix_inp_static input {
  background-color: transparent;
}
.webix_property_label_line.webix_dark .webix_view.webix_control .webix_disabled_box .webix_segment_0,
.webix_property_label_line.webix_dark .webix_view.webix_control .webix_disabled_box .webix_segment_1,
.webix_property_label_line.webix_dark .webix_view.webix_control .webix_disabled_box .webix_segment_N,
.webix_property_label_line.webix_dark .webix_view.webix_control .webix_disabled_box .webix_item_tab {
  color: rgba(255, 255, 255, 0.4);
  background-color: rgba(0, 0, 0, 0.1);
  text-shadow: none;
}
.webix_property_label_line.webix_dark .webix_view.webix_control .webix_disabled_box .webix_segment_0.webix_selected,
.webix_property_label_line.webix_dark .webix_view.webix_control .webix_disabled_box .webix_segment_1.webix_selected,
.webix_property_label_line.webix_dark .webix_view.webix_control .webix_disabled_box .webix_segment_N.webix_selected {
  background-color: rgba(0, 0, 0, 0.15);
}
.webix_property_label_line.webix_dark .webix_disabled_top_label {
  color: rgba(255, 255, 255, 0.4);
}
.webix_property_label_line.webix_dark .webix_invalid .webix_el_box input,
.webix_property_label_line.webix_dark .webix_invalid .webix_el_box .webix_inp_static,
.webix_property_label_line.webix_dark .webix_invalid .webix_el_box select,
.webix_property_label_line.webix_dark .webix_invalid .webix_el_box textarea,
.webix_property_label_line.webix_dark input.webix_invalid,
.webix_property_label_line.webix_dark select.webix_invalid,
.webix_property_label_line.webix_dark textarea.webix_invalid {
  color: #ffdedb;
  background-color: rgba(255, 92, 76, 0.3);
  border-color: #ff8d82;
}
.webix_property_label_line.webix_dark .webix_invalid .webix_multicombo_value,
.webix_property_label_line.webix_dark .webix_invalid .webix_multicombo_tag {
  color: #FF5C4C;
}
.webix_property_label_line.webix_dark .webix_invalid .webix_el_box .webix_inp_static input {
  background-color: transparent;
}
.webix_property_label_line.webix_dark {
  color: #FFFFFF;
}
.webix_property_col_ind {
  float: right;
  cursor: pointer;
  margin: 0 8px 0 0;
  border: 1px solid #DADEE0;
  background: #ffffff;
  border-radius: 3px;
  width: 16px;
  height: 16px;
}
.webix_property_value {
  text-overflow: ellipsis;
  white-space: nowrap;
}
.webix_property_check {
  margin: 0;
  vertical-align: middle;
}
/*style used by tooltip's container*/
.webix_tooltip {
  display: none;
  position: absolute;
  z-index: 10000;
  background-color: #FFFFFF;
  padding: 5px 10px;
  border: 1px solid #DADEE0;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #475466;
  letter-spacing: 0.2px;
  border-radius: 0px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.webix_global_tooltip {
  pointer-events: none;
}
/*comments list*/
.webix_comments .webix_list .webix_list_item {
  margin-top: 24px;
  border: none;
  cursor: default;
}
.webix_comments .webix_list .webix_comments_more_item {
  margin-top: 12px;
}
/* comments list templates */
.webix_comments_name {
  margin-left: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
}
.webix_comments_menu.webix_icon {
  line-height: 30px;
  width: 6px;
  visibility: hidden;
  float: right;
  cursor: pointer;
  color: #94A1B3;
  margin: 0 4px 0 0;
}
.webix_comments_current:hover .webix_comments_menu,
.webix_comments_current.active_menu .webix_comments_menu {
  visibility: visible;
}
.webix_comments_message {
  white-space: pre-wrap;
  line-height: 20px;
  margin-left: 70px;
  margin-right: 10px;
}
.webix_comments_message a {
  color: #1CA1C1;
  text-decoration: none;
  word-break: break-all;
}
.webix_comments_message a:hover {
  text-decoration: underline;
}
.webix_comments_message a:visited {
  color: #7e57c2;
}
.webix_comments_message .webix_comments_mention {
  font-weight: 500;
  white-space: nowrap;
}
.webix_comments_mention {
  color: #1CA1C1;
}
.webix_comments_avatar {
  margin-left: 14px;
  float: left;
  position: relative;
  width: 40px;
  height: 40px;
}
.webix_comments_status {
  z-index: 1;
  top: 30px;
  left: 30px;
  border-radius: 50%;
  border: 2px solid #FFFFFF;
  position: absolute;
  height: 12px;
  width: 12px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_comments_status.active {
  background-color: #55CD97;
}
.webix_comments_status.busy {
  background-color: #FF5C4C;
}
.webix_comments_status.away {
  background-color: #FDBF4C;
}
.webix_comments_status.none {
  background-color: #d0d5dd;
}
.webix_comments_avatar_image {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
  float: left;
  position: relative;
}
.webix_comments_photo {
  width: auto;
  height: 100%;
  margin: 0 auto;
}
.webix_comments_avatar_text {
  font-size: 16px;
  color: #1CA1C1;
  line-height: 40px;
  text-align: center;
  background-color: #DFE2E6;
}
.webix_comments_avatar_text .webix_icon {
  margin: 0;
}
.webix_comments_date {
  float: right;
  color: #94A1B3;
  font-size: 12px;
}
.webix_comments_image {
  max-width: 100%;
  max-height: 300px;
  display: block;
}
/* buttons*/
.webix_comments_more {
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  background: #F4F5F9;
  color: #1CA1C1;
  border: none;
  border-radius: 2px;
}
.webix_comments_more:hover {
  background-color: #e7e9f2;
}
.webix_comments_send .webix_el_box {
  float: right !important;
}
.webix_message_area {
  position: fixed;
  right: 5px;
  width: 250px;
  z-index: 1000;
}
.webix_message {
  min-width: 120px;
  font-family: Roboto, sans-serif;
  z-index: 10000;
  overflow: hidden;
  margin: 4px 4px 12px;
  transition: all 0.5s ease;
}
.webix_message.hidden {
  height: 0px;
  min-height: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-width: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  box-shadow: none;
  overflow: hidden;
}
.webix_modal_box {
  pointer-events: auto;
  overflow: hidden;
  display: inline-block;
  min-width: 250px;
  width: 250px;
  text-align: center;
  position: fixed;
  background-color: #FFFFFF;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 7px 0 rgba(0, 0, 0, 0.1);
  font-family: Roboto, sans-serif;
  z-index: 20000;
  border-radius: 0px;
  border: 1px solid #DADEE0;
  outline: none;
}
.webix_modal_box .webix_popup_button {
  margin-top: 8px;
}
.webix_message,
.webix_popup_button {
  cursor: pointer;
}
.webix_popup_controls {
  padding: 4px 12px 12px;
}
.webix_popup_button {
  height: 30px;
  line-height: 30px;
  display: inline-block;
  margin: 0 4px;
  border-radius: 2px;
}
.webix_popup_button {
  min-width: 100px;
}
div.webix_modal_cover {
  background-color: #000;
  cursor: default;
  opacity: 0.1;
  position: fixed;
  z-index: 19999;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  border: none;
  zoom: 1;
}
.webix_message img,
.webix_modal_box img {
  float: left;
  margin-right: 8px;
}
/*Skin section*/
.webix_popup_button {
  font-size: 14px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  background: #F4F5F9;
  color: #1CA1C1;
  outline: none;
}
.webix_popup_button:hover {
  background-color: #e7e9f2;
}
.webix_popup_button:focus {
  background-color: #e7e9f2;
}
.webix_popup_button:active {
  background-color: #daddeb;
}
.webix_popup_button.confirm {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #1CA1C1;
  letter-spacing: 0px;
  outline: none;
  background: #1CA1C1;
  color: #FFFFFF;
}
.webix_popup_button.confirm:hover {
  background-color: #1992af;
}
.webix_popup_button.confirm:focus {
  background-color: #1992af;
}
.webix_popup_button.confirm:active {
  background-color: #17839d;
}
.webix_popup_input.webix_el_text {
  outline: none;
  overflow: hidden;
  padding: 3px 2px;
}
.webix_popup_input.webix_el_text input {
  width: 100%;
  height: 30px;
  font-size: 14px;
  background: #ffffff;
  font-family: Roboto, sans-serif;
  border-color: #CCD7E6;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_popup_input.webix_el_text.webix_required::before {
  position: absolute;
  right: 18px;
  padding-top: 1px;
  content: "*";
  color: #FF5C4C;
}
.webix_popup_input.webix_el_text.webix_popup_invalid input {
  background-color: #ffedee;
  border-color: #fea5a8;
  color: #FF5C4C;
  background-color: #ffdedb;
}
.webix_popup_title {
  color: #475466;
  line-height: 20px;
  padding: 20px 16px 12px 16px;
  font-size: 16px;
  font-weight: 500;
}
.webix_popup_title + div.webix_popup_text {
  padding-top: 0;
  box-shadow: none;
}
.webix_popup_text {
  overflow: hidden;
  padding: 24px 16px 0px 16px;
  font-size: 14px;
  color: #475466;
  line-height: 20px;
}
.webix_popup_text.webix_popup_label {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.webix_alert .webix_popup_title,
.webix_confirm .webix_popup_title,
.webix_prompt .webix_popup_title,
.webix_alert .webix_popup_text,
.webix_confirm .webix_popup_text,
.webix_prompt .webix_popup_text {
  box-shadow: inset 0 4px #1CA1C1;
}
.webix_alert-warning .webix_popup_title,
.webix_confirm-warning .webix_popup_title,
.webix_prompt-warning .webix_popup_title,
.webix_alert-warning .webix_popup_text,
.webix_confirm-warning .webix_popup_text,
.webix_prompt-warning .webix_popup_text {
  box-shadow: inset 0 4px #FDBF4C;
}
.webix_alert-error .webix_popup_title,
.webix_confirm-error .webix_popup_title,
.webix_prompt-error .webix_popup_title,
.webix_alert-error .webix_popup_text,
.webix_confirm-error .webix_popup_text,
.webix_prompt-error .webix_popup_text {
  box-shadow: inset 0 4px #FF5C4C;
}
.webix_message {
  font-size: 14px;
  color: #475466;
  box-shadow: 0 1px 14px 0 rgba(0, 0, 0, 0.12), 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  line-height: 20px;
  border-radius: 2px;
  border: 1px solid #DADEE0;
  background-color: #FFFFFF;
}
.webix_message.webix_debug {
  color: #313131;
  border-color: #fed282;
  background-color: #fff2db;
}
.webix_message.webix_error {
  color: #313131;
  border-color: #ff8d82;
  background-color: #ffdedb;
}
.webix_message.webix_success {
  color: #313131;
  border-color: #88dcb6;
  background-color: #ddf5ea;
}
.webix_message div {
  padding: 11px 12px;
}
.webix_color_row {
  clear: both;
  white-space: nowrap;
}
.webix_color_palette {
  text-align: left;
  padding: 4px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.webix_color_palette.webix_palette_classic {
  padding: 0;
}
.webix_color_row,
.webix_color_row div {
  line-height: 0;
  font-size: 0;
}
.webix_color_row div {
  display: inline-block;
  position: relative;
}
.webix_color_row div > * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_color_row .webix_color_selected > * {
  border: 1px solid;
  border-color: #FFFFFF;
  border-radius: 2px;
  box-shadow: rgba(28, 161, 193, 0.6) 0 0 0 2px;
}
.webix_color_row .webix_color_selected:focus > * {
  box-shadow: #1CA1C1 0 0 0 2px;
}
.webix_palette_classic .webix_color_row .webix_color_selected > * {
  border: 2px solid #f3f3f3;
  border-radius: 0px;
  box-shadow: none;
}
.webix_palette_classic .webix_color_row .webix_color_selected:focus > * {
  box-shadow: inset 0 0 15px #fff;
}
.webix_palette_classic .webix_color_item {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.webix_palette_material .webix_color_item {
  display: inline-block;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 2px;
  cursor: pointer;
  width: 100%;
  height: 100%;
}
.webix_palette_material .webix_color_row > div {
  margin: 0 4px 4px 0;
}
.webix_color_area {
  width: 100%;
  height: 100%;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.webix_color_block {
  position: relative;
  border-radius: 2px;
  margin: 12px 12px 14px;
}
.webix_color_block:before,
.webix_color_block:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.webix_color_block:before {
  background-image: linear-gradient(0deg, #000, hsla(0, 0%, 100%, 0));
  z-index: 2;
}
.webix_color_block:after {
  background-image: linear-gradient(90deg, #fff, hsla(20, 42%, 65%, 0));
  z-index: 1;
}
.webix_color_line {
  position: relative;
  height: 8px;
  margin: 0 12px;
  border-radius: 8px;
  background-image: linear-gradient(90deg, red, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, red);
}
.webix_color_circle {
  width: 8px;
  height: 8px;
  margin: -6px 0 0 -6px;
  border: 2px solid #FFFFFF;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
}
.webix_color_circle:focus,
.webix_color_area_active .webix_color_circle {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2), inset 0 0 4px #FFFFFF;
}
.webix_color_line_circle {
  width: 12px;
  height: 12px;
  margin: -4px 0 0 -8px;
  border: 2px solid #FFFFFF;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  background: #12FF08;
}
.webix_color_line_circle:focus,
.webix_color_area_active .webix_color_line_circle {
  box-shadow: inset 0 0 8px #FFFFFF;
}
.webix_color_area_active .webix_color_circle,
.webix_color_area_active .webix_color_line_circle {
  pointer-events: none;
}
.webix_color_out_block,
.webix_color_out_text {
  height: 32px;
  float: left;
  border: 1px solid #CCD7E6;
  margin: 12px 0px 12px 12px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_color_out_text {
  color: #475466;
  font-size: 14px;
  background: #ffffff;
  font-family: Roboto, sans-serif;
  border-color: #CCD7E6;
  padding: 0px 12px;
}
.webix_color_out_text:focus {
  border: 1px solid #1CA1C1;
}
.webix_color_area .webix_button {
  width: calc(100% - 12px*2);
  height: 32px;
  margin: 0px 0px 12px 12px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_daterange .webix_view {
  background-color: #FFFFFF;
}
.webix_daterange .webix_range_0 .webix_cal_next_button,
.webix_daterange .webix_range_N .webix_cal_prev_button {
  visibility: hidden;
}
.webix_daterange .webix_range_1 .webix_cal_next_button,
.webix_daterange .webix_range_1 .webix_cal_prev_button {
  visibility: hidden;
}
.webix_daterange .webix_range_1 .webix_cal_next_button:hover,
.webix_daterange .webix_range_1 .webix_cal_prev_button:hover {
  cursor: default;
}
.webix_daterange .webix_range_left .webix_cal_next_button:hover,
.webix_daterange .webix_range_right .webix_cal_prev_button:hover {
  cursor: default;
}
.webix_cal_range {
  background-color: #ffffff;
}
.webix_cal_range_start {
  border-radius: 3px 0 0 3px;
}
.webix_cal_range_end {
  border-radius: 0 3px 3px 0;
}
.webix_cal_range_start,
.webix_cal_range_end,
.webix_cal_event.webix_cal_range_start,
.webix_cal_event.webix_cal_range_end,
.webix_cal_body .webix_cal_today.webix_cal_range_start,
.webix_cal_body .webix_cal_today.webix_cal_range_end {
  background-color: #F4F5F9;
  color: #475466;
}
.webix_cal_outside.webix_cal_range,
.webix_cal_body .webix_cal_outside.webix_cal_range_start,
.webix_cal_body .webix_cal_outside.webix_cal_range_end,
.webix_cal_body .webix_cal_outside.webix_cal_today.webix_cal_range_start,
.webix_cal_body .webix_cal_outside.webix_cal_today.webix_cal_range_end {
  background-color: transparent;
  color: #b1bbc8;
}
.webix_daterange .webix_cal_icons {
  text-align: right;
  padding: 0 4px;
}
.webix_daterange .webix_cal_icons .webix_cal_icon {
  border-top: 1px solid #DADEE0;
}
.webix_daterange .webix_cal_time {
  display: none;
}
.webix_daterange .webix_cal_timepicker .webix_range_time_start {
  display: block;
}
.webix_daterange .webix_range_timepicker .webix_cal_time {
  display: block;
  padding: 5px 15px;
}
.webix_daterange .webix_range_timepicker .webix_range_time_start {
  float: left;
}
.webix_daterange .webix_range_timepicker .webix_range_time_end {
  float: right;
}
.webix_daterange .webix_range_footer .webix_cal_footer {
  padding: 0;
}
.webix_timeboard .webix_slider_title {
  text-align: left;
  margin-left: 10px;
}
.webix_timeboard .webix_colon_template .webix_template {
  padding: 0;
  text-align: center;
  line-height: 100%;
  font-size: 30px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.webix_timeboard .webix_day_part .webix_el_box {
  padding-left: 6px;
  color: #1CA1C1;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.webix_timeboard .webix_day_part .webix_el_box:hover,
.webix_timeboard .webix_day_part .webix_el_box:focus {
  color: #17839d;
}
.webix_drag_zone {
  position: absolute;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #475466;
  letter-spacing: 0.2px;
  pointer-events: none;
}
.webix_drag_zone .webix_tree_item {
  padding: 0 8px;
}
.webix_drag_zone .webix_tree_item .webix_tree_open,
.webix_drag_zone .webix_tree_item .webix_tree_close,
.webix_drag_zone .webix_tree_item .webix_tree_none {
  display: none;
}
.webix_drag_zone .webix_ss_hscroll,
.webix_drag_zone .webix_ss_vscroll {
  display: none !important;
}
.webix_drag_over,
.webix_drop_zone {
  background-color: #e6e6e6;
}
.webix_drag_main {
  position: relative;
  background-color: #FFFFFF;
}
.webix_drag_main .webix_tree_item,
.webix_drag_main .webix_list_item,
.webix_drag_main .webix_dataview_item {
  border: 1px solid #EDEFF0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_drag_multiple,
.webix_drag_multiple_last {
  height: 100%;
  width: 100%;
  position: absolute;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #EDEFF0;
  background-color: #FFFFFF;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
}
.webix_drag_multiple {
  bottom: 8px;
  left: 8px;
}
.webix_drag_multiple_last {
  bottom: 16px;
  left: 16px;
}
.webix_drag_zone > .webix_badge {
  top: -14px;
  right: -14px;
}
.webix_invisible {
  display: none !important;
}
.webix_drop_area,
.webix_drop_area_inner {
  height: 36px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #f1f2f7;
}
.webix_list-x .webix_drop_area {
  display: inline-block;
  border-right: 1px solid #EDEFF0;
}
.webix_tree .webix_drop_area {
  height: 24px;
}
.webix_list .webix_drop_area {
  border-bottom: 1px solid #EDEFF0;
}
.webix_dataview .webix_drop_area {
  float: left;
  background-color: transparent;
}
.webix_dataview .webix_drop_area_inner {
  border-right: 1px solid #EDEFF0;
  border-bottom: 1px solid #EDEFF0;
}
.webix_vscroll_x {
  height: 0px;
  width: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
}
.webix_vscroll_y {
  width: 0px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}
.webix_vscroll_body {
  width: 16px;
  height: 16px;
}
.webix_scroll_x {
  width: 50px;
  height: 4px !important;
  left: 1px;
  bottom: 3px;
  background-color: silver;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  border-radius: 4px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_scroll_y {
  width: 4px !important;
  height: 50px;
  top: 1px;
  right: 3px;
  background-color: silver;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  border-radius: 4px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_custom_scroll {
  -ms-overflow-style: none;
  /* IE, Edge */
  scrollbar-width: none;
  /* Firefox */
}
.webix_custom_scroll::-webkit-scrollbar {
  display: none;
  /* Chrome, Edge(chromium), Opera, Safari, all browsers on iOS */
}
.webix_c_scroll_y {
  position: absolute;
  z-index: 3;
  width: 10px;
  right: 0px;
}
.webix_c_scroll_y > div {
  transition: width 0.3s 0.2s, margin 0.3s 0.2s;
  width: 4px;
  height: 100%;
  background-color: silver;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  margin: 0px 3px;
}
.webix_c_scroll_y:hover > div,
.webix_c_scroll_y.webix_scroll_active div {
  width: 10px;
  margin: 0px;
}
.webix_c_scroll_x {
  position: absolute;
  z-index: 3;
  height: 10px;
  bottom: 0px;
}
.webix_c_scroll_x > div {
  transition: height 0.3s 0.2s, margin 0.3s 0.2s;
  height: 4px;
  width: 100%;
  background-color: silver;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  margin: 3px 0px;
}
.webix_c_scroll_x:hover > div,
.webix_c_scroll_x.webix_scroll_active div {
  height: 10px;
  margin: 0px;
}
.webix_c_scroll_bar_y,
.webix_c_scroll_bar_x {
  position: absolute;
  background: rgba(0, 0, 0, 0.05);
}
.webix_c_scroll_bar_y {
  height: 100%;
  width: 10px;
  right: 0px;
}
.webix_c_scroll_bar_x {
  width: 100%;
  height: 10px;
  bottom: 0px;
}
.webix_scroll_cont {
  width: 100%;
  height: auto;
  clear: both;
  position: relative;
}
.webix_secondary .webix_button {
  background: #F4F5F9;
  color: #1CA1C1;
}
.webix_secondary .webix_button:hover {
  background-color: #e7e9f2;
}
.webix_secondary .webix_button:focus {
  background-color: #e7e9f2;
}
.webix_secondary .webix_button:active {
  background-color: #daddeb;
}
.webix_secondary .webix_button .webix_icon,
.webix_secondary .webix_button .webix_img_btn_text,
.webix_secondary .webix_button .webix_icon_btn {
  color: #1CA1C1;
}
.webix_secondary .webix_pressed .webix_button {
  background-color: #daddeb;
}
.webix_primary .webix_button {
  background: #1CA1C1;
  color: #FFFFFF;
}
.webix_primary .webix_button:hover {
  background-color: #1992af;
}
.webix_primary .webix_button:focus {
  background-color: #1992af;
}
.webix_primary .webix_button:active {
  background-color: #17839d;
}
.webix_primary .webix_button .webix_icon,
.webix_primary .webix_button .webix_img_btn_text,
.webix_primary .webix_button .webix_icon_btn {
  color: #FFFFFF;
}
.webix_primary .webix_pressed .webix_button {
  background-color: #17839d;
}
.webix_danger .webix_button {
  background: #FF5C4C;
  color: #ffffff;
}
.webix_danger .webix_button:hover {
  background-color: #ff4938;
}
.webix_danger .webix_button:focus {
  background-color: #ff4938;
}
.webix_danger .webix_button:active {
  background-color: #ff3723;
}
.webix_danger .webix_button .webix_icon,
.webix_danger .webix_button .webix_img_btn_text,
.webix_danger .webix_button .webix_icon_btn {
  color: #ffffff;
}
.webix_danger .webix_pressed .webix_button {
  background-color: #ff3723;
}
.webix_transparent .webix_button {
  color: #475466;
  background: transparent;
  border: none;
}
.webix_transparent .webix_button .webix_img_btn_text {
  color: #475466;
}
.webix_transparent .webix_button .webix_icon,
.webix_transparent .webix_button .webix_icon_btn {
  color: #94A1B3;
}
.webix_transparent .webix_button:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.webix_transparent .webix_button:focus {
  background-color: rgba(0, 0, 0, 0.04);
}
.webix_transparent .webix_button:active {
  background-color: rgba(0, 0, 0, 0.05);
}
.webix_transparent .webix_pressed .webix_button {
  background-color: rgba(0, 0, 0, 0.05);
}
.webix_button,
.webixbutton {
  border-radius: 2px;
  height: 100%;
  width: 100%;
  cursor: pointer;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #1CA1C1;
  letter-spacing: 0px;
  outline: none;
  -webkit-appearance: none;
  padding: 0 6px;
}
.webix_el_button .webix_el_box,
.webix_el_toggle .webix_el_box {
  position: relative;
}
.webix_img_btn,
.webix_img_btn_top {
  vertical-align: top;
  border: none;
  width: 100%;
  padding: 0;
}
.webix_img_btn .webix_icon {
  color: #1CA1C1;
  margin-right: 4px;
}
.webix_img_btn_top .webix_icon,
.webix_img_btn_top .webix_image {
  font-size: 20px;
  position: absolute;
  top: 8px;
  display: inline-block;
  left: 50%;
  transform: translateX(-50%);
}
.webix_img_btn .webix_image {
  display: inline-block;
  margin-right: 4px;
  vertical-align: middle;
}
.webix_img_btn_text {
  position: absolute;
  left: 0;
  bottom: 4px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  color: #1CA1C1;
}
.webix_pressed .webix_button {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.webix_view.webix_control .webix_disabled_box .webix_button {
  color: #94A1B3;
  background: #f4f5f9;
  border-color: #f4f5f9;
  text-shadow: none;
}
.webix_view.webix_control .webix_disabled_box .webix_img_btn_text {
  color: #94A1B3;
}
.webix_view.webix_control .webix_disabled_box .webix_image {
  opacity: 0.4;
}
.webix_disabled_view.webix_control .webix_icon_btn,
.webix_disabled_view.webix_control .webix_icon {
  color: #94A1B3;
}
.webix_toolbar .webix_disabled_view.webix_control .webix_icon,
.webix_toolbar .webix_disabled_view.webix_control .webix_disabled_box .webix_img_btn_text,
.webix_toolbar .webix_disabled_view.webix_control .webix_icon_btn {
  color: #94A1B3;
}
.webixbutton {
  width: auto;
  height: auto;
  padding: 8px 15px;
}
.webix_richtext_editor {
  height: 100%;
}
.webix_template .webix_richtext_editor:focus {
  outline: none;
}
.webix_richtext_container .webix_template {
  padding: 10px;
  overflow-x: auto;
  border: solid #DADEE0;
  border-width: 0px 1px 1px;
  border-radius: 0px 0px 6px 6px;
}
.webix_richtext_inp_label .webix_template {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #475466;
  text-transform: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  float: left;
  padding-top: 1px;
  padding-left: 0;
  padding-right: 9px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #313131;
  letter-spacing: 0.2px;
  padding-right: 8px;
  margin-bottom: 0px;
  padding-top: 10px;
  float: none;
}
.webix_richtext .wxi-underline,
.webix_richtext .wxi-italic,
.webix_richtext .wxi-bold {
  vertical-align: sub;
  font-size: 24px;
  width: 24px;
}
.webix_control {
  background: transparent;
}
.webix_control input,
.webix_control button,
.webix_control textarea,
.webix_el_label,
.webix_inp_label,
.webix_inp_top_label,
.webix_inp_bottom_label {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
}
.webix_el_box {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 3px 2px;
  height: 100%;
  width: 100%;
  font-size: 14px;
  overflow: hidden;
}
.webixtoolbarlabel {
  font-size: 16px;
}
.webix_el_text input,
.webix_el_search input,
.webix_el_combo input,
.webix_el_datepicker input,
.webix_el_colorpicker input {
  font-size: 14px;
  height: 100%;
  padding: 0px 12px;
  border: 1px solid #DADEE0;
  color: #475466;
  background: #ffffff;
  font-family: Roboto, sans-serif;
  border-color: #CCD7E6;
  -webkit-appearance: none;
  border-radius: 0;
}
.webix_multitext_icon:hover,
.webix_multitext_icon:active {
  color: #1CA1C1;
  background: inherit;
  cursor: pointer;
}
.webix_inp_label,
.webix_inp_top_label,
.webix_label_right {
  color: #475466;
  text-transform: none;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.webix_inp_label {
  float: left;
  padding-top: 1px;
  padding-left: 0;
  padding-right: 9px;
}
.webix_inp_top_label {
  float: none;
  padding: 3px 2px;
  padding-bottom: 0px;
}
.webix_inp_bottom_label {
  font-size: 12px;
  color: #516075;
}
.webix_label_right {
  padding-left: 9px;
}
.webix_el_checkbox .webix_label_right {
  display: inline-block;
  padding-top: 1px;
}
.webix_el_label {
  color: #475466;
}
.webix_toolbar .webix_el_label {
  text-transform: none;
}
.webix_el_select select {
  height: 100%;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  border: 1px solid #DADEE0;
}
body:not(:-moz-handler-blocked) .webix_el_select select {
  padding: 0.2em;
}
.webix_el_group,
.webix_all_segments {
  height: 100%;
  white-space: nowrap;
}
.webix_inp_counter_next,
.webix_inp_counter_prev,
.webix_inp_counter_value {
  border: 1px solid #DADEE0;
  cursor: pointer;
  -webkit-appearance: none;
  width: 20px;
  height: 100%;
  vertical-align: top;
}
.webix_inp_counter_value {
  font-size: 14px;
  width: 50px;
  color: #475466;
  text-align: center;
  border-left-width: 0px;
  border-right-width: 0px;
  -webkit-appearance: none;
  border-radius: 0;
}
.webix_inp_counter_prev {
  padding: 0;
  background: #F4F5F9;
  color: #1CA1C1;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.webix_inp_counter_prev:hover {
  background-color: #e7e9f2;
}
.webix_inp_counter_prev:focus {
  background-color: #e7e9f2;
}
.webix_inp_counter_prev:active {
  background-color: #daddeb;
}
.webix_inp_counter_next {
  padding: 0;
  background: #F4F5F9;
  color: #1CA1C1;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.webix_inp_counter_next:hover {
  background-color: #e7e9f2;
}
.webix_inp_counter_next:focus {
  background-color: #e7e9f2;
}
.webix_inp_counter_next:active {
  background-color: #daddeb;
}
.webix_el_textarea textarea,
.webix_textarea_measure {
  border: 1px solid #DADEE0;
  height: 100%;
  margin: 0;
  padding: 5px;
  color: #475466;
  resize: none;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  padding-left: 12px;
  padding-right: 12px;
}
.webix_textarea_measure {
  box-sizing: border-box;
  overflow: hidden;
}
.webix_el_textarea .webix_inp_label {
  padding-top: 10px;
}
.webix_noscroll.webix_el_textarea textarea {
  overflow: hidden;
}
.webix_segment_0,
.webix_segment_1 {
  border: 1px solid #DADEE0;
  border-right-width: 0px;
  height: 100%;
}
.webix_segment_0 {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.webix_segment_N {
  border: 1px solid #DADEE0;
  height: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.webix_segment_N:first-child {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.webix_segment_0,
.webix_segment_1,
.webix_segment_N {
  -webkit-appearance: none;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  outline: none;
  background: #F4F5F9;
  color: #1CA1C1;
  cursor: pointer;
  padding: 0;
}
.webix_segment_0:hover,
.webix_segment_1:hover,
.webix_segment_N:hover {
  background-color: #e7e9f2;
}
.webix_segment_0:focus,
.webix_segment_1:focus,
.webix_segment_N:focus {
  background-color: #e7e9f2;
}
.webix_segment_0:active,
.webix_segment_1:active,
.webix_segment_N:active {
  background-color: #daddeb;
}
.webix_segment_0.webix_selected,
.webix_segment_1.webix_selected,
.webix_segment_N.webix_selected {
  color: #475466;
  background: #F4F5F9;
  border-color: #1CA1C1;
  background-color: #1CA1C1;
  color: #FFFFFF;
}
.webix_segment_0.webix_selected:focus,
.webix_segment_1.webix_selected:focus,
.webix_segment_N.webix_selected:focus {
  background: #edeff5;
}
.webix_segment_0.webix_disabled,
.webix_segment_1.webix_disabled,
.webix_segment_N.webix_disabled {
  color: #94A1B3;
  background-color: #fbfbfd;
  border-color: transparent;
  cursor: not-allowed;
}
.webix_segment_0.webix_disabled:hover,
.webix_segment_1.webix_disabled:hover,
.webix_segment_N.webix_disabled:hover,
.webix_segment_0.webix_disabled:focus,
.webix_segment_1.webix_disabled:focus,
.webix_segment_N.webix_disabled:focus,
.webix_segment_0.webix_disabled:active,
.webix_segment_1.webix_disabled:active,
.webix_segment_N.webix_disabled:active {
  box-shadow: none;
  background-color: #fbfbfd;
}
.webix_segment_0.webix_disabled.webix_selected,
.webix_segment_1.webix_disabled.webix_selected,
.webix_segment_N.webix_disabled.webix_selected,
.webix_segment_0.webix_disabled.webix_selected:hover,
.webix_segment_1.webix_disabled.webix_selected:hover,
.webix_segment_N.webix_disabled.webix_selected:hover,
.webix_segment_0.webix_disabled.webix_selected:focus,
.webix_segment_1.webix_disabled.webix_selected:focus,
.webix_segment_N.webix_disabled.webix_selected:focus {
  background-color: #dde0ec;
}
.webix_inp_static {
  border: 1px solid #DADEE0;
  overflow: hidden;
  padding: 0px 12px;
  height: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
}
.webix_placeholder {
  color: #94A1B3;
}
::-webkit-input-placeholder {
  color: #94A1B3;
}
::-moz-placeholder {
  color: #94A1B3;
}
/* Firefox 19+ */
:-moz-placeholder {
  color: #94A1B3;
}
/* Firefox 18- */
:-ms-input-placeholder {
  color: #94A1B3;
}
.webix_el_combo,
.webix_el_richselect,
.webix_el_datepicker,
.webix_el_colorpicker,
.webix_el_search {
  font-size: 14px;
}
.webix_el_combo input,
.webix_el_richselect input,
.webix_el_datepicker input,
.webix_el_colorpicker input,
.webix_el_search input,
.webix_el_combo .webix_inp_static,
.webix_el_richselect .webix_inp_static,
.webix_el_datepicker .webix_inp_static,
.webix_el_colorpicker .webix_inp_static,
.webix_el_search .webix_inp_static {
  color: #475466;
  background: #ffffff;
  font-family: Roboto, sans-serif;
  border-color: #CCD7E6;
  -webkit-appearance: none;
}
.webix_el_combo .webix_el_box,
.webix_el_richselect .webix_el_box,
.webix_el_datepicker .webix_el_box,
.webix_el_colorpicker .webix_el_box,
.webix_el_search .webix_el_box,
.webix_el_text .webix_el_box,
.webix_el_texthighlight .webix_el_box {
  color: #475466;
  position: relative;
}
.webix_el_combo .webix_input_icon,
.webix_el_richselect .webix_input_icon,
.webix_el_datepicker .webix_input_icon,
.webix_el_colorpicker .webix_input_icon,
.webix_el_search .webix_input_icon,
.webix_el_text .webix_input_icon,
.webix_el_texthighlight .webix_input_icon {
  background-color: transparent;
  position: absolute;
  z-index: 1;
  padding-left: 4px;
  width: 24px;
}
.webix_clear_icon:hover {
  color: #FF5C4C;
  cursor: pointer;
}
.webix_clear_icon:hover::before {
  content: "\F011";
  font-family: "Webix Material Icons";
}
.webix_view input::-ms-clear {
  display: none;
}
/*Remove button padding in FF*/
.webix_view button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.webix_el_radio .webix_inp_label {
  line-height: normal !important;
  padding-top: 7px;
}
.webix_inp_radio_border input,
.webix_el_checkbox input {
  margin: 0px;
  padding: 0px;
  float: left;
}
.webix_line_break {
  clear: both;
}
.webix_custom_checkbox,
.webix_custom_radio {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.webix_radio_option.webix_disabled .webix_label_right,
.webix_radio_option.webix_disabled .webix_inp_radio_border {
  color: #94A1B3;
  cursor: not-allowed;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.webix_el_radio .webix_radio_option.webix_disabled .webix_custom_radio {
  color: #94A1B3;
  cursor: not-allowed;
}
.webix_radio_option {
  display: table;
  float: left;
  height: 32px;
  margin-right: 20px;
}
.webix_inp_radio_border,
.webix_el_radio .webix_label_right {
  display: table-cell;
  vertical-align: middle;
}
.webix_measure_size .webix_inp_radio_border,
.webix_measure_size.webix_el_radio .webix_label_right {
  display: table-cell !important;
}
.webix_measure_size .webix_radio_option {
  display: table !important;
  float: left !important;
}
.webix_measure_size .webix_line_break {
  display: block !important;
}
.webix_invalid .webix_el_box input,
.webix_invalid .webix_custom_radio,
.webix_invalid .webix_el_box .webix_inp_static,
.webix_invalid .webix_el_box select,
.webix_invalid .webix_el_box textarea,
input.webix_invalid,
select.webix_invalid,
textarea.webix_invalid {
  background-color: #fff6f6;
  border-color: #fea5a8;
}
.webix_invalid .webix_inp_bottom_label {
  font-size: 12px;
  color: #fd595f;
}
.webix_el_colorpicker .webix_input_icon {
  padding-left: 0;
  width: 24px;
  height: 24px;
  border-radius: 2px;
}
.webix_el_search .webix_el_box {
  position: relative;
}
.webix_el_search input {
  padding-right: 30px;
}
.webix_el_search span {
  position: absolute;
  right: 19px;
  cursor: pointer;
  padding-left: 7px;
}
.webix_view.webix_control > .webix_disabled {
  opacity: 0;
}
.webix_disabled_view.webix_control .webix_image {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.webix_el_label.webix_disabled_view {
  color: #94A1B3;
}
.webix_view.webix_control .webix_disabled_box label,
.webix_view.webix_control .webix_disabled_box .webix_input_icon,
.webix_view.webix_control .webix_disabled_box button,
.webix_view.webix_control .webix_disabled_box .webix_slider_title {
  color: #94A1B3;
}
.webix_view.webix_control .webix_disabled_box input,
.webix_view.webix_control .webix_disabled_box select,
.webix_view.webix_control .webix_disabled_box textarea,
.webix_view.webix_control .webix_disabled_box .webix_inp_static,
.webix_view.webix_control .webix_disabled_box .webix_slider_right,
.webix_view.webix_control .webix_disabled_box .webix_slider_handle {
  color: #94A1B3;
  background: #fbfbfd;
  border-color: #EDEFF0;
}
.webix_view.webix_control .webix_disabled_box .webix_inp_counter_prev,
.webix_view.webix_control .webix_disabled_box .webix_inp_counter_next,
.webix_view.webix_control .webix_disabled_box .webix_segment_0,
.webix_view.webix_control .webix_disabled_box .webix_segment_1,
.webix_view.webix_control .webix_disabled_box .webix_segment_N {
  color: #94A1B3;
  background: #f4f5f9;
  border-color: #f4f5f9;
  text-shadow: none;
}
.webix_view.webix_control .webix_disabled_box .webix_segment_0.webix_selected,
.webix_view.webix_control .webix_disabled_box .webix_segment_1.webix_selected,
.webix_view.webix_control .webix_disabled_box .webix_segment_N.webix_selected {
  background-color: #dde0ec;
}
.webix_view.webix_control .webix_disabled_box .webix_slider_left {
  background-color: #94A1B3;
}
.webix_disabled_top_label {
  color: #94A1B3;
}
label.webix_required:after,
legend.webix_required:after,
.webix_measure_size.webix_required:after,
.webix_richtext_inp_label.webix_required .webix_template:after {
  content: "*";
  padding-left: 4px;
  color: #FF5C4C;
}
.webix_multicombo .webix_inp_static {
  padding: 0 3px 0 0;
}
.webix_el_text .webix_multicombo_input {
  background-color: transparent;
  border: 0;
  float: left;
  vertical-align: middle;
  font-size: 14px;
  padding: 0px;
  color: #475466;
  font-family: Roboto, sans-serif;
  margin-left: 9px;
}
.webix_el_text .webix_multicombo_input:focus {
  border: 0;
  box-shadow: none;
}
.webix_multicombo_listbox {
  width: auto;
  line-height: 1;
  margin: 0;
  padding: 0;
  list-style: none;
}
.webix_multicombo_value,
.webix_multicombo_tag {
  float: left;
  position: relative;
  background-color: #f2f2f2;
  display: inline-block;
  margin: 3px 0 0 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 2px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_multicombo_value {
  padding: 0 20px 0 5px;
}
.webix_multicombo_tag {
  min-width: 15px;
  text-align: center;
}
.webix_multicombo_tag,
.webix_multicombo_value.webix_readonly {
  padding: 0 5px 0 5px;
}
.webix_multicombo_delete {
  position: absolute;
  top: 50%;
  right: 4px;
  margin-top: -8px;
  font-size: 16px;
  height: 16px;
  line-height: 1;
  color: #475466;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.webix_multicombo_delete::after {
  content: "\F011";
  font-family: "Webix Material Icons";
}
.webix_multilist .webix_list_item {
  white-space: nowrap;
  text-overflow: ellipsis;
}
.webix_multilist .webix_selected {
  background: #f2f2f2;
  color: #475466;
}
.webix_multilist .wxi-checkbox-marked,
.webix_multilist .wxi-checkbox-blank {
  vertical-align: middle;
  font-size: 24px;
  width: 24px;
  margin-right: 4px;
}
.webix_checksuggest_select_all {
  border-color: transparent;
  border-bottom: 1px solid #DADEE0;
  margin-bottom: 1px;
}
.webix_checksuggest_select_all .webix_label_right {
  text-transform: capitalize;
  display: block;
  padding: 4px 12px 0 12px;
}
.webix_checksuggest_select_all input {
  display: none;
}
.webix_checksuggest_select_all:hover {
  cursor: pointer;
}
.webix_checksuggest_select_all:hover .webix_label_right {
  cursor: pointer;
  color: #F4F5F9;
}
.webix_el_texthighlight {
  position: relative;
}
.webix_el_texthighlight.webix_el_text .webix_text_highlight_value {
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
  overflow: hidden;
  white-space: pre;
}
.webix_el_texthighlight.webix_el_text input,
.webix_el_texthighlight.webix_el_text .webix_text_highlight {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 0 12px;
}
.webix_el_texthighlight.webix_el_text .webix_input_icon {
  top: 3px;
}
.webix_el_texthighlight.webix_el_textarea textarea {
  padding: 5px 12px;
}
.webix_el_texthighlight.webix_el_textarea .webix_text_highlight_value {
  word-wrap: break-word;
  margin: 5px 12px;
  white-space: pre-wrap;
}
.webix_el_texthighlight.webix_disabled_view .webix_text_highlight {
  background-color: #fbfbfd;
}
.webix_el_texthighlight .webix_text_highlight,
.webix_el_texthighlight input,
.webix_el_texthighlight textarea {
  line-height: normal;
  letter-spacing: normal;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_el_texthighlight .webix_text_highlight {
  -webkit-text-size-adjust: none;
  height: 100%;
  position: absolute;
  border: 1px solid transparent;
  border-radius: 2px;
  background-color: #fff;
  overflow: auto;
}
.webix_el_texthighlight .webix_text_highlight .webix_text_highlight_value {
  text-align: left;
}
.webix_el_texthighlight.webix_control .webix_el_box input,
.webix_el_texthighlight.webix_control .webix_el_box textarea {
  display: block;
  position: relative;
  z-index: 1;
  color: transparent;
  caret-color: #475466;
  background: transparent;
  resize: none;
}
.webix_el_texthighlight textarea {
  overflow: auto;
}
.webix_el_tabbar {
  background: transparent;
}
.webix_all_tabs {
  height: 100%;
  display: table;
}
.webix_tab_filler {
  display: table-cell;
  background: transparent;
  font-size: 1px;
  border-bottom: 1px solid #DADEE0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_tab_filler:first-child {
  border-right: 1px solid #DADEE0;
}
.webix_item_tab:first-child {
  border-left: 1px solid #DADEE0;
}
.webix_item_tab {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #DADEE0;
  border-left-width: 0px;
  background: #FFFFFF;
  color: #1CA1C1;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 0px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webixtype_bottom .webix_item_tab {
  border-radius: 0;
}
.webix_item_tab.webix_selected {
  border-bottom-width: 0px;
  background: #FFFFFF;
  color: #1CA1C1;
}
.webixtype_bottom .webix_item_tab.webix_selected {
  border-bottom-width: 1px;
}
.webix_before_all_tabs {
  width: 100%;
}
.webix_after_all_tabs {
  width: 100%;
  border-left: 1px solid #DADEE0;
  border-right: 1px solid #DADEE0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_view.webix_control .webix_disabled_box .webix_item_tab {
  color: #94A1B3;
  background: #f4f5f9;
  border-color: #f4f5f9;
  text-shadow: none;
}
.webix_el_tabbar .webixtype_icon .webix_img_btn {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 auto;
  text-align: center;
}
.webix_el_tabbar .webixtype_icon .webix_img_btn .webix_icon_btn {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 3px;
}
.webix_el_tabbar .webixtype_icon .webix_item_tab,
.webix_el_tabbar .webixtype_iconTop .webix_item_tab,
.webix_el_tabbar .webixtype_image .webix_item_tab {
  padding: 3px 0;
  border-radius: 0;
}
.webix_el_tabbar .webixtype_icon .webix_img_btn_top,
.webix_el_tabbar .webixtype_iconTop .webix_img_btn_top,
.webix_el_tabbar .webixtype_image .webix_img_btn_top {
  background-position: center 0;
  position: relative;
  height: 100%;
}
.webix_tab_more_icon {
  cursor: pointer;
  display: table-cell;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  padding: 0 3px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_tab_more_icon .webix_icon {
  width: 100%;
}
.webix_tab_list .webix_list_item:last-child {
  border-bottom: none;
}
.webix_icon.webix_tab_close {
  font-size: 12px;
  float: right;
  padding: 4px;
  margin-top: -4px;
}
.webix_icon.webix_tab_close:focus {
  color: #FF5C4C;
}
.webix_all_tabs .webix_item_tab.webix_disabled {
  color: #94A1B3;
  background-color: #fbfbfd;
  border-color: transparent;
  cursor: not-allowed;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.webix_all_tabs .webix_item_tab.webix_disabled:hover,
.webix_all_tabs .webix_item_tab.webix_disabled.webix_selected,
.webix_all_tabs .webix_item_tab.webix_disabled.webix_selected:hover {
  color: #94A1B3;
  background-color: #fbfbfd;
  border-color: transparent;
}
.webix_menu .webix_list_item:hover,
.webix_menu-x .webix_list_item:hover,
.webix_menu .webix_list_item:active,
.webix_menu-x .webix_list_item:active,
.webix_menu .webix_list_item:focus,
.webix_menu-x .webix_list_item:focus {
  background: rgba(100, 100, 100, 0.1);
}
.webix_menu .webix_list_item,
.webix_menu-x .webix_list_item {
  border: none;
  text-decoration: none;
  background: #FFFFFF;
  height: 36px;
}
.webix_menu .webix_list_item .webix_submenu_icon,
.webix_menu-x .webix_list_item .webix_submenu_icon {
  float: right;
  text-align: right;
  position: relative;
  margin-left: 4px;
}
.webix_menu-x {
  border-color: transparent;
  background: transparent;
  color: #FFFFFF;
}
.webix_menu-x .webix_list_item:first-child {
  margin-left: 0;
}
.webix_menu-x .webix_submenu_icon {
  top: 14px;
}
.webix_menu-x .webix_list_item {
  display: inline-block;
  line-height: 32px;
}
.webix_menu-x .webix_list_item {
  border-right: 1px solid #fff;
  border-left: 0px;
  background: #1CA1C1;
  color: #fff;
  font-size: 14px;
}
.webix_menu-x .webix_list_item .webix_submenu_icon:before {
  content: '\F001';
}
.webix_menu-x .webix_list_item .webix_icon_btn {
  color: #fff;
  text-align: left;
}
.webix_menu-x .webix_list_item.webix_selected {
  box-shadow: none;
}
.webix_menu-x .webix_list_item:hover,
.webix_menu-x .webix_list_item:active,
.webix_menu-x .webix_list_item:focus {
  background-color: #1992af;
}
.webix_menu-x .webix_list_item:first-child {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.webix_menu-x .webix_list_item:last-child {
  border-right: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.webix_menu-x .webix_list_item.webix_disabled {
  color: #94A1B3;
  background-color: #fbfbfd;
}
.webix_menu-x.webix_disabled_view .webix_disabled {
  background-color: transparent;
}
.webix_menu-x.webix_disabled_view .webix_list_item.menu {
  color: #94A1B3;
  background: #f4f5f9;
}
/* Submenu */
.webix_menu {
  background: #FFFFFF;
  border: 1px solid #DADEE0;
  border-radius: 0px;
}
.webix_menu .webix_list_item {
  display: block;
}
.webix_menu .webix_context_separator {
  height: 3px;
  padding: 1px 3px 0 3px;
}
.webix_menu .webix_context_separator .sep_line {
  height: 1px;
  border-top: 1px solid #DADEE0;
}
.webix_menu .webix_submenu_icon {
  top: 12px;
}
.webix_menu .webix_context_spacer {
  height: 36px;
}
.webix_menu_link {
  color: inherit;
  text-decoration: none;
  display: block;
  height: 100%;
}
/* Toolbar */
.webix_toolbar.webix_layout_subbar .webix_el_label .webix_el_box {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #475466;
  letter-spacing: 0px;
}
.webix_toolbar.webix_layout_subbar.webix_dark {
  background: #657584;
  border-color: #657584;
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_el_button,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_el_label .webix_el_box,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_inp_label,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_inp_top_label,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_slider_title {
  color: #FFFFFF;
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_secondary .webix_icon,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_transparent .webix_icon,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_secondary .webix_img_btn_text,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_transparent .webix_img_btn_text,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_secondary .webix_icon_btn,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_transparent .webix_icon_btn {
  color: #FFFFFF;
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_transparent button {
  color: #FFFFFF;
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_transparent button:hover,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_transparent button:focus {
  background-color: rgba(0, 0, 0, 0.2);
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_transparent button:active {
  background-color: rgba(0, 0, 0, 0.4);
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_secondary button,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_inp_counter_next,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_inp_counter_prev,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_segment_0,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_segment_1,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_segment_N,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_menu-x .webix_list_item {
  background-color: rgba(0, 0, 0, 0.2);
  color: #FFFFFF;
  border-color: transparent;
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_view > .webix_template {
  color: #FFFFFF;
  background-color: #657584;
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_secondary button:hover,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_menu-x .webix_list_item:hover,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_inp_counter_next:hover,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_inp_counter_prev:hover,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_segment_0:hover,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_segment_1:hover,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_segment_N:hover {
  color: #FFFFFF;
  background-color: rgba(0, 0, 0, 0.4);
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_secondary button:focus,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_menu-x .webix_list_item:focus,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_inp_counter_next:focus,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_inp_counter_prev:focus,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_segment_0:focus,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_segment_1:focus,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_segment_N:focus {
  background-color: rgba(0, 0, 0, 0.4);
  color: #FFFFFF;
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_secondary button:active,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_menu-x .webix_list_item:active,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_inp_counter_next:active,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_inp_counter_prev:active,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_segment_0:active,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_segment_1:active,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_segment_N:active {
  background-color: rgba(0, 0, 0, 0.5);
  color: #FFFFFF;
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_inp_static,
.webix_toolbar.webix_layout_subbar.webix_dark input[type=text],
.webix_toolbar.webix_layout_subbar.webix_dark input[type=combo],
.webix_toolbar.webix_layout_subbar.webix_dark input[type=datepicker],
.webix_toolbar.webix_layout_subbar.webix_dark textarea,
.webix_toolbar.webix_layout_subbar.webix_dark select,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_text_highlight {
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.7);
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_el_texthighlight input,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_el_texthighlight textarea {
  caret-color: rgba(255, 255, 255, 0.7);
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_inp_static input[type=text] {
  background-color: transparent;
}
.webix_toolbar.webix_layout_subbar.webix_dark select option {
  background-color: #657584;
  color: #FFFFFF;
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_multicombo_value,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_multicombo_tag {
  color: #657584;
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_inp_static:focus,
.webix_toolbar.webix_layout_subbar.webix_dark input[type=text]:focus,
.webix_toolbar.webix_layout_subbar.webix_dark input[type=combo]:focus,
.webix_toolbar.webix_layout_subbar.webix_dark input[type=datepicker]:focus,
.webix_toolbar.webix_layout_subbar.webix_dark textarea:focus,
.webix_toolbar.webix_layout_subbar.webix_dark select:focus {
  border-color: #FFFFFF;
  box-shadow: none;
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_el_counter button {
  background-color: rgba(0, 0, 0, 0.3);
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_el_counter input[type=text]:focus {
  border-color: transparent;
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.webix_toolbar.webix_layout_subbar.webix_dark ::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.webix_toolbar.webix_layout_subbar.webix_dark ::-moz-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.webix_toolbar.webix_layout_subbar.webix_dark :-moz-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.webix_toolbar.webix_layout_subbar.webix_dark :-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_input_icon {
  color: rgba(255, 255, 255, 0.7);
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_selected.webix_segment_0,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_selected.webix_segment_1,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_selected.webix_segment_N {
  background: #1CA1C1;
  color: #FFFFFF;
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_selected.webix_segment_0:hover,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_selected.webix_segment_1:hover,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_selected.webix_segment_N:hover {
  background-color: #1992af;
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_selected.webix_segment_0:focus,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_selected.webix_segment_1:focus,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_selected.webix_segment_N:focus {
  background-color: #1992af;
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_selected.webix_segment_0:active,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_selected.webix_segment_1:active,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_selected.webix_segment_N:active {
  background-color: #17839d;
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_el_icon,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_icon_button {
  color: #FFFFFF;
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_control {
  border-color: #1CA1C1;
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_icon_button:hover:before {
  background-color: rgba(0, 0, 0, 0.2);
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_icon_button:hover .webix_icon,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_icon_button:focus .webix_icon {
  opacity: 1;
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_icon_button .webix_icon {
  color: #FFFFFF;
  opacity: 0.7;
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_custom_checkbox,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_custom_radio {
  color: #FFFFFF;
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_custom_checkbox:focus,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_custom_radio:focus {
  color: #f0f0f0;
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_label_right {
  color: #FFFFFF;
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_view.webix_control .webix_disabled_box .webix_button {
  color: rgba(255, 255, 255, 0.4);
  background-color: rgba(0, 0, 0, 0.1);
  text-shadow: none;
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_view.webix_control .webix_disabled_box .webix_img_btn_text {
  color: rgba(255, 255, 255, 0.4);
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_disabled_view.webix_control .webix_icon_btn,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_disabled_view.webix_control .webix_icon {
  color: rgba(255, 255, 255, 0.4);
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_disabled_view.webix_menu-x .webix_list_item.menu {
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(255, 255, 255, 0.4);
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_toolbar .webix_disabled_view.webix_control .webix_icon,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_toolbar .webix_disabled_view.webix_control .webix_disabled_box .webix_img_btn_text,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_toolbar .webix_disabled_view.webix_control .webix_icon_btn {
  color: rgba(255, 255, 255, 0.4);
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_view.webix_control .webix_disabled_box label,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_view.webix_control .webix_disabled_box .webix_input_icon,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_view.webix_control .webix_disabled_box button {
  color: rgba(255, 255, 255, 0.4);
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_view.webix_control .webix_disabled_box input,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_view.webix_control .webix_disabled_box select,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_view.webix_control .webix_disabled_box textarea,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_view.webix_control .webix_disabled_box .webix_inp_static,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_view.webix_control .webix_disabled_box .webix_inp_counter_prev,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_view.webix_control .webix_disabled_box .webix_inp_counter_next {
  color: rgba(255, 255, 255, 0.4);
  background-color: rgba(0, 0, 0, 0.1);
  border-color: transparent;
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_view.webix_control .webix_disabled_box .webix_inp_static input {
  background-color: transparent;
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_view.webix_control .webix_disabled_box .webix_segment_0,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_view.webix_control .webix_disabled_box .webix_segment_1,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_view.webix_control .webix_disabled_box .webix_segment_N,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_view.webix_control .webix_disabled_box .webix_item_tab {
  color: rgba(255, 255, 255, 0.4);
  background-color: rgba(0, 0, 0, 0.1);
  text-shadow: none;
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_view.webix_control .webix_disabled_box .webix_segment_0.webix_selected,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_view.webix_control .webix_disabled_box .webix_segment_1.webix_selected,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_view.webix_control .webix_disabled_box .webix_segment_N.webix_selected {
  background-color: rgba(0, 0, 0, 0.15);
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_disabled_top_label {
  color: rgba(255, 255, 255, 0.4);
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_invalid .webix_el_box input,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_invalid .webix_el_box .webix_inp_static,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_invalid .webix_el_box select,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_invalid .webix_el_box textarea,
.webix_toolbar.webix_layout_subbar.webix_dark input.webix_invalid,
.webix_toolbar.webix_layout_subbar.webix_dark select.webix_invalid,
.webix_toolbar.webix_layout_subbar.webix_dark textarea.webix_invalid {
  color: #ffdedb;
  background-color: rgba(255, 92, 76, 0.3);
  border-color: #ff8d82;
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_invalid .webix_multicombo_value,
.webix_toolbar.webix_layout_subbar.webix_dark .webix_invalid .webix_multicombo_tag {
  color: #FF5C4C;
}
.webix_toolbar.webix_layout_subbar.webix_dark .webix_invalid .webix_el_box .webix_inp_static input {
  background-color: transparent;
}
.webix_toolbar .webix_layout_line,
.webix_toolbar .webix_layout_clean {
  background: transparent;
}
.webix_hidden_upload,
.webix_el_button .webix_hidden_upload {
  height: 1px;
  width: 1px;
  border: 0 !important;
  font-size: 1px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
  opacity: 0;
}
.webix_upload_client,
.webix_upload_error,
.webix_upload_server {
  width: 60px;
  text-align: center;
  float: right;
}
.webix_error_icon,
.webix_cancel_icon {
  display: inline-block;
  background-repeat: no-repeat;
}
.webix_error_icon {
  width: 18px;
  height: 16px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAQCAYAAAAbBi9cAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjZFMDdENjlDNjE2QzExRTI5QzhBQUU2OUI3RDY4RTFCIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjZFMDdENjlENjE2QzExRTI5QzhBQUU2OUI3RDY4RTFCIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NkUwN0Q2OUE2MTZDMTFFMjlDOEFBRTY5QjdENjhFMUIiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NkUwN0Q2OUI2MTZDMTFFMjlDOEFBRTY5QjdENjhFMUIiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4ZJnE7AAABdklEQVR42mK80aLPgAcwA/EcIOYB4mgg/oWuQL36AphmYcAPYvl5WBOYmBgY3n/6fQTIn4hLIRMeQ0CuaBHgY2EQ4GMF8euAWIgcg6qF+NmkOdiZGdhYmRiEBdhAhrSQapAKEBcJC7DCBUQE2UBUGhAbkGLQBDEhdjYmJkaGtHVMDD4LmBk+/2RgEBdhBwX+ZGINcuLiZPYW5Ie45tknRrAhIFqAl5WBh4vFBijsR8ggkI29IA0wwMsOo/+DaWjA9wIxGz6D0oAKDXi5EalCkvc/2DApPgifm5OZQZCPFRyGuAwCxwqya0AAZADMVTAgyA92TDUQS2MzqAUUxcAgRjPoP9hVyICVhREUi6B01g4TY4RmES0gvqSuyMPMQAK4ef/LXyBlAswmF2DWd4kJs2M1pGgLIvrRAXJyAIWqNzcXi7cgHysDqQAUnt9//LW52WoQBfLaDRlxDnWgYQzkAKBBDI+ef38E0n3nycsf6gyUgUcAAQYA9DZJKVXGocQAAAAASUVORK5CYII=");
}
.webix_upload_server {
  color: #55CD97;
}
.webix_remove_upload {
  float: right;
}
.webix_cancel_icon {
  margin-top: 9px;
  width: 14px;
  height: 14px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAQAAAC1QeVaAAAAsElEQVR4XoVQUQbDQBScAywIuUcpKCWglBJCDlBKf0vpbymhd5mDhIDcoAcoJQSEsn2G5KmWzMDsPG92ZyGAgVfWfBtrUwEOZnwwOu2UjaPCbScLAEzYmzxyxafMgaUlRXMTsJJ1B7jgy0Y7gHt5FdgyimeAS+a6ZpDTQkLkCQbmk9OBHaPv+p6x99jLFFuOsTMPSlXl8FVlrSopFBP/sIQAbn6+bwsHA29sNGhMBQgf1cri4JYEbDkAAAAASUVORK5CYII=");
}
.webix_upload_flash {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #fff;
}
.webix_drop_file {
  background: #f4f4f4;
  position: relative;
}
.webix_drop_file::before {
  position: absolute;
  bottom: 10px;
  left: 0px;
  width: 100%;
  text-align: center;
  pointer-events: none;
}
.webix_fieldset > fieldset {
  border: 1px solid #DADEE0;
  margin: 0px;
  padding: 5px 8px;
}
.webix_fieldset_label {
  color: #475466;
  font-size: 12px;
  padding: 0px 8px;
  text-transform: uppercase;
}
.webix_forminput > fieldset {
  border: none;
  margin: 0px;
  padding: 0px;
}
.webix_forminput_label {
  color: #475466;
  font-family: Roboto, sans-serif;
  float: left;
  line-height: 32px;
  padding: 3px 2px;
  padding-right: 9px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
legend.webix_forminput_label_top {
  width: 100%;
  padding: 3px 2px 0;
}
.webix_slider_box {
  position: relative;
  padding: 0px 10px;
  height: 100%;
  float: left;
  border-radius: 5px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: -11px;
  padding-top: 15px;
}
.webix_slider_box .webix_slider_left {
  height: 10px;
  float: left;
  width: 100px;
  background: #cbeff7;
  border: 1px solid #e8eaec;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_slider_box .webix_slider_right {
  height: 10px;
  float: left;
  width: 100px;
  background: #fff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid #e8eaec;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_slider_box .webix_slider_handle {
  z-index: 1;
  position: absolute;
  width: 14px;
  height: 14px;
  top: 12px;
  border-radius: 8px;
}
.webix_slider_box .webix_slider_handle:focus {
  border-color: #1CA1C1;
}
.webix_slider_box .webix_slider_left {
  height: 6px;
  border: 1px solid #CCD7E6;
  background: #1CA1C1;
}
.webix_slider_box .webix_slider_right {
  height: 6px;
  border-color: #CCD7E6;
}
.webix_slider_box .webix_slider_handle {
  width: 14px;
  height: 14px;
  border: 1px solid #CCD7E6;
  background-color: #FFFFFF;
  top: 10px;
}
.webix_rangeslider .webix_slider_box .webix_slider_left {
  position: absolute;
}
.webix_rangeslider .webix_slider_box .webix_slider_active {
  z-index: 2;
}
.webix_rangeslider .webix_slider_box .webix_slider_right {
  border-radius: 5px;
}
.webix_rangeslider .webix_slider_title_box {
  position: relative;
  float: left;
  height: 18px;
  width: 1px;
}
.webix_rangeslider .webix_slider_title.webix_slider_move {
  position: absolute;
}
.webix_rangeslider.webix_slider_vertical .webix_slider_box .webix_slider_right {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.webix_rangeslider.webix_slider_vertical .webix_slider_title.webix_slider_move {
  display: block;
  position: relative;
}
.webix_slider_alt.webix_slider_vertical .webix_slider_box .webix_slider_handle {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAFAQMAAABCXz8WAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAZQTFRFAAAAAAAApWe5zwAAAAJ0Uk5TNgD/j1xNAAAADklEQVR4nGNgYPjPAMYACgAB/xSLTywAAAAASUVORK5CYII=");
  background-repeat: no-repeat;
}
.webix_slider_alt .webix_slider_box .webix_slider_left {
  border: 1px solid #e1e1e1;
}
.webix_slider_alt .webix_slider_box .webix_slider_right {
  border: 1px solid #e1e1e1;
}
.webix_slider_alt .webix_slider_box .webix_slider_handle {
  width: 11px;
  margin: 0 2px;
  height: 14px;
  top: 12px;
  border-radius: 2px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAICAYAAAAx8TU7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTJCMjdFNENBRTg4MTFFMjk2NjJGMTJENjkyNDA2NTkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTJCMjdFNERBRTg4MTFFMjk2NjJGMTJENjkyNDA2NTkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5MkIyN0U0QUFFODgxMUUyOTY2MkYxMkQ2OTI0MDY1OSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5MkIyN0U0QkFFODgxMUUyOTY2MkYxMkQ2OTI0MDY1OSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Powz/icAAAAaSURBVHjaYmBgYDBjgAA4zcSABQysIECAAQBn+ACx2PqtbAAAAABJRU5ErkJggg==");
  background-repeat: no-repeat;
  background-position: 3px center;
}
.webix_slider_alt .webix_slider_box .webix_slider_handle:focus {
  border-color: #1CA1C1;
}
.webix_slider_alt .webix_slider_box .webix_slider_left {
  border: 1px solid #CCD7E6;
  background: #1CA1C1;
}
.webix_slider_alt .webix_slider_box .webix_slider_right {
  border-color: #CCD7E6;
}
.webix_slider_alt .webix_slider_box .webix_slider_handle {
  background-color: #FFFFFF;
  border: 1px solid #CCD7E6;
  top: 10px;
}
.webix_slider_vertical .webix_slider_box .webix_slider_left,
.webix_slider_vertical .webix_slider_box .webix_slider_right {
  width: 10px;
  float: none;
}
.webix_slider_vertical .webix_slider_box .webix_slider_left {
  border-top-left-radius: 0;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 5px;
}
.webix_slider_vertical .webix_slider_box .webix_slider_right {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 0;
}
.webix_slider_vertical .webix_slider_box .webix_slider_handle {
  left: 7px;
}
.webix_slider_vertical .webix_slider_title {
  padding-top: 1px;
  text-align: left;
}
.webix_slider_vertical.webix_slider_alt .webix_slider_handle {
  height: 11px;
  width: 14px;
  left: 5px;
}
.webix_slider_title {
  text-align: center;
}
.webix_slider_title.webix_slider_move {
  position: relative;
  text-align: left;
  display: inline-block;
  width: auto;
  white-space: nowrap;
}
.webix_switch_toggle {
  position: absolute;
  visibility: hidden;
}
.webix_switch_box {
  display: block;
  position: relative;
  top: 50%;
  margin-top: -12px;
  height: 24px;
  cursor: pointer;
  background-color: #f1f1f1;
  border: 1px solid #DADEE0;
  border-radius: 60px;
  transition: background-color 0.4s ease;
  color: #666;
  text-align: center;
  float: left;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_el_switch .webix_label_right {
  display: block;
  padding-top: 3px;
  margin-top: 4px;
}
.webix_switch_handle {
  height: 22px;
  width: 22px;
  border-radius: 100%;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  border: 1px solid #eee;
  position: absolute;
  top: 0px;
  background-color: #fff;
  transition: left 0.3s ease;
  cursor: pointer;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_switch_handle:focus,
.webix_switch_box:active .webix_switch_handle {
  box-shadow: 0 0px 2px 2px rgba(0, 0, 0, 0.2);
}
.webix_switch_text {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 1px 0 0 18px;
  display: inline-block;
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_switch_on .webix_switch_text {
  padding: 1px 18px 0 0;
}
.webix_switch_box.webix_switch_on {
  background-color: #F4F5F9;
  color: #fff;
}
.webix_progress_top,
.webix_progress_bottom {
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 6px;
  overflow: hidden;
  z-index: 95;
  background: #f2f2f2;
}
.webix_progress_bottom {
  bottom: 0px;
  top: auto;
  height: 17px;
}
.webix_progress_top .webix_progress_state,
.webix_progress_bottom .webix_progress_state {
  width: 0px;
  height: 6px;
  background: #F4F5F9;
  transition: width 3s;
}
.webix_progress_bottom .webix_progress_state {
  height: 17px;
}
.webix_progress_icon {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 95;
}
.webix_progress_icon .webix_progress_state {
  font-family: "Webix Material Icons";
  font-size: 50px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -25px;
  margin-top: -25px;
  display: inline-block;
  color: #93a0b3;
}
.webix_gage_box {
  display: table;
  height: 100%;
  width: 100%;
}
.webix_gage_box > div {
  display: table-cell;
  vertical-align: middle;
}
.webix_gage_body {
  height: 100%;
  width: 100%;
  transform: rotate(180deg);
}
.webix_gage_label {
  font-size: 0.9em;
  text-align: center;
  margin-top: 10px;
}
.webix_gage_placeholder {
  margin-top: -20px;
}
.webix_gage_info {
  text-align: center;
  font-size: 0.7em;
  position: relative;
  margin: 0 auto 10px auto;
}
.webix_gage_min_range {
  left: -23%;
}
.webix_gage_max_range {
  right: -23%;
}
.webix_gage_min_range,
.webix_gage_max_range {
  display: inline-block;
  margin-top: 20px;
  position: absolute;
  text-align: center;
  width: 46%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.webix_gage_range_info {
  font-size: 1.2em;
  line-height: 1;
}
.webix_gage_value {
  font-size: 2.2em;
  color: #475466;
}
.webix_gage_value,
.webix_gage_range_info {
  overflow: hidden;
  text-overflow: ellipsis;
}
.webix_gage_gradient_point_animated {
  transition: transform 1.3s linear;
}
.webix_gage_animated {
  stroke: hsl(120, 100%, 50%);
  animation: gage_dash 1.3s linear forwards;
  transition: stroke 1.3s linear, stroke-dasharray 1.3s linear;
}
@keyframes gage_dash {
  to {
    stroke-dashoffset: 0;
  }
}
.webix_gage_small_font .webix_gage_info,
.webix_gage_small_font .webix_gage_range_info {
  font-size: 1em;
}
.webix_gage_small_font .webix_gage_label,
.webix_gage_small_font .webix_gage_value {
  font-weight: 500;
  font-size: 1.2em;
}
.webix_gage_small_font .webix_gage_min_range,
.webix_gage_small_font .webix_gage_max_range {
  margin-top: 0;
}
.webix_gage_small_font .webix_gage_placeholder {
  margin-top: 0;
  padding-top: 20px;
}
.webix_bullet_header {
  font-weight: 500;
}
.webix_bullet_header,
.webix_bullet_subheader {
  font-size: 14px;
  fill: #475466;
}
.webix_bullet_scale {
  fill: #74869f;
  font-size: 12px;
  font-weight: 500;
  stroke: none;
  text-anchor: middle;
}
.webix_bullet_scale_lines {
  stroke: #74869f;
}
.webix_bullet_marker {
  y: 5px;
  width: 3px;
}
.webix_timeline .webix_scroll_cont {
  padding-top: 28px;
}
.webix_timeline_item {
  line-height: 0;
  padding: 0 12px;
  position: relative;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_timeline_date,
.webix_timeline_event {
  line-height: 20px;
  overflow: hidden;
  position: absolute;
  top: 0px;
}
.webix_timeline_left .webix_timeline_date,
.webix_timeline_right .webix_timeline_details,
.webix_timeline_right .webix_timeline_value {
  text-align: right;
}
.webix_timeline_value {
  font-weight: 500;
}
.webix_timeline_details {
  color: #94A1B3;
  padding-top: 2px;
}
.webix_timeline_date {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.webix_timeline_horizontal .webix_scroll_cont {
  white-space: nowrap;
  display: inline-block;
  padding-left: 28px;
  height: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_timeline_horizontal .webix_timeline_item {
  display: inline-block;
  height: 100%;
  padding: 12px 0 12px 0;
  white-space: normal;
}
.webix_timeline_horizontal .webix_timeline_item .webix_timeline_date,
.webix_timeline_horizontal .webix_timeline_item .webix_timeline_event {
  left: 0px;
}
.webix_timeline_horizontal .webix_timeline_item.webix_timeline_bottom .webix_timeline_event {
  top: initial;
}
.webix_treemap {
  background-color: #f5f5f5;
}
.webix_treemap .webix_scroll_cont {
  position: relative;
  height: 100%;
  width: 100%;
}
.webix_treemap_item {
  position: absolute;
  color: #444;
  overflow: hidden;
  text-align: left;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 3px 5px;
  border-width: 0px 1px 1px 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.2);
  background: transparent;
  cursor: pointer;
}
.webix_treemap_level_top {
  z-index: 1;
  border-color: rgba(0, 0, 0, 0.4);
}
.webix_treemap_item_bottom {
  border-bottom-width: 0;
}
.webix_treemap_item_right {
  border-right-width: 0;
}
.webix_treemap_item:hover {
  box-shadow: inset 0 0 5px #666;
}
.webix_treemap_item.webix_selected {
  border-color: #F4F5F9;
  box-shadow: inset 0 0 1px 2px #F4F5F9;
}
.webix_treemap_header {
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_treemap_header {
  padding: 0 10px;
  border-bottom: 1px solid #DADEE0;
}
.webix_treemap_header_item {
  cursor: pointer;
}
.webix_treemap_header_item:last-child {
  cursor: default;
}
.webix_treemap_reset {
  float: right;
  width: 25px;
  text-align: center;
}
.webix_treemap_reset:before {
  content: "\F011";
  color: #475466;
  font-family: "Webix Material Icons";
  font-size: 16px;
  display: block;
  cursor: pointer;
}
.webix_treemap_path_icon {
  width: 20px;
  text-align: center;
}
.webix_barcode {
  position: relative;
}
.webix_barcode .webix_canvas_text {
  font-size: 14px;
  padding: 0 2px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.portlet_drag {
  position: absolute;
  z-index: 1;
  top: 5px;
  right: 5px;
  width: 18px;
  opacity: 0.5;
  cursor: pointer;
}
.portlet_in_drag {
  opacity: 0.4;
}
.portlet_marker,
.portlet_markertop,
.portlet_markerbottom,
.portlet_markerleft,
.portlet_markerright {
  opacity: 0.5;
  background: #9169BE;
  position: absolute;
  z-index: 2;
  transition: top 0.5s, left 0.5s, width 0.5s, height 0.5s;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
.portlet_markertop {
  height: 50%;
}
.portlet_markerbottom {
  height: 50%;
  top: 50%;
}
.portlet_markerleft {
  width: 50%;
}
.portlet_markerright {
  width: 50%;
  left: 50%;
}
.panel_icon {
  position: absolute;
  z-index: 93;
  top: 5px;
  right: 5px;
  line-height: normal;
  font-size: 13px;
  width: 18px;
  opacity: 0.5;
  cursor: pointer;
}
.webix_disabled_view .panel_icon {
  z-index: 91;
}
.panel_target {
  position: absolute;
  z-index: 3;
  background: rgba(0, 0, 0, 0.04);
  border: 1px solid #DADEE0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_popup.webix_sidemenu.webix_sidemenu_left {
  border-width: 0 1px 0 0;
  box-shadow: 2px 0 2px rgba(0, 0, 0, 0.05);
}
.webix_sidemenu_left.webix_animate {
  transition: left 0.6s;
}
.webix_popup.webix_sidemenu.webix_sidemenu_right {
  left: auto;
  border-width: 0 0 0 1px;
  box-shadow: -2px 0px 2px rgba(0, 0, 0, 0.05);
}
.webix_sidemenu_right.webix_animate {
  transition: right 0.6s;
}
.webix_popup.webix_sidemenu.webix_sidemenu_top {
  border-width: 0 0 1px 0 ;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
}
.webix_sidemenu_top .webix_win_body {
  position: relative;
}
.webix_sidemenu_top .webix_win_body > .webix_view {
  position: absolute;
  top: auto;
  left: 0;
  bottom: 0;
}
.webix_sidemenu_top.webix_animate,
.webix_sidemenu_top.webix_animate .webix_win_body {
  transition: height 0.6s;
}
.webix_popup.webix_sidemenu.webix_sidemenu_bottom {
  border-width: 1px 0 0 0 ;
  top: auto;
  box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.05);
}
.webix_sidemenu_bottom.webix_animate,
.webix_sidemenu_bottom.webix_animate .webix_win_body {
  transition: height 0.6s;
}
/* SideBar*/
.webix_sidebar {
  background: #ECEFF1;
}
.webix_sidebar .webix_tree_item.webix_selected:focus span {
  background: transparent;
}
.webix_sidebar .webix_tree_item {
  color: #454545;
  height: 44px;
  line-height: 43px;
}
.webix_sidebar .webix_scroll_cont > .webix_tree_leaves {
  display: block;
  padding: 0;
}
.webix_sidebar .webix_tree_leaves .webix_tree_leaves {
  margin-left: 0px;
}
.webix_sidebar_selected,
.webix_sidebar_expanded .webix_tree_item:hover {
  background-color: rgba(0, 0, 0, 0.02);
}
.webix_sidebar .webix_tree_item.webix_selected,
.webix_sidebar .webix_tree_item.webix_selected span {
  background-color: #F4F5F9;
  padding-right: 0;
}
.webix_sidebar .webix_tree_branch_1 .webix_tree_item {
  padding-left: 44px;
}
.webix_sidebar .webix_tree_branch_1 > .webix_tree_item {
  height: 44px;
  line-height: 44px;
  padding-left: 0;
}
.webix_sidebar .webix_tree_branch_1 {
  border-bottom: 1px solid #e5e5e5;
}
.webix_sidebar .webix_tree_item.webix_selected span,
.webix_sidebar .webix_tree_item span {
  margin: 0;
  padding: 0px;
}
.webix_icon.webix_sidebar_icon {
  width: 44px;
  text-align: center;
}
span.webix_sidebar_dir_icon {
  float: right;
  line-height: inherit;
}
/*SubMenu (Popup) */
.webix_sidebar_popup {
  border: none !important;
  box-shadow: 2px 3px 3px #ddd;
}
.webix_sidebar_popup,
.webix_sidebar_popup .webix_list_item {
  border-radius: 0;
}
.webix_sidebar_popup_right {
  box-shadow: -3px 3px 3px #ddd;
}
.webix_sidebar_popup_list.webix_sidebar_popup_left .webix_icon {
  float: right;
  line-height: inherit;
  width: 8px;
}
.webix_sidebar_popup_list.webix_sidebar_popup_right .webix_icon {
  float: left;
  line-height: inherit;
}
/*SubMenu: title*/
.webix_sidebar_popup_title {
  background: #ECEFF1;
}
.webix_sidebar_popup_title.webix_selected {
  border-left-color: #F4F5F9;
  background: #F4F5F9;
}
.webix_sidebar_popup_title .webix_template {
  line-height: 44px;
  padding: 0 10px;
  border: 1px solid #E5E5E5;
  border-left: none;
}
.webix_sidebar_popup_right .webix_sidebar_popup_title .webix_template {
  border: 1px solid #E5E5E5;
  border-right: none;
}
.webix_sidebar_selected.webix_sidebar_popup_title .webix_template {
  background: rgba(0, 0, 0, 0.03);
  border-left: none;
}
.webix_sidebar_popup_list .webix_list_item {
  border-left: 1px solid #E5E5E5;
  border-right: 1px solid #E5E5E5;
}
/*SubMenu: list*/
.webix_sidebar_popup_list .webix_list_item:first-child {
  border-top: 1px solid #E5E5E5;
}
.webix_sidebar_popup_list .webix_list_item:hover {
  background: #f6f9fb;
}
.webix_sidebar_popup_list .webix_list_item.webix_selected:hover {
  background: #F4F5F9;
}
.webix_menu .webix_list_item.webix_sidebar_selected {
  background: rgba(0, 0, 0, 0.02);
}
.webix_menu .webix_list_item.webix_sidebar_selected:hover {
  background: rgba(0, 0, 0, 0.02) !important;
}
.webix_view.webix_pdf {
  background-color: #404040;
  overflow: auto;
}
.webix_pdf .canvas_wrapper {
  margin: 0px auto;
  box-shadow: 5px 5px 15px #1c1c1c;
  width: 100%;
  height: 100%;
}
.webix_toolbar.pdf_bar .webix_img_btn {
  text-align: center;
}
.webix_toolbar.pdf_bar .webix_view {
  background-color: transparent;
}
.webix_toolbar.pdf_bar .webix_icon_btn {
  margin: 0;
}
.webix_toolbar.pdf_bar .webix_template {
  background-color: transparent;
  color: #fff;
  line-height: 2em;
}
.webix_toolbar.pdf_bar .webix_el_box input,
.webix_toolbar.pdf_bar .webix_inp_static {
  background-color: #5c5c5c;
  color: #fff;
  border-color: #404040;
}
.webix_toolbar.pdf_bar .webix_el_box input:focus,
.webix_toolbar.pdf_bar .webix_inp_static:focus {
  border-color: #333;
}
.webix_toolbar.pdf_bar .webix_el_box .webix_input_icon {
  color: #fff;
}
.webix_view.webix_popup.pdf_opt_list {
  border: none;
}
.pdf_opt_list .webix_list {
  background-color: #404040;
  color: #fff;
}
.pdf_opt_list .webix_list .webix_list_item {
  border-color: #474747;
  line-height: 1.5em;
}
.pdf_opt_list .webix_list .webix_list_item.webix_selected,
.pdf_opt_list .webix_list .webix_list_item:hover {
  color: #404040;
  background-color: #fff;
}
.webix_dbllist .bottom_label {
  text-align: center;
  font-size: 10px;
}
.webix_dbllist .webix_list,
.webix_dbllist .bottom_label {
  background: #ededed;
}
.webix_dbllist .webix_list_item {
  background: #FFFFFF;
  margin: 3px 4px 0px 4px;
  border: 1px solid #DADEE0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_dbllist .webix_list_item.webix_selected {
  border-bottom-color: #DADEE0;
  background-color: #F4F5F9;
  color: #475466;
}
.webix_dbllist .dbllist_button {
  width: 45%;
  height: 30px;
  margin-top: 4px;
  padding: 0;
  border-radius: 2px;
  border: 1px solid #DADEE0;
  cursor: pointer;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  background-color: #F4F5F9;
}
.webix_dbllist .dbllist_button .webix_icon {
  color: #1CA1C1;
  font-size: 20px;
  width: 26px;
}
.webix_dbllist .dbllist_button:first-child {
  margin-top: 36px;
  margin-right: 4px;
}
.webix_dbllist .dbllist_button:last-child {
  margin-left: 4px;
}
.webix_dbllist .dbllist_button:hover,
.webix_dbllist .dbllist_button:focus,
.webix_dbllist .dbllist_button:active {
  background-color: #e7e9f2;
}
.webix_invalid .webix_list {
  background: #f8e2e2;
}
.dbllist_buttons .webix_template {
  padding: 0 0 0 4px;
}
.webix_sparklines {
  padding: 1px 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_sparklines svg {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_sparklines_line {
  stroke: #3498db;
  stroke-width: 1;
  fill: transparent;
  stroke-linecap: round;
}
.webix_sparklines_item {
  fill: #3498db;
  stroke-width: 1;
}
.webix_sparklines_bar {
  fill: #6dbcf0;
}
.webix_sparklines_bar_negative {
  fill: #d86c79;
}
.webix_sparklines_area {
  fill: #e5eef4;
  opacity: 0.5;
}
.webix_sparklines_origin {
  stroke: #888;
  stroke-width: 1;
  fill: transparent;
}
.webix_sparklines_event_area {
  fill: transparent;
}
.webix_sparklines_donut_hole {
  fill: #FFFFFF;
}
.webix_sparklines_line_chart .webix_sparklines_event_area:hover,
.webix_sparklines_area_chart .webix_sparklines_event_area:hover,
.webix_sparklines_splinearea_chart .webix_sparklines_event_area:hover,
.webix_sparklines_radar_chart .webix_sparklines_event_area:hover {
  fill: rgba(220, 220, 220, 0.4);
  stroke: rgba(255, 255, 255, 0.6);
}
.webix_sparklines_pie_chart .webix_sparklines_event_area:hover,
.webix_sparklines_bar_chart .webix_sparklines_event_area:hover {
  fill: rgba(255, 255, 255, 0.3);
}
/* print css rules*/
.webix_ui_print,
.webix_print_pdf {
  display: none;
}
body.webix_print {
  margin-top: 0px;
}
@media print {
  body.webix_print {
    overflow: visible !important;
    background-color: none !important;
  }
  body.webix_print > * {
    display: none;
  }
  body.webix_print * {
    visibility: hidden;
  }
  .webix_ui_print {
    display: block !important;
    margin: 0 0 0 0!important;
    visibility: visible !important;
  }
  .webix_ui_print * {
    visibility: visible !important;
  }
  .webix_print_noscroll {
    height: auto !important;
    width: auto !important;
  }
  .webix_print_noscroll,
  .webix_print_noscroll .webix_scroll_cont {
    overflow: visible !important;
  }
  .webix_print_pagebreak {
    page-break-after: always;
  }
  .webix_print_header,
  .webix_print_footer {
    display: block !important;
    padding: 20px 0px;
    text-align: center;
    height: auto !important;
    visibility: visible !important;
  }
  .webix_print_header *,
  .webix_print_footer * {
    visibility: visible !important;
  }
  /*datatable specific*/
  .webix_table_print {
    display: table;
    visibility: visible !important;
    table-layout: fixed;
    width: 100%;
    border: 1px solid #ebebeb;
    margin-bottom: 20px;
    position: initial;
  }
  .webix_table_print td {
    overflow: hidden;
  }
  .webix_table_print tr {
    page-break-inside: avoid;
  }
  .webix_table_print .webix_print_top_split .webix_cell {
    border-bottom: 1px solid #d2d2d2;
  }
  .webix_table_print .webix_cell {
    display: table-cell !important;
    border-right: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
    padding: 0px 10px;
    white-space: nowrap;
  }
  .webix_table_print .webix_header_cell {
    display: table-cell;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #313131;
    letter-spacing: 0.2px;
    background: #F4F5F9;
    border-right: 1px solid #DADEE0;
    border-bottom: 1px solid #DADEE0;
  }
  .webix_table_print .webix_footer_cell {
    display: table-cell;
    background: #fafafa;
    border-right: 1px solid #EDEFF0;
    border-top: 1px solid #EDEFF0;
    border-bottom: 1px solid #EDEFF0;
  }
  .webix_table_print .webix_cell.webix_dtable_span {
    position: initial !important;
    white-space: normal;
  }
  .webix_table_print .webix_rotate {
    transform-origin: center 40% !important;
  }
  .webix_table_print.borderless,
  .webix_table_print.borderless .webix_cell {
    border: none;
  }
  /* x-list specific*/
  .webix_table_print.webix_view.webix_list-x {
    white-space: normal;
  }
  .webix_table_print.webix_view.webix_list-x .webix_list_item {
    display: table-cell;
  }
}
.webix_fullscreen_html .webix_template > * {
  width: 100% !important;
  height: 100% !important;
  margin: 0px !important;
}
.webix_icon,
.webix_icon_btn,
.webix_input_icon {
  display: inline-block;
  font-family: "Webix Material Icons";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}
.webix_icon,
.webix_input_icon {
  font-size: 17px;
  display: inline-block;
  width: 20px;
}
.webix_input_icon {
  float: right;
  font-size: 20px;
  color: #94A1B3;
}
.webix_icon_button {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  vertical-align: middle;
}
.webix_el_icon .webix_el_box {
  padding: 0;
  margin: 0 auto;
  text-align: center;
  position: relative;
  overflow: visible;
  cursor: pointer;
}
.webix_icon_button .webix_icon {
  font-size: 20px;
  height: 20px;
  width: 20px;
  display: inline-block;
  text-align: center;
  color: #94A1B3;
}
.webix_icon_btn {
  font-size: 20px;
  display: inline-block;
  text-align: center;
  width: 20px;
  margin-right: 4px;
  color: #94A1B3;
}
.webix_icon {
  text-align: center;
}
.webix_badge {
  background-color: #ff8839;
  color: #FFFFFF;
  border-radius: 11px;
  font-size: 12px;
  height: 22px;
  min-width: 22px;
  padding: 0 4px;
  text-align: center;
  line-height: 22px;
  position: absolute;
  right: 0;
  top: 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_badge.webix_measure_size {
  right: auto;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_list_item .webix_badge {
  position: static;
  float: right;
  margin: 4px -5px 0 12px;
}
@font-face {
  font-family: Roboto;
  src: url('fonts/Roboto-Regular-webfont.woff2') format('woff2'), url('fonts/Roboto-Regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Roboto;
  src: url('fonts/Roboto-Medium-webfont.woff2') format('woff2'), url('fonts/Roboto-Medium-webfont.woff') format('woff');
  font-style: normal;
  font-weight: 500;
}
.mainFont {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #475466;
  letter-spacing: 0.2px;
}
.buttonFont {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #1CA1C1;
  letter-spacing: 0px;
}
.selectFocusRule {
  background: #edeff5;
}
.layoutBarBG .webix_el_label .webix_el_box {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #475466;
  letter-spacing: 0px;
}
.layoutBarBG.webix_dark {
  background: #657584;
  border-color: #657584;
}
.layoutBarBG.webix_dark .webix_el_button,
.layoutBarBG.webix_dark .webix_el_label .webix_el_box,
.layoutBarBG.webix_dark .webix_inp_label,
.layoutBarBG.webix_dark .webix_inp_top_label,
.layoutBarBG.webix_dark .webix_slider_title {
  color: #FFFFFF;
}
.layoutBarBG.webix_dark .webix_secondary .webix_icon,
.layoutBarBG.webix_dark .webix_transparent .webix_icon,
.layoutBarBG.webix_dark .webix_secondary .webix_img_btn_text,
.layoutBarBG.webix_dark .webix_transparent .webix_img_btn_text,
.layoutBarBG.webix_dark .webix_secondary .webix_icon_btn,
.layoutBarBG.webix_dark .webix_transparent .webix_icon_btn {
  color: #FFFFFF;
}
.layoutBarBG.webix_dark .webix_transparent button {
  color: #FFFFFF;
}
.layoutBarBG.webix_dark .webix_transparent button:hover,
.layoutBarBG.webix_dark .webix_transparent button:focus {
  background-color: rgba(0, 0, 0, 0.2);
}
.layoutBarBG.webix_dark .webix_transparent button:active {
  background-color: rgba(0, 0, 0, 0.4);
}
.layoutBarBG.webix_dark .webix_secondary button,
.layoutBarBG.webix_dark .webix_inp_counter_next,
.layoutBarBG.webix_dark .webix_inp_counter_prev,
.layoutBarBG.webix_dark .webix_segment_0,
.layoutBarBG.webix_dark .webix_segment_1,
.layoutBarBG.webix_dark .webix_segment_N,
.layoutBarBG.webix_dark .webix_menu-x .webix_list_item {
  background-color: rgba(0, 0, 0, 0.2);
  color: #FFFFFF;
  border-color: transparent;
}
.layoutBarBG.webix_dark .webix_view > .webix_template {
  color: #FFFFFF;
  background-color: #657584;
}
.layoutBarBG.webix_dark .webix_secondary button:hover,
.layoutBarBG.webix_dark .webix_menu-x .webix_list_item:hover,
.layoutBarBG.webix_dark .webix_inp_counter_next:hover,
.layoutBarBG.webix_dark .webix_inp_counter_prev:hover,
.layoutBarBG.webix_dark .webix_segment_0:hover,
.layoutBarBG.webix_dark .webix_segment_1:hover,
.layoutBarBG.webix_dark .webix_segment_N:hover {
  color: #FFFFFF;
  background-color: rgba(0, 0, 0, 0.4);
}
.layoutBarBG.webix_dark .webix_secondary button:focus,
.layoutBarBG.webix_dark .webix_menu-x .webix_list_item:focus,
.layoutBarBG.webix_dark .webix_inp_counter_next:focus,
.layoutBarBG.webix_dark .webix_inp_counter_prev:focus,
.layoutBarBG.webix_dark .webix_segment_0:focus,
.layoutBarBG.webix_dark .webix_segment_1:focus,
.layoutBarBG.webix_dark .webix_segment_N:focus {
  background-color: rgba(0, 0, 0, 0.4);
  color: #FFFFFF;
}
.layoutBarBG.webix_dark .webix_secondary button:active,
.layoutBarBG.webix_dark .webix_menu-x .webix_list_item:active,
.layoutBarBG.webix_dark .webix_inp_counter_next:active,
.layoutBarBG.webix_dark .webix_inp_counter_prev:active,
.layoutBarBG.webix_dark .webix_segment_0:active,
.layoutBarBG.webix_dark .webix_segment_1:active,
.layoutBarBG.webix_dark .webix_segment_N:active {
  background-color: rgba(0, 0, 0, 0.5);
  color: #FFFFFF;
}
.layoutBarBG.webix_dark .webix_inp_static,
.layoutBarBG.webix_dark input[type=text],
.layoutBarBG.webix_dark input[type=combo],
.layoutBarBG.webix_dark input[type=datepicker],
.layoutBarBG.webix_dark textarea,
.layoutBarBG.webix_dark select,
.layoutBarBG.webix_dark .webix_text_highlight {
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.7);
}
.layoutBarBG.webix_dark .webix_el_texthighlight input,
.layoutBarBG.webix_dark .webix_el_texthighlight textarea {
  caret-color: rgba(255, 255, 255, 0.7);
}
.layoutBarBG.webix_dark .webix_inp_static input[type=text] {
  background-color: transparent;
}
.layoutBarBG.webix_dark select option {
  background-color: #657584;
  color: #FFFFFF;
}
.layoutBarBG.webix_dark .webix_multicombo_value,
.layoutBarBG.webix_dark .webix_multicombo_tag {
  color: #657584;
}
.layoutBarBG.webix_dark .webix_inp_static:focus,
.layoutBarBG.webix_dark input[type=text]:focus,
.layoutBarBG.webix_dark input[type=combo]:focus,
.layoutBarBG.webix_dark input[type=datepicker]:focus,
.layoutBarBG.webix_dark textarea:focus,
.layoutBarBG.webix_dark select:focus {
  border-color: #FFFFFF;
  box-shadow: none;
}
.layoutBarBG.webix_dark .webix_el_counter button {
  background-color: rgba(0, 0, 0, 0.3);
}
.layoutBarBG.webix_dark .webix_el_counter input[type=text]:focus {
  border-color: transparent;
}
.layoutBarBG.webix_dark .webix_placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.layoutBarBG.webix_dark ::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.layoutBarBG.webix_dark ::-moz-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.layoutBarBG.webix_dark :-moz-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.layoutBarBG.webix_dark :-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.layoutBarBG.webix_dark .webix_input_icon {
  color: rgba(255, 255, 255, 0.7);
}
.layoutBarBG.webix_dark .webix_selected.webix_segment_0,
.layoutBarBG.webix_dark .webix_selected.webix_segment_1,
.layoutBarBG.webix_dark .webix_selected.webix_segment_N {
  background: #1CA1C1;
  color: #FFFFFF;
}
.layoutBarBG.webix_dark .webix_selected.webix_segment_0:hover,
.layoutBarBG.webix_dark .webix_selected.webix_segment_1:hover,
.layoutBarBG.webix_dark .webix_selected.webix_segment_N:hover {
  background-color: #1992af;
}
.layoutBarBG.webix_dark .webix_selected.webix_segment_0:focus,
.layoutBarBG.webix_dark .webix_selected.webix_segment_1:focus,
.layoutBarBG.webix_dark .webix_selected.webix_segment_N:focus {
  background-color: #1992af;
}
.layoutBarBG.webix_dark .webix_selected.webix_segment_0:active,
.layoutBarBG.webix_dark .webix_selected.webix_segment_1:active,
.layoutBarBG.webix_dark .webix_selected.webix_segment_N:active {
  background-color: #17839d;
}
.layoutBarBG.webix_dark .webix_el_icon,
.layoutBarBG.webix_dark .webix_icon_button {
  color: #FFFFFF;
}
.layoutBarBG.webix_dark .webix_control {
  border-color: #1CA1C1;
}
.layoutBarBG.webix_dark .webix_icon_button:hover:before {
  background-color: rgba(0, 0, 0, 0.2);
}
.layoutBarBG.webix_dark .webix_icon_button:hover .webix_icon,
.layoutBarBG.webix_dark .webix_icon_button:focus .webix_icon {
  opacity: 1;
}
.layoutBarBG.webix_dark .webix_icon_button .webix_icon {
  color: #FFFFFF;
  opacity: 0.7;
}
.layoutBarBG.webix_dark .webix_custom_checkbox,
.layoutBarBG.webix_dark .webix_custom_radio {
  color: #FFFFFF;
}
.layoutBarBG.webix_dark .webix_custom_checkbox:focus,
.layoutBarBG.webix_dark .webix_custom_radio:focus {
  color: #f0f0f0;
}
.layoutBarBG.webix_dark .webix_label_right {
  color: #FFFFFF;
}
.layoutBarBG.webix_dark .webix_view.webix_control .webix_disabled_box .webix_button {
  color: rgba(255, 255, 255, 0.4);
  background-color: rgba(0, 0, 0, 0.1);
  text-shadow: none;
}
.layoutBarBG.webix_dark .webix_view.webix_control .webix_disabled_box .webix_img_btn_text {
  color: rgba(255, 255, 255, 0.4);
}
.layoutBarBG.webix_dark .webix_disabled_view.webix_control .webix_icon_btn,
.layoutBarBG.webix_dark .webix_disabled_view.webix_control .webix_icon {
  color: rgba(255, 255, 255, 0.4);
}
.layoutBarBG.webix_dark .webix_disabled_view.webix_menu-x .webix_list_item.menu {
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(255, 255, 255, 0.4);
}
.layoutBarBG.webix_dark .webix_toolbar .webix_disabled_view.webix_control .webix_icon,
.layoutBarBG.webix_dark .webix_toolbar .webix_disabled_view.webix_control .webix_disabled_box .webix_img_btn_text,
.layoutBarBG.webix_dark .webix_toolbar .webix_disabled_view.webix_control .webix_icon_btn {
  color: rgba(255, 255, 255, 0.4);
}
.layoutBarBG.webix_dark .webix_view.webix_control .webix_disabled_box label,
.layoutBarBG.webix_dark .webix_view.webix_control .webix_disabled_box .webix_input_icon,
.layoutBarBG.webix_dark .webix_view.webix_control .webix_disabled_box button {
  color: rgba(255, 255, 255, 0.4);
}
.layoutBarBG.webix_dark .webix_view.webix_control .webix_disabled_box input,
.layoutBarBG.webix_dark .webix_view.webix_control .webix_disabled_box select,
.layoutBarBG.webix_dark .webix_view.webix_control .webix_disabled_box textarea,
.layoutBarBG.webix_dark .webix_view.webix_control .webix_disabled_box .webix_inp_static,
.layoutBarBG.webix_dark .webix_view.webix_control .webix_disabled_box .webix_inp_counter_prev,
.layoutBarBG.webix_dark .webix_view.webix_control .webix_disabled_box .webix_inp_counter_next {
  color: rgba(255, 255, 255, 0.4);
  background-color: rgba(0, 0, 0, 0.1);
  border-color: transparent;
}
.layoutBarBG.webix_dark .webix_view.webix_control .webix_disabled_box .webix_inp_static input {
  background-color: transparent;
}
.layoutBarBG.webix_dark .webix_view.webix_control .webix_disabled_box .webix_segment_0,
.layoutBarBG.webix_dark .webix_view.webix_control .webix_disabled_box .webix_segment_1,
.layoutBarBG.webix_dark .webix_view.webix_control .webix_disabled_box .webix_segment_N,
.layoutBarBG.webix_dark .webix_view.webix_control .webix_disabled_box .webix_item_tab {
  color: rgba(255, 255, 255, 0.4);
  background-color: rgba(0, 0, 0, 0.1);
  text-shadow: none;
}
.layoutBarBG.webix_dark .webix_view.webix_control .webix_disabled_box .webix_segment_0.webix_selected,
.layoutBarBG.webix_dark .webix_view.webix_control .webix_disabled_box .webix_segment_1.webix_selected,
.layoutBarBG.webix_dark .webix_view.webix_control .webix_disabled_box .webix_segment_N.webix_selected {
  background-color: rgba(0, 0, 0, 0.15);
}
.layoutBarBG.webix_dark .webix_disabled_top_label {
  color: rgba(255, 255, 255, 0.4);
}
.layoutBarBG.webix_dark .webix_invalid .webix_el_box input,
.layoutBarBG.webix_dark .webix_invalid .webix_el_box .webix_inp_static,
.layoutBarBG.webix_dark .webix_invalid .webix_el_box select,
.layoutBarBG.webix_dark .webix_invalid .webix_el_box textarea,
.layoutBarBG.webix_dark input.webix_invalid,
.layoutBarBG.webix_dark select.webix_invalid,
.layoutBarBG.webix_dark textarea.webix_invalid {
  color: #ffdedb;
  background-color: rgba(255, 92, 76, 0.3);
  border-color: #ff8d82;
}
.layoutBarBG.webix_dark .webix_invalid .webix_multicombo_value,
.layoutBarBG.webix_dark .webix_invalid .webix_multicombo_tag {
  color: #FF5C4C;
}
.layoutBarBG.webix_dark .webix_invalid .webix_el_box .webix_inp_static input {
  background-color: transparent;
}
.webix_toolbar .webix_el_label .webix_el_box {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #475466;
  letter-spacing: 0px;
}
.webix_toolbar.webix_dark {
  background: #657584;
  border-color: #657584;
}
.webix_toolbar.webix_dark .webix_el_button,
.webix_toolbar.webix_dark .webix_el_label .webix_el_box,
.webix_toolbar.webix_dark .webix_inp_label,
.webix_toolbar.webix_dark .webix_inp_top_label,
.webix_toolbar.webix_dark .webix_slider_title {
  color: #FFFFFF;
}
.webix_toolbar.webix_dark .webix_secondary .webix_icon,
.webix_toolbar.webix_dark .webix_transparent .webix_icon,
.webix_toolbar.webix_dark .webix_secondary .webix_img_btn_text,
.webix_toolbar.webix_dark .webix_transparent .webix_img_btn_text,
.webix_toolbar.webix_dark .webix_secondary .webix_icon_btn,
.webix_toolbar.webix_dark .webix_transparent .webix_icon_btn {
  color: #FFFFFF;
}
.webix_toolbar.webix_dark .webix_transparent button {
  color: #FFFFFF;
}
.webix_toolbar.webix_dark .webix_transparent button:hover,
.webix_toolbar.webix_dark .webix_transparent button:focus {
  background-color: rgba(0, 0, 0, 0.2);
}
.webix_toolbar.webix_dark .webix_transparent button:active {
  background-color: rgba(0, 0, 0, 0.4);
}
.webix_toolbar.webix_dark .webix_secondary button,
.webix_toolbar.webix_dark .webix_inp_counter_next,
.webix_toolbar.webix_dark .webix_inp_counter_prev,
.webix_toolbar.webix_dark .webix_segment_0,
.webix_toolbar.webix_dark .webix_segment_1,
.webix_toolbar.webix_dark .webix_segment_N,
.webix_toolbar.webix_dark .webix_menu-x .webix_list_item {
  background-color: rgba(0, 0, 0, 0.2);
  color: #FFFFFF;
  border-color: transparent;
}
.webix_toolbar.webix_dark .webix_view > .webix_template {
  color: #FFFFFF;
  background-color: #657584;
}
.webix_toolbar.webix_dark .webix_secondary button:hover,
.webix_toolbar.webix_dark .webix_menu-x .webix_list_item:hover,
.webix_toolbar.webix_dark .webix_inp_counter_next:hover,
.webix_toolbar.webix_dark .webix_inp_counter_prev:hover,
.webix_toolbar.webix_dark .webix_segment_0:hover,
.webix_toolbar.webix_dark .webix_segment_1:hover,
.webix_toolbar.webix_dark .webix_segment_N:hover {
  color: #FFFFFF;
  background-color: rgba(0, 0, 0, 0.4);
}
.webix_toolbar.webix_dark .webix_secondary button:focus,
.webix_toolbar.webix_dark .webix_menu-x .webix_list_item:focus,
.webix_toolbar.webix_dark .webix_inp_counter_next:focus,
.webix_toolbar.webix_dark .webix_inp_counter_prev:focus,
.webix_toolbar.webix_dark .webix_segment_0:focus,
.webix_toolbar.webix_dark .webix_segment_1:focus,
.webix_toolbar.webix_dark .webix_segment_N:focus {
  background-color: rgba(0, 0, 0, 0.4);
  color: #FFFFFF;
}
.webix_toolbar.webix_dark .webix_secondary button:active,
.webix_toolbar.webix_dark .webix_menu-x .webix_list_item:active,
.webix_toolbar.webix_dark .webix_inp_counter_next:active,
.webix_toolbar.webix_dark .webix_inp_counter_prev:active,
.webix_toolbar.webix_dark .webix_segment_0:active,
.webix_toolbar.webix_dark .webix_segment_1:active,
.webix_toolbar.webix_dark .webix_segment_N:active {
  background-color: rgba(0, 0, 0, 0.5);
  color: #FFFFFF;
}
.webix_toolbar.webix_dark .webix_inp_static,
.webix_toolbar.webix_dark input[type=text],
.webix_toolbar.webix_dark input[type=combo],
.webix_toolbar.webix_dark input[type=datepicker],
.webix_toolbar.webix_dark textarea,
.webix_toolbar.webix_dark select,
.webix_toolbar.webix_dark .webix_text_highlight {
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.7);
}
.webix_toolbar.webix_dark .webix_el_texthighlight input,
.webix_toolbar.webix_dark .webix_el_texthighlight textarea {
  caret-color: rgba(255, 255, 255, 0.7);
}
.webix_toolbar.webix_dark .webix_inp_static input[type=text] {
  background-color: transparent;
}
.webix_toolbar.webix_dark select option {
  background-color: #657584;
  color: #FFFFFF;
}
.webix_toolbar.webix_dark .webix_multicombo_value,
.webix_toolbar.webix_dark .webix_multicombo_tag {
  color: #657584;
}
.webix_toolbar.webix_dark .webix_inp_static:focus,
.webix_toolbar.webix_dark input[type=text]:focus,
.webix_toolbar.webix_dark input[type=combo]:focus,
.webix_toolbar.webix_dark input[type=datepicker]:focus,
.webix_toolbar.webix_dark textarea:focus,
.webix_toolbar.webix_dark select:focus {
  border-color: #FFFFFF;
  box-shadow: none;
}
.webix_toolbar.webix_dark .webix_el_counter button {
  background-color: rgba(0, 0, 0, 0.3);
}
.webix_toolbar.webix_dark .webix_el_counter input[type=text]:focus {
  border-color: transparent;
}
.webix_toolbar.webix_dark .webix_placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.webix_toolbar.webix_dark ::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.webix_toolbar.webix_dark ::-moz-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.webix_toolbar.webix_dark :-moz-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.webix_toolbar.webix_dark :-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.webix_toolbar.webix_dark .webix_input_icon {
  color: rgba(255, 255, 255, 0.7);
}
.webix_toolbar.webix_dark .webix_selected.webix_segment_0,
.webix_toolbar.webix_dark .webix_selected.webix_segment_1,
.webix_toolbar.webix_dark .webix_selected.webix_segment_N {
  background: #1CA1C1;
  color: #FFFFFF;
}
.webix_toolbar.webix_dark .webix_selected.webix_segment_0:hover,
.webix_toolbar.webix_dark .webix_selected.webix_segment_1:hover,
.webix_toolbar.webix_dark .webix_selected.webix_segment_N:hover {
  background-color: #1992af;
}
.webix_toolbar.webix_dark .webix_selected.webix_segment_0:focus,
.webix_toolbar.webix_dark .webix_selected.webix_segment_1:focus,
.webix_toolbar.webix_dark .webix_selected.webix_segment_N:focus {
  background-color: #1992af;
}
.webix_toolbar.webix_dark .webix_selected.webix_segment_0:active,
.webix_toolbar.webix_dark .webix_selected.webix_segment_1:active,
.webix_toolbar.webix_dark .webix_selected.webix_segment_N:active {
  background-color: #17839d;
}
.webix_toolbar.webix_dark .webix_el_icon,
.webix_toolbar.webix_dark .webix_icon_button {
  color: #FFFFFF;
}
.webix_toolbar.webix_dark .webix_control {
  border-color: #1CA1C1;
}
.webix_toolbar.webix_dark .webix_icon_button:hover:before {
  background-color: rgba(0, 0, 0, 0.2);
}
.webix_toolbar.webix_dark .webix_icon_button:hover .webix_icon,
.webix_toolbar.webix_dark .webix_icon_button:focus .webix_icon {
  opacity: 1;
}
.webix_toolbar.webix_dark .webix_icon_button .webix_icon {
  color: #FFFFFF;
  opacity: 0.7;
}
.webix_toolbar.webix_dark .webix_custom_checkbox,
.webix_toolbar.webix_dark .webix_custom_radio {
  color: #FFFFFF;
}
.webix_toolbar.webix_dark .webix_custom_checkbox:focus,
.webix_toolbar.webix_dark .webix_custom_radio:focus {
  color: #f0f0f0;
}
.webix_toolbar.webix_dark .webix_label_right {
  color: #FFFFFF;
}
.webix_toolbar.webix_dark .webix_view.webix_control .webix_disabled_box .webix_button {
  color: rgba(255, 255, 255, 0.4);
  background-color: rgba(0, 0, 0, 0.1);
  text-shadow: none;
}
.webix_toolbar.webix_dark .webix_view.webix_control .webix_disabled_box .webix_img_btn_text {
  color: rgba(255, 255, 255, 0.4);
}
.webix_toolbar.webix_dark .webix_disabled_view.webix_control .webix_icon_btn,
.webix_toolbar.webix_dark .webix_disabled_view.webix_control .webix_icon {
  color: rgba(255, 255, 255, 0.4);
}
.webix_toolbar.webix_dark .webix_disabled_view.webix_menu-x .webix_list_item.menu {
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(255, 255, 255, 0.4);
}
.webix_toolbar.webix_dark .webix_toolbar .webix_disabled_view.webix_control .webix_icon,
.webix_toolbar.webix_dark .webix_toolbar .webix_disabled_view.webix_control .webix_disabled_box .webix_img_btn_text,
.webix_toolbar.webix_dark .webix_toolbar .webix_disabled_view.webix_control .webix_icon_btn {
  color: rgba(255, 255, 255, 0.4);
}
.webix_toolbar.webix_dark .webix_view.webix_control .webix_disabled_box label,
.webix_toolbar.webix_dark .webix_view.webix_control .webix_disabled_box .webix_input_icon,
.webix_toolbar.webix_dark .webix_view.webix_control .webix_disabled_box button {
  color: rgba(255, 255, 255, 0.4);
}
.webix_toolbar.webix_dark .webix_view.webix_control .webix_disabled_box input,
.webix_toolbar.webix_dark .webix_view.webix_control .webix_disabled_box select,
.webix_toolbar.webix_dark .webix_view.webix_control .webix_disabled_box textarea,
.webix_toolbar.webix_dark .webix_view.webix_control .webix_disabled_box .webix_inp_static,
.webix_toolbar.webix_dark .webix_view.webix_control .webix_disabled_box .webix_inp_counter_prev,
.webix_toolbar.webix_dark .webix_view.webix_control .webix_disabled_box .webix_inp_counter_next {
  color: rgba(255, 255, 255, 0.4);
  background-color: rgba(0, 0, 0, 0.1);
  border-color: transparent;
}
.webix_toolbar.webix_dark .webix_view.webix_control .webix_disabled_box .webix_inp_static input {
  background-color: transparent;
}
.webix_toolbar.webix_dark .webix_view.webix_control .webix_disabled_box .webix_segment_0,
.webix_toolbar.webix_dark .webix_view.webix_control .webix_disabled_box .webix_segment_1,
.webix_toolbar.webix_dark .webix_view.webix_control .webix_disabled_box .webix_segment_N,
.webix_toolbar.webix_dark .webix_view.webix_control .webix_disabled_box .webix_item_tab {
  color: rgba(255, 255, 255, 0.4);
  background-color: rgba(0, 0, 0, 0.1);
  text-shadow: none;
}
.webix_toolbar.webix_dark .webix_view.webix_control .webix_disabled_box .webix_segment_0.webix_selected,
.webix_toolbar.webix_dark .webix_view.webix_control .webix_disabled_box .webix_segment_1.webix_selected,
.webix_toolbar.webix_dark .webix_view.webix_control .webix_disabled_box .webix_segment_N.webix_selected {
  background-color: rgba(0, 0, 0, 0.15);
}
.webix_toolbar.webix_dark .webix_disabled_top_label {
  color: rgba(255, 255, 255, 0.4);
}
.webix_toolbar.webix_dark .webix_invalid .webix_el_box input,
.webix_toolbar.webix_dark .webix_invalid .webix_el_box .webix_inp_static,
.webix_toolbar.webix_dark .webix_invalid .webix_el_box select,
.webix_toolbar.webix_dark .webix_invalid .webix_el_box textarea,
.webix_toolbar.webix_dark input.webix_invalid,
.webix_toolbar.webix_dark select.webix_invalid,
.webix_toolbar.webix_dark textarea.webix_invalid {
  color: #ffdedb;
  background-color: rgba(255, 92, 76, 0.3);
  border-color: #ff8d82;
}
.webix_toolbar.webix_dark .webix_invalid .webix_multicombo_value,
.webix_toolbar.webix_dark .webix_invalid .webix_multicombo_tag {
  color: #FF5C4C;
}
.webix_toolbar.webix_dark .webix_invalid .webix_el_box .webix_inp_static input {
  background-color: transparent;
}
.webix_layout_space > .webix_toolbar {
  border-radius: 2px;
}
.webix_view.webix_layout_accordion {
  background-color: #EBEDF0;
}
.webix_accordionitem .webix_accordionitem_button {
  float: right;
  position: relative;
  height: 19px;
  width: 19px;
  margin: 13.5px 13px;
}
.webix_accordionitem .webix_accordionitem_header {
  line-height: 44px;
}
.webix_accordionitem .webix_accordionitem_header:hover,
.webix_accordionitem .webix_accordionitem_header:focus {
  background-color: #fafafa;
}
.webix_accordionitem.collapsed .webix_accordionitem_button {
  float: right;
  position: relative;
  margin-top: 12.5px;
}
.webix_accordionitem.horizontal.collapsed,
.webix_accordionitem.collapsed {
  background-color: #F4F5F9;
}
.webix_accordionitem.horizontal.collapsed .webix_accordionitem_header,
.webix_accordionitem.collapsed .webix_accordionitem_header {
  background-color: #F4F5F9;
  color: #1CA1C1;
}
.webix_accordionitem.horizontal.collapsed .webix_accordionitem_header .webix_accordionitem_button,
.webix_accordionitem.collapsed .webix_accordionitem_header .webix_accordionitem_button {
  color: #1CA1C1;
}
.webix_accordionitem.horizontal.collapsed .webix_accordionitem_header .webix_accordionitem_label .webix_icon,
.webix_accordionitem.collapsed .webix_accordionitem_header .webix_accordionitem_label .webix_icon {
  color: #1CA1C1;
}
.webix_accordionitem.horizontal.collapsed .webix_accordionitem_header:hover,
.webix_accordionitem.collapsed .webix_accordionitem_header:hover,
.webix_accordionitem.horizontal.collapsed .webix_accordionitem_header:focus,
.webix_accordionitem.collapsed .webix_accordionitem_header:focus {
  background-color: #edeff5;
}
.webix_accordionitem_label {
  padding-left: 12px;
}
.webix_layout_accordion.webix_dark .webix_accordionitem_header {
  background-color: #657584;
  color: #FFFFFF;
}
.webix_layout_accordion.webix_dark .webix_accordionitem_header .webix_accordionitem_button {
  color: #FFFFFF;
}
.webix_layout_accordion.webix_dark .webix_accordionitem_header.collapsed {
  background-color: #F4F5F9;
  color: #1CA1C1;
}
.webix_layout_accordion.webix_dark .webix_accordionitem_header.collapsed .webix_accordionitem_button {
  color: #1CA1C1;
}
.webix_layout_accordion.webix_dark .webix_accordionitem_header:hover,
.webix_layout_accordion.webix_dark .webix_accordionitem_header:focus {
  background-color: #61707e;
}
.webix_layout_accordion.webix_dark .webix_accordionitem.horizontal.collapsed .webix_accordionitem_header:hover,
.webix_layout_accordion.webix_dark .webix_accordionitem.collapsed .webix_accordionitem_header:hover,
.webix_layout_accordion.webix_dark .webix_accordionitem.horizontal.collapsed .webix_accordionitem_header:focus,
.webix_layout_accordion.webix_dark .webix_accordionitem.collapsed .webix_accordionitem_header:focus {
  background-color: #edeff5;
}
.webix_accordionitem .webix_accordionitem_header .webix_accordionitem_button {
  color: #94A1B3;
  background-image: none;
  font-family: "Webix Material Icons";
  font-size: 24px;
  text-align: center;
  width: 24px;
  height: 43px;
  margin: 0 10px 0 10px;
}
.webix_accordionitem .webix_accordionitem_header .webix_accordionitem_label .webix_icon {
  color: #94A1B3;
}
.webix_accordionitem .webix_accordionitem_header .webix_accordionitem_button:before {
  content: '\F002';
}
.webix_accordionitem .webix_accordionitem_header.collapsed .webix_accordionitem_button:before {
  content: '\F004';
}
.webix_accordionitem.horizontal:last-child > .webix_accordionitem_header .webix_accordionitem_button:before {
  content: '\F003';
}
.webix_accordionitem.horizontal.collapsed:last-child > .webix_accordionitem_header .webix_accordionitem_button:before {
  content: '\F001';
}
.webix_accordionitem.vertical > .webix_accordionitem_header .webix_accordionitem_button {
  height: 43px;
  margin: 0px 10px 0 10px;
}
.webix_accordionitem.vertical > .webix_accordionitem_header .webix_accordionitem_button:before {
  content: '\F004';
}
.webix_accordionitem.vertical > .webix_accordionitem_header.collapsed .webix_accordionitem_button:before {
  content: '\F001';
}
.webix_toolbar.webix_layout_subbar {
  background: transparent;
}
.webix_toolbar.webix_layout_subbar .webix_view {
  color: #475466;
}
.webix_el_tabbar + .webix_multiview {
  background-color: #FFFFFF;
}
.webix_layout_space > .webix_view,
.webix_layout_wide > .webix_view {
  border-radius: 2px;
}
.webix_layout_space .webix_layout_accordion .webix_accordionitem,
.webix_layout_wide .webix_layout_accordion .webix_accordionitem {
  border-radius: 2px;
}
.webix_popup_button {
  border-color: #F4F5F9;
}
.webix_popup_button.confirm {
  border-color: #1CA1C1;
}
.webix_popup_button div {
  border-radius: 0;
}
.dataHeaderFont {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #313131;
  letter-spacing: 0.2px;
}
.webix_dtable .webix_ss_header .webix_hcell,
.webix_dtable .webix_ss_footer .webix_hcell {
  border-color: transparent;
}
.webix_dtable .webix_ss_body .webix_column > div,
.webix_dtable .webix_ss_body .webix_table_cell {
  border-right-color: transparent;
  border-left-color: transparent;
}
.webix_dtable.webix_header_border .webix_ss_header .webix_hcolumn > div,
.webix_dtable.webix_header_border .webix_ss_header .webix_span {
  border-right-color: #DADEE0;
  border-bottom-color: #DADEE0;
}
.webix_dtable.webix_footer_border .webix_ss_footer .webix_hcolumn > div,
.webix_dtable.webix_footer_border .webix_ss_footer .webix_span {
  border-right-color: #DADEE0;
  border-top-color: #DADEE0;
}
.webix_dtable.webix_data_border .webix_ss_body .webix_column > div,
.webix_dtable.webix_data_border .webix_table_cell {
  border-right-color: #EDEFF0;
}
.webix_dtable .webix_ss_left .webix_column.webix_last > div,
.webix_dtable .webix_table_cell.webix_last_rightcell {
  border-right: 1px solid #ccd2d4;
}
.webix_dtable div.webix_last_topcell {
  border-bottom: 1px solid #ccd2d4;
}
.webix_dtable .webix_ss_right .webix_column.webix_first > div {
  border-left: 1px solid #ccd2d4;
}
.webix_dtable .webix_hs_right .webix_hcell.webix_first {
  border-left: 1px solid #DADEE0;
}
.webix_dtable .webix_hs_left .webix_hcell.webix_last {
  border-right: 1px solid #DADEE0;
}
.webix_dtable .webix_hcell.webix_ss_filter {
  padding: 0px 4px;
}
.webix_dtable .webix_hcell.webix_div_filter {
  padding: 1px 4px;
}
.webix_dtable .webix_dtable_subview {
  background: #FFFFFF;
}
.webix_dtable .webix_dtable_subview .webix_view {
  border-left-width: 1px;
  border-right-width: 1px;
  border-color: #EDEFF0;
}
.webix_dtable .webix_ss_filter select,
.webix_dtable .webix_ss_filter input {
  height: 32px;
  vertical-align: middle;
  border-radius: 2px;
  border-color: #CCD7E6;
}
.webix_dtable .webix_ss_filter select:focus,
.webix_dtable .webix_ss_filter input:focus {
  border-color: #1CA1C1;
}
.webix_dtable .webix_select_mark > div.webix_row_select {
  box-shadow: inset 2px 0 #1CA1C1;
}
.webix_dtable .webix_ss_header .webix_hcolumn div.webix_last_row,
.webix_dtable .webix_ss_header .webix_span.webix_last_row {
  border-bottom: 1px solid #5ccce7;
}
.webix_dtable .webix_ss_footer .webix_hcolumn > div:first-child,
.webix_dtable .webix_ss_footer .webix_hcell[row="0"] {
  border-top: 1px solid #5ccce7;
}
.webix_dtable .webix_ss_footer,
.webix_dtable .webix_ss_footer .webix_hcolumn,
.webix_dtable .webix_ss_footer .webix_hs_left,
.webix_dtable .webix_ss_footer .webix_hs_center,
.webix_dtable .webix_ss_footer .webix_hs_right,
.webix_dtable .webix_ss_footer div.webix_ss_vscroll_footer {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #313131;
  letter-spacing: 0.2px;
  background: #F4F5F9;
}
.webix_dtable .webix_column > .webix_drag_over.webix_cell {
  background-color: #F4F5F9;
}
.webix_block_selection {
  border: none;
  background: #1CA1C1;
  opacity: 0.1;
}
.webix_area_selection,
.webix_area_selection_handle {
  background: #1CA1C1;
}
.webix_dtable_focused .webix_area_selection,
.webix_dtable_focused .webix_area_selection_handle {
  background-color: #1992af;
}
.dtBodyCell {
  border-bottom: 1px solid #EDEFF0;
}
.webix_dtable div.webix_ss_vscroll_header {
  border-bottom-color: #5ccce7;
}
.webix_dtable div.webix_ss_vscroll_footer {
  border-top-color: #5ccce7;
  border-left-color: #DADEE0;
}
.webix_view .webix_dt_editor input,
.webix_view .webix_dt_editor select {
  border-color: #1CA1C1;
  border-radius: 2px;
}
.dataItemStyle {
  border-color: #EDEFF0;
}
.webix_list_item.webix_group_back {
  color: #313131;
  background: #F4F5F9;
  border-bottom: 1px solid #DADEE0;
}
.webix_dataview_item {
  border-right-color: #EDEFF0;
  line-height: 24px;
}
.webix_dataview_item.webix_selected {
  box-shadow: none;
}
.webix_dataview_item.tiles.webix_selected {
  border-color: #5ccce7;
}
.webix_view .webix_pager_item {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  outline: none;
  background: #F4F5F9;
  color: #1CA1C1;
}
.webix_view .webix_pager_item:hover {
  background-color: #e7e9f2;
}
.webix_view .webix_pager_item:focus {
  background-color: #e7e9f2;
}
.webix_view .webix_pager_item:active {
  background-color: #daddeb;
}
.webix_view .webix_pager_item_selected {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #1CA1C1;
  letter-spacing: 0px;
  outline: none;
  background: #1CA1C1;
  color: #FFFFFF;
}
.webix_view .webix_pager_item_selected:hover {
  background-color: #1992af;
}
.webix_view .webix_pager_item_selected:focus {
  background-color: #1992af;
}
.webix_view .webix_pager_item_selected:active {
  background-color: #17839d;
}
.webix_view .webix_pager_item,
.webix_view .webix_pager_item_selected {
  border-radius: 2px;
  border-width: 0px;
}
.webix_list .webix_unit_header {
  font-weight: normal;
  text-shadow: none;
  border-bottom: 1px solid #DADEE0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #F4F5F9;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #313131;
  letter-spacing: 0.2px;
}
.webix_list .webix_unit_header:not(:first-child) {
  border-top: 1px solid #DADEE0;
  margin-top: -1px;
}
.webix_tree .webix_tree_item.webix_selected {
  background-color: #F4F5F9;
}
.webix_tree .webix_tree_item.webix_selected:focus {
  background-color: #edeff5;
}
.webix_tree .webix_tree_item.webix_selected span {
  background-color: transparent;
  padding: 0;
  margin: 0;
}
.webix_tree .webix_tree_item.webix_selected div {
  background-color: transparent;
}
.webix_tree .webix_tree_item,
.webix_tree .webix_tree_item.webix_selected {
  padding-right: 12px;
}
.webix_tree_open,
.webix_tree_close {
  background-image: none;
  font-family: "Webix Material Icons";
  font-size: 24px;
  text-align: center;
  color: #94A1B3;
}
.webix_tree_open:before {
  margin-left: -2px;
  content: "\F027";
}
.webix_tree_close:before {
  margin-left: -2px;
  content: "\F028";
}
.webix_tree_folder,
.webix_tree_folder_open,
.webix_tree_file {
  background-image: none;
  font-family: "Webix Material Icons";
  font-size: 20px;
  margin: 0 4px 0 0;
  width: 24px;
  text-align: center;
  color: #DADEE0;
}
.webix_tree_folder:before {
  content: "\F030";
}
.webix_tree_folder_open:before {
  content: "\F031";
}
.webix_tree_file:before {
  content: "\F032";
}
.webix_menu-x .webix_list_item .webix_submenu_icon,
.webix_view.webix_menu .webix_list_item .webix_submenu_icon {
  font-size: 14px;
  font-family: "Webix Material Icons";
  width: 6px;
  top: 0;
  margin-left: 0;
}
.webix_view.webix_popup.webix_menu {
  border: none;
}
.webix_menu.webix_view .webix_list_item:hover,
.webix_menu.webix_view .webix_list_item:active {
  background-color: #edeff5;
}
.webix_menu.webix_view .webix_list_item .webix_submenu_icon:before {
  content: '\F003';
}
.webix_menu.webix_view .webix_list_item.webix_selected {
  background: #F4F5F9;
}
.webix_menu.webix_view .webix_list_item.webix_disabled {
  background-color: #FFFFFF;
}
.webix_menu.webix_view .webix_icon {
  color: #94A1B3;
}
.webix_property .webix_property_label_line {
  background-color: #F4F5F9;
}
.webix_drag_over,
.webix_drop_zone {
  background-color: #F4F5F9;
}
.webix_view button,
.webix_view input[type=button] {
  -webkit-appearance: none;
}
.focusStyle {
  border: 1px solid #1CA1C1;
}
.webix_el_search input,
.webix_el_combo input,
.webix_el_colorpicker input {
  padding-right: 28px;
}
.webix_el_search input,
.webix_el_text input,
.webix_el_combo input,
.webix_el_colorpicker input,
.webix_el_datepicker input {
  border-radius: 2px;
}
.webix_el_search input:focus,
.webix_el_text input:focus,
.webix_el_combo input:focus,
.webix_el_colorpicker input:focus,
.webix_el_datepicker input:focus {
  border: 1px solid #1CA1C1;
}
.webix_el_richselect .webix_inp_static,
.webix_el_datepicker .webix_inp_static,
.webix_el_colorpicker .webix_inp_static {
  border-radius: 2px;
  padding-right: 28px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.webix_el_richselect .webix_inp_static:focus,
.webix_el_datepicker .webix_inp_static:focus,
.webix_el_colorpicker .webix_inp_static:focus {
  border: 1px solid #1CA1C1;
}
.webix_el_textarea textarea:focus {
  border: 1px solid #1CA1C1;
}
.webix_el_select select:focus {
  border: 1px solid #1CA1C1;
}
.webix_el_select select,
.webix_el_textarea textarea {
  border-radius: 2px;
}
.webix_el_text .webix_inp_static {
  border-radius: 2px;
  white-space: nowrap;
}
.webix_focused .webix_inp_static {
  border: 1px solid #1CA1C1;
}
.webix_multilist .wxi-checkbox-marked {
  color: #1CA1C1;
}
.webix_multilist .wxi-checkbox-blank {
  color: #94A1B3;
}
.webix_multicombo_value,
.webix_multicombo_tag,
.webix_multicombo_value.webix_readonly {
  background-color: #EBEDF0;
  border-radius: 100px;
  padding: 0 12px 0 12px;
}
.webix_multicombo_value:has(> .webix_multicombo_delete) {
  padding: 0 26px 0 12px;
}
.webix_multicombo_delete {
  color: #94A1B3;
  margin-top: -10px;
  font-size: 20px;
  height: 20px;
}
.webix_multicombo_delete::after {
  content: "\F033";
}
.webix_checksuggest_select_all:hover {
  background-color: #F4F5F9;
  border-radius: 2px;
}
.webix_checksuggest_select_all:hover .webix_label_right {
  color: #1CA1C1;
}
.webix_checksuggest_select_all .webix_label_right {
  text-align: center;
  font-size: 16px;
  color: #1CA1C1;
}
.webix_checksuggest_select_all.webix_el_checkbox .webix_label_right {
  padding-top: 5px;
}
.buttonStyle {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #1CA1C1;
  letter-spacing: 0px;
  outline: none;
}
.webix_el_colorpicker div.webix_inp_static div {
  margin: 2.5px -20px 0px -5px;
}
div.webix_inp_static,
.webix_el_textarea textarea {
  background: #ffffff;
  font-family: Roboto, sans-serif;
  border-color: #CCD7E6;
}
.webix_el_select select {
  font-family: Roboto, sans-serif;
  border-color: #DADEE0;
}
.webix_el_checkbox input {
  border-color: #DADEE0;
}
.webix_el_counter .webix_inp_counter_value {
  width: 42px;
  background: #ffffff;
  font-family: Roboto, sans-serif;
  border-color: #CCD7E6;
}
.webix_el_counter .webix_inp_counter_prev,
.webix_el_counter .webix_inp_counter_next {
  background: #F4F5F9;
  width: 30px;
  border: 1px solid #CCD7E6;
  font-weight: bold;
  font-family: "Courier New", Courier, monospace;
}
.webix_el_counter .webix_inp_counter_prev {
  font-size: 20px;
  border-right: none;
}
.webix_el_counter .webix_inp_counter_next {
  font-size: 20px;
  border-left: none;
}
.webix_view.webix_control.webix_el_tabbar {
  background: #FFFFFF;
}
.webix_all_tabs .webix_item_tab {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #1CA1C1;
  letter-spacing: 0px;
  border: none;
}
.webix_all_tabs .webix_item_tab:hover {
  background-color: #F4F5F9;
}
.webix_all_tabs .webix_item_tab .webix_icon.webix_tab_close {
  width: 28px;
  font-size: 20px;
  padding: 0;
  margin-top: 1px;
  color: #94A1B3;
}
.webix_all_tabs .webix_item_tab .webix_icon.webix_tab_close:focus {
  color: #FF5C4C;
}
.webix_all_tabs .webix_item_tab.webix_selected {
  box-shadow: inset 0 -2px #1CA1C1;
  padding-bottom: 0;
}
.webix_all_tabs .webix_item_tab.webix_selected:hover {
  background-color: transparent;
}
.webix_all_tabs .webix_item_tab.webix_selected:focus {
  color: #17839d;
  box-shadow: inset 0 -2px #17839d;
}
.webix_all_tabs .webix_item_tab.webix_selected.webix_disabled {
  box-shadow: inset 0 -2px #94A1B3;
}
.webix_all_tabs .webix_tab_filler {
  border: none;
}
.webix_all_tabs .webix_tab_more_icon .webix_icon {
  color: #1CA1C1;
}
.webix_all_tabs .webix_tab_more_icon:hover,
.webix_all_tabs .webix_tab_more_icon:focus {
  background-color: #F4F5F9;
}
.webixtype_bottom .webix_item_tab,
.webixtype_bottom .webix_item_tab.webix_selected {
  background: #FFFFFF;
  padding: 0;
}
.webixtype_bottom .webix_item_tab:last-child,
.webixtype_bottom .webix_item_tab.webix_selected:last-child {
  border-right: none;
}
.webixtype_bottom .webix_item_tab.webix_selected {
  box-shadow: inset 0 2px #1CA1C1;
  background: #FFFFFF;
  color: #1CA1C1;
}
.webixtype_bottom .webix_item_tab.webix_selected:focus {
  box-shadow: inset 0 2px #17839d;
}
.webixtype_bottom .webix_item_tab.webix_selected.webix_disabled {
  box-shadow: inset 0 2px #94A1B3;
}
.webix_el_tabbar .webixtype_icon .webix_img_btn {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 auto;
  text-align: center;
}
.webix_el_tabbar .webixtype_icon .webix_img_btn .webix_icon_btn {
  color: #1CA1C1;
}
.webix_el_tabbar .webixtype_icon .webix_img_btn .webix_icon_btn {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 24px;
  margin: 0 3px;
  height: 100%;
  line-height: inherit;
  vertical-align: top;
  width: 100%;
}
.webix_el_tabbar .webixtype_icon .webix_item_tab.webix_selected {
  background: #FFFFFF;
}
.webix_el_tabbar .webixtype_icon .webix_img_btn:active,
.webix_el_tabbar .webixtype_icon .webix_img_btn_top:active {
  background-color: #FFFFFF;
}
.webix_accordionitem_label .webix_icon {
  margin-right: 8px;
}
.webix_el_tabbar .webixtype_iconTop,
.webix_el_tabbar .webixtype_image {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_el_tabbar .webixtype_iconTop .webix_icon,
.webix_el_tabbar .webixtype_image .webix_icon {
  color: #1CA1C1;
}
.webix_el_tabbar .webixtype_iconTop .webix_item_tab,
.webix_el_tabbar .webixtype_image .webix_item_tab {
  font-size: 14px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.webix_el_tabbar .webixtype_iconTop .webix_img_btn_top .webix_icon,
.webix_el_tabbar .webixtype_image .webix_img_btn_top .webix_icon,
.webix_el_tabbar .webixtype_iconTop .webix_img_btn_top .webix_image,
.webix_el_tabbar .webixtype_image .webix_img_btn_top .webix_image {
  top: 0;
}
.webix_el_tabbar .webixtype_iconTop .webix_img_btn_text,
.webix_el_tabbar .webixtype_image .webix_img_btn_text {
  bottom: 0px;
  font-size: 14px;
}
.webix_el_tabbar .webixtype_iconTop .webix_item_tab.webix_selected,
.webix_el_tabbar .webixtype_image .webix_item_tab.webix_selected {
  background: #FFFFFF;
}
.webix_el_tabbar .webixtype_iconTop .webix_item_tab.webix_selected .webix_img_btn_text,
.webix_el_tabbar .webixtype_image .webix_item_tab.webix_selected .webix_img_btn_text {
  color: #1CA1C1;
}
.webix_el_tabbar .webixtype_iconTop .webix_item_tab.webix_selected .webix_icon,
.webix_el_tabbar .webixtype_image .webix_item_tab.webix_selected .webix_icon {
  color: #1CA1C1;
}
.webix_el_tabbar .webixtype_iconTop .webix_item_tab.webix_selected .webix_icon_btn,
.webix_el_tabbar .webixtype_image .webix_item_tab.webix_selected .webix_icon_btn {
  background-color: #FFFFFF;
}
.webix_el_tabbar .webixtype_iconTop .webix_item_tab.webix_selected .webix_img_btn,
.webix_el_tabbar .webixtype_image .webix_item_tab.webix_selected .webix_img_btn {
  color: #1CA1C1;
}
.webix_el_tabbar .webixtype_icon .webix_item_tab,
.webix_el_tabbar .webixtype_iconTop .webix_item_tab,
.webix_el_tabbar .webixtype_image .webix_item_tab {
  padding: 0;
  border-radius: 0;
}
.webix_el_tabbar .webixtype_icon .webix_item_tab .webix_img_btn,
.webix_el_tabbar .webixtype_iconTop .webix_item_tab .webix_img_btn,
.webix_el_tabbar .webixtype_image .webix_item_tab .webix_img_btn {
  color: #1CA1C1;
}
.webix_el_tabbar .webixtype_icon .webix_tab_more_icon,
.webix_el_tabbar .webixtype_iconTop .webix_tab_more_icon,
.webix_el_tabbar .webixtype_image .webix_tab_more_icon,
.webix_el_tabbar .webixtype_bottom .webix_tab_more_icon {
  background: #FFFFFF;
}
.webix_el_tabbar .webixtype_icon .webix_tab_more_icon .webix_icon,
.webix_el_tabbar .webixtype_iconTop .webix_tab_more_icon .webix_icon,
.webix_el_tabbar .webixtype_image .webix_tab_more_icon .webix_icon,
.webix_el_tabbar .webixtype_bottom .webix_tab_more_icon .webix_icon {
  color: #1CA1C1;
}
.webix_el_tabbar .webixtype_icon .webix_tab_more_icon:hover,
.webix_el_tabbar .webixtype_iconTop .webix_tab_more_icon:hover,
.webix_el_tabbar .webixtype_image .webix_tab_more_icon:hover,
.webix_el_tabbar .webixtype_bottom .webix_tab_more_icon:hover,
.webix_el_tabbar .webixtype_icon .webix_tab_more_icon:focus,
.webix_el_tabbar .webixtype_iconTop .webix_tab_more_icon:focus,
.webix_el_tabbar .webixtype_image .webix_tab_more_icon:focus,
.webix_el_tabbar .webixtype_bottom .webix_tab_more_icon:focus {
  background-color: #F4F5F9;
}
.webix_el_tabbar .webixtype_icon .webix_img_btn:hover,
.webix_el_tabbar .webixtype_iconTop .webix_img_btn:hover,
.webix_el_tabbar .webixtype_image .webix_img_btn:hover,
.webix_el_tabbar .webixtype_icon .webix_img_btn_top:hover,
.webix_el_tabbar .webixtype_iconTop .webix_img_btn_top:hover,
.webix_el_tabbar .webixtype_image .webix_img_btn_top:hover {
  background-color: transparent;
}
.webix_tab_filler:first-child {
  border-right: 0;
}
.passiveBarBG {
  background: #FFFFFF;
  color: #1CA1C1;
}
.webix_cancel_icon {
  background-image: none;
  font-family: "Webix Material Icons";
  font-size: 17px;
  margin: 0;
  height: auto;
  width: 20px;
}
.webix_cancel_icon:hover {
  color: #FF5C4C;
}
.webix_cancel_icon:before {
  content: "\F011";
}
.webix_error_icon {
  background-image: none;
  font-family: "Webix Material Icons";
  font-size: 20px;
  height: auto;
  width: 20px;
  color: #FF5C4C;
}
.webix_error_icon:before {
  content: "\F034";
}
.webix_inp_counter_next:hover,
.webix_inp_counter_prev:hover {
  background-color: #e7e9f2;
}
.webix_inp_counter_next:focus,
.webix_inp_counter_prev:focus {
  background-color: #e7e9f2;
}
.webix_inp_counter_next:active,
.webix_inp_counter_prev:active {
  background-color: #daddeb;
}
.webix_el_button button,
.webix_el_toggle button,
.webixbutton,
.webix_view button,
.webix_view input[type=button] {
  border-width: 0px;
}
.webix_segment_0:hover,
.webix_segment_1:hover,
.webix_segment_N:hover {
  background-color: #e7e9f2;
}
.webix_segment_0:focus,
.webix_segment_1:focus,
.webix_segment_N:focus {
  background-color: #e7e9f2;
}
.webix_segment_0:active,
.webix_segment_1:active,
.webix_segment_N:active {
  background-color: #daddeb;
}
.webix_selected.webix_segment_0:hover,
.webix_selected.webix_segment_1:hover,
.webix_selected.webix_segment_N:hover {
  background-color: #1992af;
}
.webix_selected.webix_segment_0:focus,
.webix_selected.webix_segment_1:focus,
.webix_selected.webix_segment_N:focus {
  background-color: #1992af;
}
.webix_selected.webix_segment_0:active,
.webix_selected.webix_segment_1:active,
.webix_selected.webix_segment_N:active {
  background-color: #17839d;
}
.webix_all_segments .webix_segment_1,
.webix_all_segments .webix_segment_N {
  margin-left: 1px;
  border-left: none;
}
.webix_segment_0,
.webix_segment_1,
.webix_segment_N {
  border-color: #F4F5F9;
  background-color: #F4F5F9;
  color: #1CA1C1;
  font-weight: 500;
}
.webix_slider_vertical .webix_slider_box .webix_slider_left,
.webix_slider_vertical .webix_slider_box .webix_slider_right {
  width: 6px;
}
.webix_slider_vertical .webix_slider_box .webix_slider_handle {
  left: 5px;
}
.webix_slider_vertical.webix_slider_alt .webix_slider_handle {
  left: 3px;
}
.webix_section > .webix_template {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #475466;
  letter-spacing: 0.2px;
}
.webix_view.webix_control .webix_disabled_box .webix_input_icon,
.webix_view.webix_control .webix_disabled_box .webix_custom_checkbox,
.webix_view.webix_control .webix_disabled_box .webix_custom_radio {
  color: #DADEE0;
}
.webix_view > .webix_disabled {
  background-color: #EBEDF0;
  opacity: 0.6;
}
.webix_disabled_view .webix_disabled_top_label {
  color: #94A1B3;
}
.webix_custom_checkbox:before {
  content: "\F013";
  font-family: "Webix Material Icons";
  cursor: pointer;
}
.webix_checkbox_1 .webix_custom_checkbox:before {
  content: "\F012";
}
.webix_checkbox_1 .webix_custom_checkbox {
  color: #1CA1C1;
}
.webix_custom_checkbox {
  float: left;
  position: relative;
  font-size: 24px;
  height: 100%;
  padding: 0;
  margin-top: 1px;
  border: none;
  background-color: transparent;
  color: #94A1B3;
}
.webix_custom_checkbox:focus {
  color: #188ba6;
}
.webix_el_radio .webix_custom_radio:before {
  font-family: "Webix Material Icons";
  font-size: 24px;
  content: "\F035";
  display: block;
}
.webix_el_radio .webix_radio_1 .webix_custom_radio {
  color: #1CA1C1;
}
.webix_el_radio .webix_radio_1 .webix_custom_radio:before {
  content: "\F036";
}
.webix_el_radio .webix_custom_radio {
  padding: 0;
  display: block;
  background-color: transparent;
  border-color: transparent;
  color: #94A1B3;
  cursor: pointer;
}
.webix_el_radio .webix_custom_radio:focus {
  color: #188ba6;
}
.webix_cal_icon,
.webix_cal_time,
.webix_cal_month_name {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #475466;
  letter-spacing: 0.2px;
}
.webix_cal_icon:hover,
.webix_cal_time:hover,
.webix_cal_month_name:hover,
.webix_cal_icon:focus,
.webix_cal_time:focus,
.webix_cal_month_name:focus {
  text-decoration: none;
  color: #1CA1C1;
}
.webix_cal_month_name,
.webix_time_header .webix_cal_hours,
.webix_time_header .webix_cal_minutes {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #313131;
  letter-spacing: 0.2px;
}
.webix_cal_month_name.webix_readonly:hover,
.webix_cal_month_name.webix_readonly:focus {
  color: #313131;
}
.webix_cal_prev_button,
.webix_cal_next_button {
  background-image: none;
  width: 24px;
  font-size: 24px;
  font-family: "Webix Material Icons";
  color: #94A1B3;
}
.webix_cal_prev_button:hover,
.webix_cal_next_button:hover,
.webix_cal_prev_button:focus,
.webix_cal_next_button:focus {
  color: #1CA1C1;
}
.webix_cal_prev_button:before {
  content: "\F029";
}
.webix_cal_next_button:before {
  content: "\F028";
}
.webix_time_header .webix_cal_hours,
.webix_time_header .webix_cal_minutes,
.webix_cal_body .webix_cal_block_empty {
  font-weight: 500;
  border-bottom: none;
}
.webix_view > .webix_cal_header {
  height: 18px;
}
.webix_view > .webix_cal_header div {
  height: 17px;
  line-height: 18px;
  font-size: 12px;
  border-bottom-color: transparent;
}
.webix_cal_body .webix_cal_week_num {
  color: #475466;
}
.webix_cal_body .webix_cal_today .webix_cal_day_inner {
  box-shadow: inset 0 0 0 1px #1CA1C1;
}
.webix_cal_body .webix_cal_day_inner {
  display: inline-block;
  font-size: 12px;
  border-radius: 50%;
}
.webix_cal_body .webix_cal_day_inner:hover {
  background-color: #F4F5F9;
}
.webix_cal_body .webix_cal_event {
  font-weight: 500;
  color: #1CA1C1;
}
.webix_cal_body .webix_cal_outside {
  color: #94A1B3;
  font-weight: normal;
}
.webix_cal_body .webix_cal_select,
.webix_cal_body .webix_cal_today.webix_cal_select {
  background-color: transparent;
}
.webix_cal_body .webix_cal_select .webix_cal_day_inner,
.webix_cal_body .webix_cal_today.webix_cal_select .webix_cal_day_inner {
  color: #FFFFFF;
  background-color: #1CA1C1;
}
.webix_cal_body .webix_cal_select .webix_cal_day_inner:hover,
.webix_cal_body .webix_cal_today.webix_cal_select .webix_cal_day_inner:hover {
  background-color: #1CA1C1;
}
.webix_cal_body .webix_cal_select:focus .webix_cal_day_inner,
.webix_cal_body .webix_cal_today.webix_cal_select:focus .webix_cal_day_inner {
  background-color: #1992af;
}
.webix_cal_body .webix_cal_block {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #475466;
  letter-spacing: 0.2px;
}
.webix_cal_body .webix_cal_block.webix_selected {
  background-color: transparent;
}
.webix_cal_body .webix_cal_block.webix_selected span {
  background-color: #1CA1C1;
  color: #FFFFFF;
}
.webix_cal_body .webix_cal_block.webix_selected span:hover {
  background-color: #1CA1C1;
}
.webix_cal_body .webix_cal_block.webix_selected:focus span {
  background-color: #1992af;
}
.webix_cal_body .webix_cal_block span {
  border-radius: 50%;
}
.webix_cal_body .webix_cal_block span:hover {
  background-color: #F4F5F9;
}
.webix_cal_body .webix_hours .webix_cal_day_disabled,
.webix_cal_body .webix_hours .webix_cal_day_disabled.webix_cal_event,
.webix_cal_body .webix_minutes .webix_cal_day_disabled,
.webix_cal_body .webix_minutes .webix_cal_day_disabled.webix_cal_event,
.webix_cal_body .webix_cal_day_disabled,
.webix_cal_body .webix_cal_day_disabled.webix_cal_event {
  background-color: #fbfbfd;
  color: #94A1B3;
}
.webix_cal_body .webix_hours .webix_cal_day_disabled span:hover,
.webix_cal_body .webix_hours .webix_cal_day_disabled.webix_cal_event span:hover,
.webix_cal_body .webix_minutes .webix_cal_day_disabled span:hover,
.webix_cal_body .webix_minutes .webix_cal_day_disabled.webix_cal_event span:hover,
.webix_cal_body .webix_cal_day_disabled span:hover,
.webix_cal_body .webix_cal_day_disabled.webix_cal_event span:hover {
  background-color: transparent;
}
.webix_cal_body .webix_cal_range_first,
.webix_cal_body .webix_cal_range_last {
  position: relative;
}
.webix_cal_body .webix_cal_range_last + .webix_cal_outside {
  background-color: #FFFFFF;
}
.webix_cal_body .webix_cal_range_first:before,
.webix_cal_body .webix_cal_range_last:after {
  content: "";
  display: block;
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  background: #e1f6fb;
}
.webix_cal_body .webix_cal_range_first:before {
  right: 100%;
}
.webix_cal_body .webix_cal_range_last:after {
  left: 100%;
}
.webix_cal_body .webix_cal_range_start,
.webix_cal_body .webix_cal_range_end {
  position: relative;
  z-index: 1;
}
.webix_cal_body .webix_cal_range_last + .webix_cal_outside {
  position: relative;
  z-index: 1;
  background-color: #FFFFFF;
}
.webix_cal_body .webix_cal_range:first-child:before,
.webix_cal_body .webix_cal_range:last-child:after,
.webix_cal_body .webix_cal_outside + .webix_cal_range_first:before {
  width: 0;
}
.webix_cal_body .webix_cal_range_start,
.webix_cal_body .webix_cal_range_end,
.webix_cal_body .webix_cal_today.webix_cal_range_start,
.webix_cal_body .webix_cal_today.webix_cal_range_end {
  background-color: transparent;
}
.webix_cal_body .webix_cal_range_start .webix_cal_day_inner,
.webix_cal_body .webix_cal_range_end .webix_cal_day_inner,
.webix_cal_body .webix_cal_today.webix_cal_range_start .webix_cal_day_inner,
.webix_cal_body .webix_cal_today.webix_cal_range_end .webix_cal_day_inner {
  color: #FFFFFF;
  background-color: #1CA1C1;
}
.webix_cal_body .webix_cal_range {
  background-color: #e1f6fb;
}
.webix_daterange .webix_range_timepicker .webix_cal_time {
  padding: 4px;
}
.webix_daterange .webix_cal_icons .webix_cal_icon {
  border-top: none;
}
.webix_progress_top .webix_progress_state,
.webix_progress_bottom .webix_progress_state {
  background: #1CA1C1;
  background-image: linear-gradient(135deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
}
.webix_progress_top .webix_progress_state {
  background-size: 10px 10px;
}
.webix_progress_bottom .webix_progress_state {
  background-size: 14px 14px;
}
.webix_switch_box {
  color: #475466;
  background-color: #F4F5F9;
}
.webix_switch_box.webix_switch_on {
  background-color: #1CA1C1;
}
.webix_switch_box .webix_switch_text {
  padding-top: 2px;
}
.webix_sidebar {
  background-color: #FFFFFF;
}
.webix_sidebar .webix_tree_item:hover {
  background-color: #edeff5;
}
.webix_sidebar.webix_sidebar_left .webix_tree_item.webix_sidebar_selected + .webix_tree_leaves .webix_tree_item,
.webix_sidebar.webix_sidebar_left .webix_tree_item.webix_selected,
.webix_sidebar.webix_sidebar_left .webix_tree_item.webix_sidebar_selected {
  box-shadow: inset 2px 0 #1CA1C1;
}
.webix_sidebar.webix_sidebar_right .webix_tree_item.webix_sidebar_selected + .webix_tree_leaves .webix_tree_item,
.webix_sidebar.webix_sidebar_right .webix_tree_item.webix_selected,
.webix_sidebar.webix_sidebar_right .webix_tree_item.webix_sidebar_selected {
  box-shadow: inset -2px 0 #1CA1C1;
}
.webix_sidebar .webix_tree_branch_1 {
  border: none;
}
.webix_sidebar .webix_tree_branch_1 .webix_tree_item {
  padding-right: 12px;
}
.webix_sidebar .webix_tree_branch_1 > .webix_tree_item {
  padding-left: 12px;
}
.webix_sidebar .webix_tree_item.webix_selected .webix_sidebar_icon,
.webix_sidebar .webix_tree_item .webix_sidebar_icon {
  margin-right: 8px;
}
.webix_sidebar .webix_sidebar_icon {
  width: 20px;
  vertical-align: middle;
}
.webix_sidebar .webix_sidebar_icon,
.webix_sidebar .webix_sidebar_dir_icon {
  color: #94A1B3;
  font-size: 20px;
}
.webix_sidebar .webix_tree_item,
.webix_sidebar .webix_tree_branch_1 > .webix_tree_item {
  color: #475466;
  cursor: pointer;
}
.webix_sidebar .webix_tree_item.webix_selected,
.webix_sidebar .webix_tree_item.webix_sidebar_selected {
  color: #1CA1C1;
  background-color: #F4F5F9;
  font-weight: 500;
}
.webix_sidebar .webix_tree_item.webix_selected .webix_sidebar_icon,
.webix_sidebar .webix_tree_item.webix_sidebar_selected .webix_sidebar_icon,
.webix_sidebar .webix_tree_item.webix_selected .webix_sidebar_dir_icon,
.webix_sidebar .webix_tree_item.webix_sidebar_selected .webix_sidebar_dir_icon {
  color: #1CA1C1;
}
.webix_sidebar .webix_tree_item.webix_selected span {
  background-color: transparent;
  color: #1CA1C1;
}
.webix_sidebar.webix_sidebar_expanded .webix_tree_item.webix_sidebar_selected {
  background-color: transparent;
}
.webix_sidebar.webix_sidebar_expanded .webix_tree_item.webix_sidebar_selected:hover {
  background-color: #edeff5;
}
.webix_sidebar_popup_list .webix_list_item,
.webix_sidebar_popup_list .webix_list_item:first-child {
  border-width: 0px;
}
.webix_sidebar_popup_list .webix_list_item.webix_sidebar_selected {
  background-color: #F4F5F9;
  color: #1CA1C1;
  font-weight: 500;
}
.webix_sidebar_popup_list .webix_list_item.webix_sidebar_selected:hover {
  background-color: #F4F5F9 !important;
}
.webix_sidebar_popup_list .webix_list_item.webix_selected {
  color: #1CA1C1;
  font-weight: 500;
}
.webix_sidebar_popup_list .webix_tree_branch_1 > .webix_tree_item {
  height: 44px;
  line-height: 44px;
}
.webix_sidebar_popup_left .webix_list_item.webix_selected,
.webix_sidebar_popup_left .webix_list_item.webix_sidebar_selected {
  box-shadow: inset 2px 0 #1CA1C1;
}
.webix_sidebar_popup_right .webix_list_item.webix_selected,
.webix_sidebar_popup_right .webix_list_item.webix_sidebar_selected {
  box-shadow: inset -2px 0 #1CA1C1;
}
.webix_sidebar_popup_list.webix_sidebar_popup_right .webix_icon {
  margin-left: -8px;
}
.webix_sidebar_popup.webix_sidebar_popup_left,
.webix_sidebar_popup.webix_sidebar_popup_right {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 7px 0 rgba(0, 0, 0, 0.1);
}
.webix_sidebar_popup.webix_sidebar_popup_right .webix_sidebar_popup_right {
  box-shadow: none;
}
.webix_sidebar_popup .webix_sidebar_popup_title {
  background-color: #F4F5F9;
}
.webix_sidebar_popup .webix_sidebar_popup_title .webix_template {
  border: none;
  line-height: 44px;
}
.webix_sidebar_popup .webix_sidebar_popup_title.webix_selected {
  color: #1CA1C1;
  font-weight: 500;
}
.webix_sidebar_popup_right {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 7px 0 rgba(0, 0, 0, 0.1);
}
.webix_sidebar.webix_dark {
  border-color: #4A4E58;
  background-color: #4A4E58;
}
.webix_sidebar.webix_dark .webix_tree_item {
  color: #FFFFFF;
}
.webix_sidebar.webix_dark .webix_tree_item:hover {
  background-color: #33353c;
}
.webix_sidebar.webix_dark .webix_tree_item.webix_selected,
.webix_sidebar.webix_dark .webix_tree_item.webix_sidebar_selected {
  color: #1CA1C1;
  background-color: #373a42;
}
.webix_sidebar.webix_dark.webix_sidebar_expanded .webix_tree_item.webix_sidebar_selected {
  background-color: transparent;
}
.webix_sidebar.webix_dark.webix_sidebar_expanded .webix_tree_item.webix_sidebar_selected:hover {
  background-color: #33353c;
}
.webix_sidebar_popup.webix_dark .webix_sidebar_popup_title {
  background-color: #373a42;
}
.webix_sidebar_popup.webix_dark .webix_sidebar_popup_title .webix_template {
  border-color: #373a42;
  color: #FFFFFF;
}
.webix_sidebar_popup.webix_dark .webix_sidebar_popup_title.webix_selected .webix_template {
  color: #1CA1C1;
}
.webix_sidebar_popup_list.webix_dark {
  background-color: #4A4E58;
  border-color: #373a42;
}
.webix_sidebar_popup_list.webix_dark .webix_list_item {
  background: #4A4E58;
  color: #FFFFFF;
  border-color: #373a42;
}
.webix_sidebar_popup_list.webix_dark .webix_list_item.webix_selected,
.webix_sidebar_popup_list.webix_dark .webix_list_item.webix_sidebar_selected {
  background-color: #373a42;
  color: #1CA1C1;
}
.webix_sidebar_popup_list.webix_dark .webix_list_item:hover {
  background-color: #33353c;
}
.webix_sidebar_popup_list.webix_dark .webix_list_item.webix_selected {
  background-color: #373a42;
}
.webix_sidebar_popup_list.webix_dark .webix_list_item.webix_sidebar_selected:hover {
  background-color: #373a42 !important;
}
.webix_sidebar_popup.webix_dark {
  border-color: #373a42;
}
.webix_list_item.webix_selected {
  box-shadow: inset 2px 0 #1CA1C1;
}
.webix_list_item.webix_disabled {
  color: #94A1B3;
}
.webix_list_item.webix_selected.webix_disabled {
  box-shadow: inset 2px 0 #94A1B3;
}
.webix_list_item .webix_icon {
  font-size: 20px;
}
.webix_list-x .webix_list_item.webix_selected {
  box-shadow: none;
  border-left: none;
  padding-left: 12px;
}
.webix_header > div {
  padding-left: 12px;
}
.webix_icon,
.webix_icon_btn,
.webix_input_icon {
  font-family: "Webix Material Icons";
}
.webix_icon_button {
  position: relative;
  z-index: 0;
}
.webix_icon_button:before {
  content: '';
  opacity: 0;
  position: absolute;
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  z-index: -1;
  bottom: 0px;
  left: 0px;
  right: 0px;
  top: 0px;
  border-radius: 50%;
  transform: scale(0);
  transition-property: transform, opacity;
}
.webix_icon_button:hover .webix_icon,
.webix_icon_button:focus .webix_icon {
  color: #475466;
}
.webix_icon_button:hover:before {
  background-color: #F4F5F9;
  opacity: 1;
  transform: scale(1);
}
.webix_icon_btn,
.webix_input_icon {
  width: 20px;
}
.webix_img_btn_top .webix_icon {
  color: #94A1B3;
}
.webix_icon_btn {
  vertical-align: text-bottom;
}
.webix_resize_frame {
  box-shadow: 0 2px 6px 0 #000, 0 1px 4px 0 #000;
}
.webix_tooltip {
  padding: 4px 8px;
  background-color: #475466;
  color: #FFFFFF;
  font-weight: 500;
  line-height: 20px;
  border-radius: 2px;
  border: none;
}
.portlet_marker,
.portlet_markertop,
.portlet_markerbottom,
.portlet_markerleft,
.portlet_markerright {
  background: #1CA1C1;
}
.webix_treemap_item:hover {
  box-shadow: inset 0 0 0 1px #5ccce7;
}
.webix_treemap_item.webix_selected {
  border-color: inherit;
  box-shadow: inset 0 0 0 2px #1CA1C1;
}
.webixlabel {
  font-weight: 500;
}
.webixtoolbarlabel {
  letter-spacing: 0;
}
.webix_inp_label,
.webix_inp_top_label,
legend.webix_forminput_label,
.webix_el_label .webix_el_box {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #313131;
  letter-spacing: 0.2px;
}
.webix_inp_label,
.webix_forminput_label {
  padding-right: 8px;
}
.webix_label_right {
  padding-left: 4px;
}
.webix_el_checkbox .webix_label_right {
  display: block;
}
.webix_richtext .webix_richtext_container {
  box-shadow: inset 0 1px #DADEE0;
}
.webix_dbllist .bottom_label {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #94A1B3;
  letter-spacing: 0.2px;
}
.webix_popup.webix_sidemenu.webix_sidemenu_left,
.webix_popup.webix_sidemenu.webix_sidemenu_right,
.webix_popup.webix_sidemenu.webix_sidemenu_top,
.webix_popup.webix_sidemenu.webix_sidemenu_bottom {
  border: none;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
}
.webix_chart .webix_chart_legend_item,
.webix_chart .webix_canvas_text,
.webix_chart .webix_axis_title_x,
.webix_chart .webix_axis_title_y {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #475466;
  letter-spacing: 0.2px;
}
.webix_chart .webix_canvas_text.webix_axis_item_x,
.webix_chart .webix_canvas_text.webix_axis_item_y,
.webix_chart .webix_axis_title_x,
.webix_chart .webix_axis_title_y,
.webix_chart .webix_canvas_text.webix_axis_radar_title {
  color: #94A1B3;
}
.webix_bullet_scale {
  fill: #94A1B3;
  font-size: 12px;
}
.webix_bullet_scale_lines {
  stroke: #94A1B3;
}
.webix_toolbar.pdf_bar {
  background-color: #474747;
}
.webix_toolbar.pdf_bar .webix_icon_btn {
  font-size: 20px;
  vertical-align: middle;
}
.webix_toolbar.pdf_bar .webix_img_btn:focus,
.webix_toolbar.pdf_bar .webix_img_btn:hover {
  background-color: #333333;
}
.invalidStyle {
  color: #FF5C4C;
  background-color: #ffdedb;
}
.webix_list_item.webix_invalid,
.webix_tree_item.webix_invalid,
.webix_dataview_item.webix_invalid,
.webix_cell.webix_invalid {
  color: #FF5C4C;
  background-color: #ffdedb;
}
.webix_invalid .webix_el_box input,
.webix_invalid .webix_el_box .webix_inp_static,
.webix_invalid .webix_el_box select,
.webix_invalid .webix_el_box textarea,
input.webix_invalid,
select.webix_invalid,
textarea.webix_invalid {
  color: #FF5C4C;
  background-color: #ffdedb;
  border-color: #ff8d82;
}
.webix_invalid .webix_inp_bottom_label {
  color: #FF5C4C;
}
.webix_nav_panel .webix_nav_item {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.4);
  opacity: 1;
}
.webix_nav_panel .webix_nav_item:hover {
  border-color: rgba(255, 255, 255, 0.6);
}
.webix_nav_panel .webix_nav_active {
  background-color: #FFFFFF;
}
.webix_nav_button_prev .webix_nav_button_inner,
.webix_nav_button_next .webix_nav_button_inner {
  opacity: 1;
  background-image: none;
  text-align: center;
  font-family: "Webix Material Icons";
  font-size: 24px;
  color: #FFFFFF;
  border-radius: 50%;
}
.webix_nav_button_prev .webix_nav_button_inner:hover,
.webix_nav_button_next .webix_nav_button_inner:hover,
.webix_nav_button_prev .webix_nav_button_inner:focus,
.webix_nav_button_next .webix_nav_button_inner:focus {
  background-color: rgba(0, 0, 0, 0.2);
  transition: 0.15s;
}
.webix_nav_button_side.webix_nav_button_prev .webix_nav_button_inner,
.webix_nav_button_side.webix_nav_button_next .webix_nav_button_inner {
  opacity: 1;
  text-align: center;
  font-family: "Webix Material Icons";
  font-size: 48px;
  color: #FFFFFF;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}
.webix_nav_button_side.webix_nav_button_prev .webix_nav_button_inner:hover,
.webix_nav_button_side.webix_nav_button_next .webix_nav_button_inner:hover,
.webix_nav_button_side.webix_nav_button_prev .webix_nav_button_inner:focus,
.webix_nav_button_side.webix_nav_button_next .webix_nav_button_inner:focus {
  background: rgba(0, 0, 0, 0.4);
}
.webix_nav_button_side.webix_nav_button_next {
  right: 0;
}
.webix_nav_button_side.webix_nav_button_prev {
  left: 0;
}
.webix_nav_button_prev .webix_nav_button_inner:before {
  content: "\F002";
}
.webix_nav_button_next .webix_nav_button_inner:before {
  content: "\F003";
}
.webix_dark .webix_nav_item {
  border-color: rgba(0, 0, 0, 0.4);
}
.webix_dark .webix_nav_item:hover {
  border-color: rgba(0, 0, 0, 0.6);
}
.webix_dark .webix_nav_inactive {
  background-color: transparent;
}
.webix_dark .webix_nav_active {
  background-color: #000000;
}
.webix_dark .webix_nav_active:hover,
.webix_dark .webix_nav_active:focus {
  box-shadow: 0 0 2px #000;
}
.webix_dark .webix_nav_button_prev .webix_nav_button_inner,
.webix_dark .webix_nav_button_next .webix_nav_button_inner {
  background-image: none;
  color: #313131;
}
.webix_dark .webix_nav_button_prev .webix_nav_button_inner:hover,
.webix_dark .webix_nav_button_next .webix_nav_button_inner:hover,
.webix_dark .webix_nav_button_prev .webix_nav_button_inner:focus,
.webix_dark .webix_nav_button_next .webix_nav_button_inner:focus {
  background-color: rgba(255, 255, 255, 0.2);
}
.webix_dark .webix_nav_button_side.webix_nav_button_prev .webix_nav_button_inner,
.webix_dark .webix_nav_button_side.webix_nav_button_next .webix_nav_button_inner {
  background: rgba(255, 255, 255, 0.2);
}
.webix_dark .webix_nav_button_side.webix_nav_button_prev .webix_nav_button_inner:hover,
.webix_dark .webix_nav_button_side.webix_nav_button_next .webix_nav_button_inner:hover,
.webix_dark .webix_nav_button_side.webix_nav_button_prev .webix_nav_button_inner:focus,
.webix_dark .webix_nav_button_side.webix_nav_button_next .webix_nav_button_inner:focus {
  background: rgba(255, 255, 255, 0.4);
}
.webix_skin_mark {
  height: 230px;
}
/* based on MaterialDesignIcons.com */
@font-face {
  font-family: "Webix Material Icons";
  src: url('fonts/webixmdi-webfont.woff2') format('woff2'), url('fonts/webixmdi-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
/* icons collection common for above fonts*/
.wxi-angle-down:before {
  content: "\F001";
}
.wxi-angle-left:before {
  content: "\F002";
}
.wxi-angle-right:before {
  content: "\F003";
}
.wxi-angle-up:before {
  content: "\F004";
}
.wxi-angle-double-right:before {
  content: "\F005";
}
.wxi-angle-double-left:before {
  content: "\F006";
}
.wxi-clock:before {
  content: "\F007";
}
.wxi-calendar:before {
  content: "\F008";
}
.wxi-search:before {
  content: "\F009";
}
.wxi-dots:before {
  content: "\F010";
}
.wxi-close:before {
  content: "\F011";
}
.wxi-checkbox-marked:before {
  content: "\F012";
}
.wxi-checkbox-blank:before {
  content: "\F013";
}
.wxi-trash:before {
  content: "\F014";
}
.wxi-pencil:before {
  content: "\F015";
}
.wxi-eye:before {
  content: "\F016";
}
.wxi-eye-slash:before {
  content: "\F017";
}
.wxi-columns:before {
  content: "\F018";
}
.wxi-drag:before {
  content: "\F019";
}
.wxi-check:before {
  content: "\F020";
}
.wxi-underline:before {
  content: "\F021";
}
.wxi-bold:before {
  content: "\F022";
}
.wxi-italic:before {
  content: "\F023";
}
.wxi-sync:before {
  content: "\F024";
}
.wxi-plus-square:before {
  content: "\F025";
}
.wxi-minus-square:before {
  content: "\F026";
}
.wxi-menu-down:before {
  content: "\F027";
}
.wxi-menu-right:before {
  content: "\F028";
}
.wxi-menu-left:before {
  content: "\F029";
}
.wxi-folder:before {
  content: "\F030";
}
.wxi-folder-open:before {
  content: "\F031";
}
.wxi-file:before {
  content: "\F032";
}
.wxi-close-circle:before {
  content: "\F033";
}
.wxi-alert:before {
  content: "\F034";
}
.wxi-radiobox-blank:before {
  content: "\F035";
}
.wxi-radiobox-marked:before {
  content: "\F036";
}
.wxi-angle-double-down:before {
  content: "\F037";
}
.wxi-angle-double-up:before {
  content: "\F038";
}
.wxi-plus-circle:before {
  content: "\F039";
}
.wxi-minus-circle:before {
  content: "\F040";
}
.wxi-plus:before {
  content: "\F041";
}
.wxi-minus:before {
  content: "\F042";
}
.wxi-download:before {
  content: "\F043";
}
.wxi-user:before {
  content: "\F044";
}
.wxi-filter:before {
  content: "\F045";
}
.wxi-menu-up:before {
  content: "\F046";
}
/*predefined styles for text lables inside a item*/
.webix_strong {
  font-weight: 500;
  white-space: nowrap;
}
.webix_light {
  color: #b3b3b3;
}
/*contr-styles for bootstrap*/
.webix_view,
.webix_input_icon {
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
.webix_view.webix_el_label {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.webix_inp_label,
.webix_inp_top_label,
.webix_label_right {
  margin-bottom: 0px;
}
.webix_view table {
  border-collapse: initial;
}
/* Spin*/
.webix_spin {
  animation: webix_spin 2s infinite linear;
}
@-webkit-keyframes webix_spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@keyframes webix_spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
